import { Functions } from "../Config/Fire";

//Registra un nuovo negozio
export async function getComune(slug) {
  //Chiamo cloud function per ottenere l'oggetto negozio in base al comune
  const comune = Functions.httpsCallable("negozio-getComune");
  return await comune(slug);
}

export async function getListaComuni() {
  //Chiamo cloud function per ottenere l'oggetto negozio in base al comune
  const comuni = Functions.httpsCallable("negozio-getComuni");
  return await comuni();
}

export async function getNomeComuneById(id) {
  //Chiamo cloud function per ottenere l'oggetto negozio in base al comune
  const comune = Functions.httpsCallable("negozio-getNomeComuneById");
  return await comune(id);
}