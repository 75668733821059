import React from "react";
import { Row, Col, Button, Select, Spin, notification } from "antd";
import { getListaComuni } from "../Controllers/Comune";

const { Option } = Select;

class ScegliComune extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      lista_comuni: [],
      comune_selezionato: null,
      partecipants: []
    };
  }
  i = 0;
  _loader = async () => {
    try {
      let lista_comuni = await getListaComuni();
      console.log("COM", lista_comuni);
      let partecipants = [];
      lista_comuni.data.map((item, index) =>
        JSON.parse(item.comuni_partecipanti).map(
          (partecipant, indexPart) => partecipants.push({ slug: item.slug, partecipant: partecipant })
        )
      )
      partecipants = partecipants.sort((a, b) => (a.partecipant.toLowerCase() > b.partecipant.toLowerCase()) ? 1 : -1)
      this.setState({ loaded: true, lista_comuni: lista_comuni.data, partecipants: partecipants });
    } catch (error) {
      notification.success({
        message: "Si è verificato un errore",
        description: `Non è stato possibile caricare la lista dei comuni disponibili,si prega di riprovare.`,
      });
    }
  };

  componentDidMount() {
    this._loader();
  }
  render() {
    return this.state.loaded ? (
      <div className="login-background">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "15px 0",
          }}
        >
          <img
            src={require("../Assets/Immagini/logo.png")}
            style={{ height: 100, marginTop: 35, marginBottom: 39 }}
            alt="logo"
          />
        </div>
        <Row justify="center" style={{ textAlign: "center" }}>
          <Col span={24}>
            <h1 className="home-benvenuto">Qual è il tuo comune?</h1>
            <h1 className="home-comune-subtitle">
              Seleziona il tuo comune di residenza
            </h1>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 8 }} style={{ marginBottom: 10 }}>
            <Select
              onChange={(value) => {
                console.log(value);
                this.props.history.push(`/registra-negozio/${value}`);
                //this.setState({ comune_selezionato: value.value });
              }}
              size="large"
              placeholder="Scegli un comune"
              style={{ width: "100%", textAlign: "left" }}
            >
              {this.state.partecipants.map(({ slug, partecipant }) => (
                <Select.Option
                  key={partecipant}
                  value={slug}
                >
                  {partecipant}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        {/* <Row justify="center">
          <Col xs={{ span: 24 }} md={{ span: 8 }}>
            <Button
              disabled={!this.state.comune_selezionato}
              block={true}
              size="large"
              onClick={() =>
                this.props.history.push(
                  `/registra-negozio/${
                    this.state.lista_comuni[this.state.comune_selezionato].slug
                  }`
                )
              }
              type="primary"
            >
              Registrati nel comune selezionato
            </Button>
          </Col>
        </Row> */}
      </div>
    ) : (
      <div className="login-background">
        <Row style={{ paddingTop: 150 }} justify="center">
          <Col span={8} style={{ textAlign: "center" }}>
            <Spin size="large" />
          </Col>
        </Row>
      </div>
    );
  }
}

export default ScegliComune;
