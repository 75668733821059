import React from 'react';

// import ant design

import Settings from '../Config/Settings';

class Accordo extends React.Component {
	render() {
		return (
			<div className="Accordo" style={{ padding: '30px 8.333333%' }}>
				<h1 style={{ color: Settings.colors.darkGrey }}>Accordo Policy</h1>
				<p>
					<b>Questo Sito Web raccoglie alcuni Dati Personali dei propri Utenti.</b>
					<br />
					<br />
					<h2>Titolare del Trattamento dei Dati</h2>
					<b>
						dGlen Srl - Via Turati 26, 20121 Milano Italia
						<br />
						Indirizzo email del Titolare: info@dglen.it P.IVA 09686140964
					</b>
					<br />
					<br />
					<br />
					<h2>Tipologie di Dati raccolti</h2>
					Fra i dati personali raccolti da questo Sito Web ci sono: Dati di utilizzo; email; nome; cognome;
					varie tipologie di Dati; numero di telefono; Partita IVA; Codice Fiscale; ragione sociale; indirizzo; nazione;
					provincia; CAP; città; settore di attività.
					<br />
					Dettagli completi su ciascuna tipologia di dati raccolti sono forniti nelle sezioni dedicate di
					questa Accordo policy o mediante specifici testi informativi visualizzati prima della raccolta dei
					dati stessi.
					<br />
					I Dati Personali possono essere liberamente forniti dall'Utente.
					<br />
					Se non diversamente specificato, tutti i Dati richiesti da questo Sito Web sono obbligatori. Se
					l’Utente rifiuta di comunicarli, potrebbe essere impossibile per questo Sito Web fornire il
					Servizio. Nei casi in cui questo Sito Web indichi alcuni Dati come facoltativi, gli Utenti sono
					liberi di astenersi dal comunicare tali Dati, senza che ciò abbia alcuna conseguenza sulla
					disponibilità del Servizio o sulla sua operatività.
					<br />
					Gli Utenti che dovessero avere dubbi su quali Dati siano obbligatori, sono incoraggiati a contattare
					il Titolare.
					<br />
					L’eventuale utilizzo di Cookie - o di altri strumenti di tracciamento - da parte di questo Sito Web
					o dei titolari dei servizi terzi utilizzati da questo Sito Web, ove non diversamente precisato, ha
					la finalità di fornire il Servizio richiesto dall'Utente, oltre alle ulteriori finalità descritte
					nel presente documento e nella Cookie Policy, se disponibile.
					<br />
					L'Utente si assume la responsabilità dei Dati Personali di terzi ottenuti, pubblicati o condivisi
					mediante questo Sito Web e garantisce di avere il diritto di comunicarli o diffonderli, liberando il
					Titolare da qualsiasi responsabilità verso terzi.
					<br />
					<br />
					<br />
					<h2>Modalità e luogo del trattamento dei Dati raccolti</h2>
					<b>Modalità di trattamento</b>
					<br />
					Il Titolare adotta le opportune misure di sicurezza volte ad impedire l’accesso, la divulgazione, la
					modifica o la distruzione non autorizzate dei Dati Personali.
					<br />
					Il trattamento viene effettuato mediante strumenti informatici e/o telematici, con modalità
					organizzative e con logiche strettamente correlate alle finalità indicate.
					<br />
					<br />
					<b>Base giuridica del trattamento</b>
					<br />
					<ul>
						<li>
							Il Titolare tratta Dati Personali relativi all’Utente in caso sussista una delle seguenti
							condizioni:
						</li>
						<li>
							l’Utente ha prestato il consenso per una o più finalità specifiche; Nota: in alcuni
							ordinamenti il Titolare può essere autorizzato a trattare Dati Personali senza che debba
							sussistere il consenso dell’Utente o un’altra delle basi giuridiche specificate di seguito,
							fino a quando l’Utente non si opponga (“opt-out”) a tale trattamento. Ciò non è tuttavia
							applicabile qualora il trattamento di Dati Personali sia regolato dalla legislazione europea
							in materia di protezione dei Dati Personali;
						</li>
						<li>
							il trattamento è necessario all'esecuzione di un contratto con l’Utente e/o all'esecuzione
							di misure precontrattuali;
						</li>
						<li>
							il trattamento è necessario per adempiere un obbligo legale al quale è soggetto il Titolare;
						</li>
						<li>
							il trattamento è necessario per l'esecuzione di un compito di interesse pubblico o per
							l'esercizio di pubblici poteri di cui è investito il Titolare;
						</li>
						<li>
							il trattamento è necessario per il perseguimento del legittimo interesse del Titolare o di
							terzi.
						</li>
					</ul>
					È comunque sempre possibile richiedere al Titolare di chiarire la concreta base giuridica di ciascun
					trattamento ed in particolare di specificare se il trattamento sia basato sulla legge, previsto da
					un contratto o necessario per concludere un contratto.
					<br />
					<br />
					<b>Luogo</b>
					<br />
					I Dati sono trattati presso le sedi operative del Titolare ed in ogni altro luogo in cui le parti
					coinvolte nel trattamento siano localizzate. Per ulteriori informazioni, contatta il Titolare.
					<br />
					<br />
					<b>Periodo di conservazione</b>
					<br />I Dati sono trattati e conservati per il tempo richiesto dalle finalità per le quali sono
					stati raccolti. Pertanto:
					<ul>
						<li>
							I Dati Personali raccolti per scopi collegati all’esecuzione di un contratto tra il Titolare
							e l’Utente saranno trattenuti sino a quando sia completata l’esecuzione di tale contratto.
						</li>
						<li>
							I Dati Personali raccolti per finalità riconducibili all’interesse legittimo del Titolare
							saranno trattenuti sino al soddisfacimento di tale interesse. L’Utente può ottenere
							ulteriori informazioni in merito all’interesse legittimo perseguito dal Titolare nelle
							relative sezioni di questo documento o contattando il Titolare.
						</li>
					</ul>
					Quando il trattamento è basato sul consenso dell’Utente, il Titolare può conservare i Dati Personali
					più a lungo sino a quando detto consenso non venga revocato. Inoltre, il Titolare potrebbe essere
					obbligato a conservare i Dati Personali per un periodo più lungo in ottemperanza ad un obbligo di
					legge o per ordine di un’autorità.
					<br />
					<br />
					Al termine del periodo di conservazione i Dati Personali saranno cancellati. Pertanto, allo spirare
					di tale termine il diritto di accesso, cancellazione, rettificazione ed il diritto alla portabilità
					dei Dati non potranno più essere esercitati.
					<br />
					<br />
					<br />
					<h2>Finalità del Trattamento dei Dati raccolti</h2>
					I Dati dell’Utente sono raccolti per consentire al Titolare di fornire il Servizio, adempiere agli
					obblighi di legge, rispondere a richieste o azioni esecutive, tutelare i propri diritti ed interessi
					(o quelli di Utenti o di terze parti), individuare eventuali attività dolose o fraudolente, nonché
					per le seguenti finalità: Gestione dei tag, Gestione contatti e invio di messaggi, Hosting ed
					infrastruttura backend, Funzionalità sociali, Contattare l'Utente e Salvataggio e gestione di
					backup. Per ottenere informazioni dettagliate sulle finalità del trattamento e sui Dati Personali
					trattati per ciascuna finalità, l’Utente può fare riferimento alla sezione “Informazioni dettagliate
					sul trattamento dei Dati Personali”.
					<br />
					<br />
					<br />
					<h2>Dettagli sul trattamento dei Dati Personali</h2>
					I Dati Personali sono raccolti per le seguenti finalità ed utilizzando i seguenti servizi:
					<br />
					<br />
					<b>Modulo di contatto (questo Sito Web)</b>
					<br />
					L’Utente, compilando con i propri Dati il modulo di contatto, acconsente al loro utilizzo per
					rispondere alle richieste di informazioni, di preventivo, o di qualunque altra natura indicata
					dall’intestazione del modulo. Dati Personali trattati: CAP; città; nome; cognome; email; indirizzo;
					nazione; nome; numero di telefono; Partita IVA; Codice Fiscale; provincia; ragione sociale; settore di attività;
					sito web.
					<br />
					<br />
					<b>Gestione contatti e invio di messaggi</b>
					<br />
					Questo tipo di servizi consente di gestire un database di contatti email, contatti telefonici o
					contatti di qualunque altro tipo, utilizzati per comunicare con l’Utente. Questi servizi potrebbero
					inoltre consentire di raccogliere dati relativi alla data e all’ora di visualizzazione dei messaggi
					da parte dell’Utente, così come all’interazione dell'Utente con essi, come le informazioni sui click
					sui collegamenti inseriti nei messaggi.
					<br />
					<br />
					<b>Gestione dei tag</b>
					<br />
					Questo tipo di servizi è funzionale alla gestione centralizzata dei tag o script utilizzati su
					questo Sito Web. L'uso di tali servizi comporta il fluire dei Dati dell'Utente attraverso gli stessi
					e, se del caso, la loro ritenzione.
					<br />
					<br />
					<b>Hosting ed infrastruttura backend</b>
					<br />
					Questo tipo di servizi ha la funzione di ospitare Dati e file che permettono a questo Sito Web di
					funzionare, ne consentono la distribuzione e mettono a disposizione un'infrastruttura pronta all'uso
					per erogare specifiche funzionalità di questo Sito Web.
					<br />
					<br />
					<b>Salvataggio e gestione di backup</b>
					<br />
					Questo tipo di servizi permette il salvataggio e la gestione dei backup di questo Sito Web su server
					esterni gestiti dal fornitore del servizio stesso. Questi backup possono comprendere sia il codice
					sorgente ed i contenuti della stessa che i dati forniti a questo Sito Web da parte dell'Utente.
					<br />
					<br />
					<br />
					<h2>Diritti dell’Utente</h2>
					Gli Utenti possono esercitare determinati diritti con riferimento ai Dati trattati dal Titolare.
					<br />
					In caso di superiore protezione, l’Utente può esercitare tutti i diritti riportati di seguito. In
					ogni altro caso, l’Utente può contattare il titolare per scoprire quali diritti siano applicabili
					nel suo caso e come esercitarli.
					<br />
					In particolare, l’Utente ha il diritto di:
					<ul>
						<li>
							<b>revocare il consenso in ogni momento</b>. L’Utente può revocare il consenso al
							trattamento dei propri Dati Personali precedentemente espresso.
						</li>
						<li>
							<b>opporsi al trattamento dei propri Dati</b>. L’Utente può opporsi al trattamento dei
							propri Dati quando esso avviene su una base giuridica diversa dal consenso. Ulteriori
							dettagli sul diritto di opposizione sono indicati nella sezione sottostante.
						</li>
						<li>
							<b>accedere ai propri Dati</b>. L’Utente ha diritto ad ottenere informazioni sui Dati
							trattati dal Titolare, su determinati aspetti del trattamento ed a ricevere una copia dei
							Dati trattati.
						</li>
						<li>
							<b>verificare e chiedere la rettificazione</b>. L’Utente può verificare la correttezza dei
							propri Dati e richiederne l’aggiornamento o la correzione.
						</li>
						<li>
							<b>ottenere la limitazione del trattamento</b>. Quando ricorrono determinate condizioni,
							l’Utente può richiedere la limitazione del trattamento dei propri Dati. In tal caso il
							Titolare non tratterà i Dati per alcun altro scopo se non la loro conservazione.
						</li>
						<li>
							<b>ottenere la cancellazione o rimozione dei propri Dati Personali</b>. Quando ricorrono
							determinate condizioni, l’Utente può richiedere la cancellazione dei propri Dati da parte
							del Titolare.
						</li>
						<li>
							<b>ricevere i propri Dati o farli trasferire ad altro titolare</b>. L’Utente ha diritto di
							ricevere i propri Dati in formato strutturato, di uso comune e leggibile da dispositivo
							automatico e, ove tecnicamente fattibile, di ottenerne il trasferimento senza ostacoli ad un
							altro titolare. Questa disposizione è applicabile quando i Dati sono trattati con strumenti
							automatizzati ed il trattamento è basato sul consenso dell’Utente, su un contratto di cui
							l’Utente è parte o su misure contrattuali ad esso connesse.
						</li>
						<li>
							<b>proporre reclamo</b>. L’Utente può proporre un reclamo all’autorità di controllo della
							protezione dei dati personali competente o agire in sede giudiziale.
						</li>
					</ul>
					<b>Dettagli sul diritto di opposizione</b>
					<br />
					Quando i Dati Personali sono trattati nell’interesse pubblico, nell’esercizio di pubblici poteri di
					cui è investito il Titolare oppure per perseguire un interesse legittimo del Titolare, gli Utenti
					hanno diritto ad opporsi al trattamento per motivi connessi alla loro situazione particolare.
					<br />
					Si fa presente agli Utenti che, ove i loro Dati fossero trattati con finalità di marketing diretto,
					possono opporsi al trattamento senza fornire alcuna motivazione. Per scoprire se il Titolare tratti
					dati con finalità di marketing diretto gli Utenti possono fare riferimento alle rispettive sezioni
					di questo documento.
					<br />
					<br />
					<b>Come esercitare i diritti</b>
					<br />
					Per esercitare i diritti dell’Utente, gli Utenti possono indirizzare una richiesta agli estremi di
					contatto del Titolare indicati in questo documento. Le richieste sono depositate a titolo gratuito e
					evase dal Titolare nel più breve tempo possibile, in ogni caso entro un mese.
					<br />
					<br />
					<br />
					<h2>Applicabilità del livello superiore di protezione</h2>
					Mentre la maggior parte delle disposizioni di questo documento vale nei confronti di tutti gli
					Utenti, alcune sono espressamente assoggettate all’applicabilità di un livello superiore di
					protezione al trattamento di Dati Personali.
					<br />
					Tale livello superiore di protezione è sempre garantito quando il trattamento:
					<ul>
						<li>è eseguito da un Titolare con sede nella UE; ovvero</li>
						<li>
							riguarda Dati Personali di Utenti che si trovano nella UE ed è funzionale all’offerta di
							beni o servizi a titolo oneroso o gratuito a tali Utenti; ovvero
						</li>
						<li>
							riguarda Dati Personali di Utenti che si trovano nella UE e permette al Titolare di
							monitorare il comportamento di tali Utenti nella misura in cui tale comportamento ha luogo
							all'interno dell'Unione.
						</li>
					</ul>
					<br />
					<h2>Cookie Policy</h2>
					Questo Sito Web fa utilizzo di Cookie e altri Identificatori.
					<br />
					<br />
					<br />
					<h2>Ulteriori informazioni sul trattamento</h2>
					<b>Difesa in giudizio</b>
					<br />
					I Dati Personali dell’Utente possono essere utilizzati da parte del Titolare in giudizio o nelle
					fasi preparatorie alla sua eventuale instaurazione per la difesa da abusi nell'utilizzo di questo
					Sito Web o dei Servizi connessi da parte dell’Utente.
					<br />
					L’Utente dichiara di essere consapevole che il Titolare potrebbe essere obbligato a rivelare i Dati
					per ordine delle autorità pubbliche.
					<br />
					<br />
					<b>Informative specifiche</b>
					<br />
					Su richiesta dell’Utente, in aggiunta alle informazioni contenute in questa Accordo policy, questo
					Sito Web potrebbe fornire all'Utente delle informative aggiuntive e contestuali riguardanti Servizi
					specifici, o la raccolta ed il trattamento di Dati Personali.
					<br />
					<br />
					<b>Log di sistema e manutenzione</b>
					<br />
					Per necessità legate al funzionamento ed alla manutenzione, questo Sito Web e gli eventuali servizi
					terzi da essa utilizzati potrebbero raccogliere log di sistema, ossia file che registrano le
					interazioni e che possono contenere anche Dati Personali, quali l’indirizzo IP Utente.
					<br />
					<br />
					<b>Informazioni non contenute in questa policy</b>
					<br />
					Ulteriori informazioni in relazione al trattamento dei Dati Personali potranno essere richieste in
					qualsiasi momento al Titolare del Trattamento utilizzando gli estremi di contatto.
					<br />
					<br />
					<b>Risposta alle richieste “Do Not Track”</b>
					<br />
					Questo Sito Web non supporta le richieste “Do Not Track”.
					<br />
					Per scoprire se gli eventuali servizi di terze parti utilizzati le supportino, l'Utente è invitato a
					consultare le rispettive Accordo policy.
					<br />
					<br />
					<b>Modifiche a questa Accordo policy</b>
					<br />
					Il Titolare del Trattamento si riserva il diritto di apportare modifiche alla presente Accordo
					policy in qualunque momento notificandolo agli Utenti su questa pagina e, se possibile, su questo
					Sito Web nonché, qualora tecnicamente e legalmente fattibile, inviando una notifica agli Utenti
					attraverso uno degli estremi di contatto di cui è in possesso. Si prega dunque di consultare con
					frequenza questa pagina, facendo riferimento alla data di ultima modifica indicata in fondo.
					<br />
					<br />
					Qualora le modifiche interessino trattamenti la cui base giuridica è il consenso, il Titolare
					provvederà a raccogliere nuovamente il consenso dell’Utente, se necessario.
				</p>
			</div>
		);
	}
}

export default Accordo;
