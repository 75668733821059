import React from "react";

// import redux
import { connect } from "react-redux";

// import ant design
import {
  Row,
  Col,
  Button,
  Input,
  Drawer,
  notification,
  Modal,
  Divider,
} from "antd";

//import components and styles
import Container from "../Components/Container";
import Label from "../Components/Label";
import PageHeader from "../Components/PageHeader";
import { Formik, Form } from "formik";
import Loading from "../Components/Loading";
import PhoneInput from "react-phone-input-2";

//import settings from config
import Settings from "../Config/Settings";
import { validationSchemaReferente } from "../Config/Validation";

// import Registrazione controller functions
import {
  getDatiReferente,
  aggiornaDatiReferente,
} from "../Controllers/Negozio";

// import Auth controller functions
import {
  updateUserEmail,
  updateUserPassword,
  resendEmailVerification,
  requestResetPassword,
  logout,
  updateUserMailchimp,
} from "../Controllers/Auth";
import { EditOutlined } from "@ant-design/icons";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { Link } from "react-router-dom";

class Account extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      loading_update: false,
      drawer_email: false,
      drawer_pw: false,
      new_email: "",
      new_email_conf: "",
      password_sent: false,
      email_errors: "",
      button_disabled: true,
      mail_modal: false,
    };
  }

  _updateUserEmail = async () => {
    const { new_email, new_email_conf } = this.state;
    //Validation
    if (new_email != "" && new_email != null) {
      if (new_email != new_email_conf) {
        this.setState({ email_errors: "Le email non corrispondono" });
      } else {
        this.setState({ loaded: false });
        try {
          const res = await updateUserMailchimp(new_email);
        } catch (error) {
          console.log(error);
        }
        try {
          const user = await updateUserEmail(new_email);
          this.setState({ drawer_email: false, mail_modal: true });
          notification.success({
            message: "Email aggiornata",
            description:
              "Il tuo indirizzo email è stato correttamente aggiornato",
          });
        } catch (e) {
          if (e.code == "auth/invalid-email") {
            this.setState({ new_email: "", new_email_conf: "" });
            notification.error({
              message: "Email non valida",
              description:
                "L'email che hai inserito non è valida. Inseriscine un'altra.",
            });
          } else if (e.code == "auth/requires-recent-login") {
            notification.warn({
              message: "Login richiesto",
              description:
                "Per effettuare il cambio mail, devi rifare l'accesso a Garzone. Verrai reindirizzato alla pagina di login.",
            });
            setTimeout(() => {
              logout();
              this.props.history.push("/accedi");
            }, 5000);
          } else if (e.code == "auth/email-already-in-use") {
            this.setState({ new_email: "", new_email_conf: "" });
            notification.error({
              message: "Email già in uso",
              description:
                "L'email inserita è già stata usata da un'altro utente. Inseriscine un'altra.",
            });
          } else {
            this.setState({ drawer_email: false });
            notification.error({
              message: "Errore",
              description:
                "Si è verificato un errore durante l'aggiornamento dell'indirizzo email",
            });
          }

          console.log("Error:", e);
        } finally {
          this.setState({ loaded: true });
        }
      }
    } else {
      this.setState({ email_errors: "Inserisci un indirizzo email" });
    }
  };

  _updateUserPassword = async () => {
    try {
      const password = await requestResetPassword(
        this.state.initialValues.email
      );
      this.setState({ password_sent: true });
    } catch (error) {
      notification.destroy();
      notification.error({
        message: "Errore",
        description: "Si è verificato un errore. Riprova.",
      });
    }
  };

  _submitForm = async (values, { setSubmitting, resetForm }) => {
    let dati_negozio = values;
    delete dati_negozio.email;
    delete dati_negozio.password;
    if (values.telefono_referente.substring(0, 1) != "+") {
      dati_negozio.telefono_referente = "+" + dati_negozio.telefono_referente;
    }
    console.log(dati_negozio);
    this.setState({ loading_update: true });
    try {
      const referente = await aggiornaDatiReferente(
        this.props.auth.user.id_negozio,
        dati_negozio
      );
      this.setState({ loading_update: false });
      notification.destroy();
      notification.success({
        message: "Dati aggiornati",
        description: "Il tuoi dati sono stati correttamente aggiornati",
      });
      resetForm({
        values: {
          nome_referente: dati_negozio.nome_referente,
          cognome_referente: dati_negozio.cognome_referente,
          telefono_referente: dati_negozio.telefono_referente,
          marketing: dati_negozio.marketing,
          profilazione: dati_negozio.profilazione
        },
      });
      //('Success', referente);
    } catch (e) {
      this.setState({ loading_update: false });
      notification.destroy();
      if (e.toString().indexOf("phone") !== -1) {
        notification.warn({
          message: "Numero già associato",
          description:
            "Il numero che hai inserito è già usato da un altro utente. Inserisci un'altro numero.",
        });
      } else if (e.toString().match("TOO_SHORT")) {
        notification.warn({
          message: "Numero corto",
          description: "Il numero di telefono è troppo corto",
        });
      } else {
        notification.warn({
          message: "Errore",
          description:
            "Si è verificato un errore durante l'aggiornamento dei dati'",
        });
      }
      console.log("Errore", e);
      setSubmitting(false);
    }
  };

  _loader = async () => {
    let user = this.props.auth.user;
    let referente = await getDatiReferente(user.id_negozio);
    referente = referente.data;

    let initialValues = {
      email: user.email,
      password: "Inserisci una password",
      nome_referente: referente.nome_referente,
      cognome_referente: referente.cognome_referente,
      telefono_referente: referente.telefono_referente,
      marketing: referente.marketing,
      profilazione: referente.profilazione
    };

    this.setState({ initialValues: initialValues, loaded: true });
  };

  componentDidMount() {
    this._loader();
  }

  render() {
    const { new_email, password_sent, new_email_conf } = this.state;

    return this.state.loaded ? (
      <Container>
        <PageHeader
          title="Il tuo account"
          description="Questi sono i dati relativi alla tua registrazione in Garzone, se vuoi li puoi modificare."
        />
        <Divider />
        <Formik
          initialValues={this.state.initialValues}
          validationSchema={validationSchemaReferente}
          onSubmit={this._submitForm}
        >
          {({
            touched,
            errors,
            values,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
            isValid,
            dirty,
            isSubmitting,
          }) => (
            <Form>
              <Row justify="start" align="top" gutter={[15, 15]}>
                <Col span={24}>
                  <Label titolo="Dati account" />
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <span
                    style={{
                      marginBottom: 10,
                      color: Settings.colors.darkGrey,
                    }}
                  >
                    Email
                  </span>
                  <Input
                    value={values.email}
                    disabled={true}
                  />
                  <Button type="primary" icon={<EditOutlined />} onClick={() => this.setState({ drawer_email: true })} style={{ marginTop: 5 }}>
                    Cambia email
                  </Button>
                  <Modal
                    destroyOnClose
                    title="Cambia email account"
                    placement="right"
                    closable={true}
                    onCancel={() => this.setState({ drawer_email: false, new_email: '', new_email_conf: '' })}
                    visible={this.state.drawer_email}
                    footer={
                      <Row
                        justify="space-between"
                        align="middle"
                        gutter={[15, 15]}
                      >
                        <Col span={12}>
                          <Button
                            size="large"
                            block
                            onClick={() =>
                              this.setState({ drawer_email: false })
                            }
                            style={{ marginRight: 8, borderRadius: 6 }}
                          >
                            Annulla
                          </Button>
                        </Col>
                        <Col span={12}>
                          <Button
                            size="large"
                            block
                            onClick={this._updateUserEmail}
                            type="primary"
                            style={{ paddingLeft: 30, paddingRight: 30 }}
                          >
                            Invia
                          </Button>
                        </Col>
                      </Row>
                    }
                  >
                    <Row justify="center" align="middle" gutter={[15, 15]}>
                      <Col span={24}>
                        <span
                          style={{
                            marginBottom: 10,
                            color: Settings.colors.darkGrey,
                          }}
                        >
                          Nuova email
                        </span>
                        <Input
                          value={new_email}
                          onChange={(value) =>
                            this.setState({ new_email: value.target.value })
                          }
                          placeholder="Email"
                        />
                        <div style={styles.emailError}>
                          {this.state.email_errors}
                        </div>
                        <span
                          style={{
                            marginBottom: 10,
                            color: Settings.colors.darkGrey,
                          }}
                        >
                          Ripeti nuova email
                        </span>
                        <Input
                          value={this.state.new_email_conf}
                          onChange={(value) =>
                            this.setState({
                              new_email_conf: value.target.value,
                            })
                          }
                          placeholder="Email"
                        />
                      </Col>
                    </Row>
                  </Modal>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <span
                    style={{
                      marginBottom: 10,
                      color: Settings.colors.darkGrey,
                    }}
                  >
                    Password
                  </span>
                  <Input.Password
                    visibilityToggle={false}
                    value={values.password}
                    placeholder="Password"
                    disabled={true}
                  />
                  <Button type="primary" icon={<EditOutlined />} onClick={() => this.setState({ drawer_pw: true })} style={{ marginTop: 5 }}>
                    Cambia password
                  </Button>
                  <Modal
                    footer={null}
                    destroyOnClose
                    title="Cambia password"
                    closable={true}
                    onCancel={() => this.setState({ drawer_pw: false })}
                    visible={this.state.drawer_pw}
                  >
                    {password_sent ? (
                      <div>
                        L'email è stata inviata. Controlla la tua casella di
                        posta (anche nella spam) e premi il link contenuto nella
                        mail per reimpostare la password.
                      </div>
                    ) : (
                      <Row justify="center" align="top" gutter={[15, 15]}>
                        <Col span={24}>
                          <span style={{ color: Settings.colors.darkGrey }}>
                            Premi questo pulsante per inviare un link alla
                            tua mail e cambiare password.
                          </span>
                        </Col>
                        <Col span={24}>
                          <Button
                            onClick={this._updateUserPassword}
                            type="primary"
                            block
                            size="large"
                            style={{
                              marginTop: 30,
                              paddingLeft: 30,
                              paddingRight: 30,
                            }}
                          >
                            Invia
                          </Button>
                        </Col>
                      </Row>
                    )}
                  </Modal>
                </Col>
              </Row>

              <Divider />

              <Row justify="start" align="top" gutter={[15, 15]}>
                <Col span={24}>
                  <Label titolo="Dati referente" />
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <span
                    style={{
                      marginBottom: 10,
                      color: Settings.colors.darkGrey,
                    }}
                  >
                    Nome
                  </span>
                  <Input
                    value={values.nome_referente}
                    onChange={handleChange("nome_referente")}
                    placeholder="Nome"
                    onBlur={handleBlur("nome_referente")}
                  />
                  <div>{touched.nome_referente && errors.nome_referente}</div>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <span
                    style={{
                      marginBottom: 10,
                      color: Settings.colors.darkGrey,
                    }}
                  >
                    Cognome
                  </span>
                  <Input
                    value={values.cognome_referente}
                    onChange={handleChange("cognome_referente")}
                    placeholder="Cognome"
                    onBlur={handleBlur("cognome_referente")}
                  />
                  <div>
                    {touched.cognome_referente && errors.cognome_referente}
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <span
                    style={{
                      marginBottom: 10,
                      color: Settings.colors.darkGrey,
                    }}
                  >
                    Telefono
                  </span>
                  <PhoneInput
                    value={values.telefono_referente}
                    placeholder="Telefono"
                    country="it"
                    enableSearch
                    searchPlaceholder="Cerca"
                    onChange={handleChange("telefono_referente")}
                    onBlur={handleBlur("telefono_referente")}
                    inputClass="input-text"
                    inputStyle={{ width: "100%" }}
                  />
                  <div>
                    {touched.telefono_referente && errors.telefono_referente}
                  </div>
                </Col>
              </Row>
              <Divider />
              <Row justify="start" align="top" gutter={[15, 15]}>
                <Col span={24}>
                  <Label
                    titolo="Permessi e condizioni"
                    desc="Spunta le tue preferenze riguardo l'utilizzo di Garzone"
                  />
                </Col>
                <Col className="input-check" xs={24}>
                  <Checkbox checked={values.marketing} onChange={handleChange("marketing")}>
                    Letta la {" "}<Link target="_blank" to={require("../Assets/Materiali/privacy.pdf")}>Privacy policy</Link>, presto il mio consenso per l’invio a mezzo email, da parte di questo sito, di comunicazioni informative e promozionali, inclusa la newsletter, riferite a prodotti e/o servizi propri e/o di terzi e per lo svolgimento di ricerche di mercato.
                  </Checkbox>
                  <div className="input-error">
                    {errors.marketing}
                  </div>
                </Col>
                <Col className="input-check" xs={24}>
                  <Checkbox checked={values.profilazione} onChange={handleChange("profilazione")}>
                    Letta la {" "}<Link target="_blank" to={require("../Assets/Materiali/privacy.pdf")}>Privacy policy</Link>, presto il mio consenso per l’invio a mezzo e-mail, da parte di questo sito, di comunicazioni informative e promozionali, inclusa la newsletter, riferite a prodotti e/o servizi di mio specifico interesse.
                  </Checkbox>
                  <div className="input-error">
                    {errors.profilazione}
                  </div>
                </Col>
              </Row>
              <Divider />
              <Row justify="center" align="middle">
                <Col span={24}>
                  <Button
                    icon={<EditOutlined />}
                    loading={this.state.loading_update}
                    disabled={!dirty || isSubmitting}
                    block={true}
                    size="large"
                    onClick={handleSubmit}
                    type="primary"
                  >
                    Aggiorna
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
        {/* Modale mail */}
        <Modal
          title="Email modificata"
          visible={this.state.mail_modal}
          closable={false}
          maskClosable={false}
          footer={
            <Button
              type="primary"
              onClick={async () => {
                await logout();
                this.props.history.push("/accedi");
              }}
            >
              OK
            </Button>
          }
        >
          L'email è stata cambiata correttamente. Premi OK per tornare al login,
          dovrai aspettare 60 secondi per richiedere una mail di conferma.
        </Modal>
      </Container>
    ) : (
      <Container>
        <Loading loading={true} opaque />
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

const styles = {
  emailError: {
    marginBottom: 20,
    color: Settings.colors.red,
  },
};

export default connect(mapStateToProps)(Account);
