import { EditOutlined, SaveOutlined } from "@ant-design/icons";
import {
  Col,
  Divider,
  Input,
  Row,
  Select,
  Switch,
  Checkbox,
  Button,
  notification,
  Alert,
} from "antd";
import Form from "antd/lib/form/Form";
import TextArea from "antd/lib/input/TextArea";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import Settings from "../Config/Settings";
import { validationModuloOrdini } from "../Config/Validation";
import {
  aggiornaModuloOrdini,
} from "../Controllers/Negozio";

const ModuloOrdini = ({ negozio, getNegozio }) => {

  const _setModuloOrdini = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    try {
      await aggiornaModuloOrdini(negozio.id, values);
      notification.destroy();
      notification.success({
        message: "Congratulazioni!",
        description: "Hai aggiornato con successo il modulo ordini",
      });
      getNegozio();
    } catch (error) {
      notification.destroy();
      notification.error({
        message: "Errore",
        description: "Errore nella richiesta",
      });
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  const initialValues = {
    modulo_ordini: negozio.modulo_ordini,
    ritiro: negozio.ritiro,
    consegna: negozio.consegna,
    dettagli_consegna: negozio.dettagli_consegna,

    online: true,
    pos: negozio.type,

    // NEXI
    // nexi_alias:
    //   negozio.type === "nexi" && negozio.parameters
    //     ? negozio.parameters.apiKey
    //     : "",
    // nexi_chiaveSegreta:
    //   negozio.type === "nexi" && negozio.parameters
    //     ? negozio.parameters.chiaveSegreta
    //     : "",
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationModuloOrdini}
      onSubmit={_setModuloOrdini}
      validateOnMount
      validateOnBlur
      validateOnChange
    >
      {({
        touched,
        errors,
        values,
        handleChange,
        handleSubmit,
        handleBlur,
        setFieldValue,
        setTouched,
        isValid,
        dirty,
        isSubmitting,
      }) => (
        <Form>
          <Row>
            <Col
              style={{
                backgroundColor: Settings.colors.darkerGrey,
                color: Settings.colors.white,
                borderRadius: "4px",
                padding: "10px",
              }}
            >
              <Row style={{ marginBottom: 15 }}>
                <span style={{ fontSize: "24px" }}>Modulo Ordini</span>
              </Row>
              <Row style={{ marginBottom: 15 }}>
                <span>
                  Permetti ai clienti di inviare ordini inserendo nel carrello
                  prodotti del tuo catalogo.
                </span>
              </Row>
              <Row style={{ marginBottom: 15 }}>
                <Col>
                  <Switch
                    defaultChecked={values.modulo_ordini}
                    onChange={(e) => {
                      console.log(e);
                      setFieldValue("modulo_ordini", e);
                    }}
                  />
                </Col>
                <Col>
                  <span style={{ marginLeft: "15px" }}>Abilita Modulo</span>
                </Col>
              </Row>
              {values.modulo_ordini ? (
                <>
                  <Row style={{ marginBottom: 15 }}>
                    <span>
                      Scegli le modalità con le queli erogare il servizio
                    </span>
                  </Row>
                  <Row gutter={[15]}>
                    <Col span={24}>
                      <Checkbox
                        checked={values.ritiro}
                        onBlur={handleBlur("ritiro")}
                        onChange={handleChange("ritiro")}
                        style={{ color: Settings.colors.white }}
                      >
                        Ritiro in negozio
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        checked={values.consegna}
                        onBlur={handleBlur("consegna")}
                        onChange={handleChange("consegna")}
                        style={{ color: Settings.colors.white }}
                      >
                        Consegna a domicilio
                      </Checkbox>
                    </Col>
                    {values.modulo_ordini && values.consegna ? (
                      <Col span={24}>
                        <Row style={{ marginTop: 15, marginBottom: 15 }}>
                          <span style={{ color: Settings.colors.white }}>
                            Descrivi, se vuoi, le tariffe e le modalità di
                            consegna da far sapere ai tuoi clienti
                          </span>
                        </Row>
                        <Row>
                          (
                          {values.dettagli_consegna
                            ? 1500 - values.dettagli_consegna.length
                            : 1500}{" "}
                          caratteri rimanenti)
                        </Row>
                        <Row>
                          <TextArea
                            value={values.dettagli_consegna}
                            onChange={handleChange("dettagli_consegna")}
                            placeholder="Inserisci informazioni riguardo la modalità di consegna a domicilio, come ad esempio, i costi di consegna"
                            autoSize={{ minRows: 7, maxRows: 7 }}
                            maxLength={1500}
                            onBlur={handleBlur("dettagli_consegna")}
                            style={{ height: 180, width: "100%" }}
                          />
                        </Row>

                        <div className="input-error">
                          {touched.dettagli_consegna &&
                            errors.dettagli_consegna}
                        </div>
                      </Col>
                    ) : null}
                  </Row>
                </>
              ) : null}
              <Divider />
              <Row justify="center" align="middle">
                <Col span={24}>
                  <Button
                    icon={<EditOutlined />}
                    loading={isSubmitting}
                    disabled={!(isValid && dirty) || isSubmitting}
                    block={true}
                    size="large"
                    onClick={handleSubmit}
                    type="primary"
                  >
                    {isSubmitting ? "Attendi..." : "Aggiorna"}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default ModuloOrdini;
