import { Functions } from "../Config/Fire";

export async function getPromozione(id) {
  const promozione = Functions.httpsCallable("negozio-getPromozione");
  return await promozione(id);
}

export async function getPromozioni(id_negozio) {
  const promozioni = Functions.httpsCallable("negozio-getPromozioni");
  return await promozioni(id_negozio);
}

export async function eliminaPromozione(promozione) {
  const promozione_eliminato = Functions.httpsCallable(
    "negozio-eliminaPromozione"
  );
  return await promozione_eliminato(promozione);
}

export async function creaPromozione(values) {
  const promozione = Functions.httpsCallable("negozio-creaPromozione");
  return await promozione(values);
}

export async function aggiornaPromozione(id_promozione, values) {
  const promozione = Functions.httpsCallable("negozio-aggiornaPromozione");
  return await promozione({ id: id_promozione, ...values });
}
