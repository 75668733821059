import React, { useEffect, useState } from "react";
import Container from "../Components/Container";
import PageHeader from "../Components/PageHeader";
import { Divider, Row, Col, Input, Space, Button, Table } from "antd";
import { useSelector } from "react-redux";
import { getResiNegozio } from "../Controllers/Negozio";
import Loading from "../Components/Loading";
import CustomChip from "../Components/CustomChip";
import { _chipResoStatusColorGetter } from "../Config/Utility";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { useHistory } from "react-router";
import Settings from "../Config/Settings";
import moment from "moment";
import "moment/locale/it";
const Resi = () => {
  const [loading, setLoading] = useState(true);
  const [resi, setResi] = useState([]);
  const [filteredInfo, setfilteredInfo] = useState({});
  const [sortedInfo, setsortedInfo] = useState({});
  const [searchText, setsearchText] = useState(null);
  const [searchInput, setSearchInput] = useState(null);
  const [searchedColumn, setsearchedColumn] = useState(null);
  //reducer data
  const auth = useSelector((state) => state.auth);

  const history = useHistory();

  const getResi = async () => {
    try {
      setLoading(true);
      const { data } = await getResiNegozio(auth.user.id_negozio);
      setResi(data);
      setLoading(false)
    } catch (error) {
      console.log("Error", error);
    }
  };

  //funzioni tabelle
  const _getColumns = () => {
    return [
      {
        title: "Prodotto",
        dataIndex: "prodotto",
        key: "prodotto",
        width: 300,
        sorter: (a, b) => (JSON.parse(a.prodotto))[0].nome.localeCompare((JSON.parse(b.prodotto))[0].nome),
        sortDirections: ["descend", "ascend"],
        ...getColumnSearchProps("prodotto", "prodotto"),
        filteredValue: filteredInfo.prodotto || null,
        sortOrder: sortedInfo.columnKey === "prodotto" && sortedInfo.order,
        render: (text, record) => {
          console.log(record);
          return record.prodotto ? (
            <span>{JSON.parse(record.prodotto)[0].nome}</span>
          ) : null;
        },
      },
      {
        title: "Stato",
        width: 150,
        dataIndex: "status",
        key: "status",
        sorter: (a, b) => a.status.localeCompare(b.status),
        sortDirections: ["descend", "ascend"],
        ...getColumnSearchProps("status", "status"),
        filteredValue: filteredInfo.status || null,
        sortOrder: sortedInfo.columnKey === "status" && sortedInfo.order,
        render: (text, record) => {
          return (
            <CustomChip
              title={text}
              backgroundColor={_chipResoStatusColorGetter(text)}
            />
          );
        },
      },
      {
        title: "Data creazione",
        width: 250,
        dataIndex: "creazione",
        key: "creazione",
        sorter: (a, b) => moment(a.creazione, "DD/MM/YYYY HH:mm").unix() - moment(b.creazione, "DD/MM/YYYY HH:mm").unix(),
        sortDirections: ["descend", "ascend"],
        ...getColumnSearchProps("creazione", "creazione"),
        filteredValue: filteredInfo.creazione || null,
        sortOrder: sortedInfo.columnKey === "creazione" && sortedInfo.order,
      },
      {
        title: "Motivazione",
        dataIndex: "richiesta",
        key: "richiesta",
        width: 500,
        ...getColumnSearchProps("richiesta", "richiesta"),
        filteredValue: filteredInfo.richiesta || null,
        sortOrder: sortedInfo.columnKey === "richiesta" && sortedInfo.order,
      },
      {
        title: "Note",
        width: 500,
        dataIndex: "note",
        key: "note",
        ...getColumnSearchProps("note", "note"),
        filteredValue: filteredInfo.note || null,
        sortOrder: sortedInfo.columnKey === "note" && sortedInfo.order,
      },
      {
        width: 150,
        render: (text, record) => {
          return (
            <Button
              style={{
                backgroundColor: Settings.colors.cyan,
                color: Settings.colors.white,
              }}
              onClick={() => {
                history.push(`/ordini/${record.uid_ordine}`);
              }}
            >
              Vai all'ordine
            </Button>
          );
        },
      },
    ];
  };
  const _handleChange = (pagination, filters, sorter) => {
    setfilteredInfo(filters);
    setsortedInfo(sorter);
  };

  const _clearFiltri = () => {
    setfilteredInfo({});
    setsortedInfo({});
  }

  //Ricerca
  const getColumnSearchProps = (title, dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            setSearchInput(node)
          }}
          placeholder={`Cerca ${title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Cerca
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : null,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible && searchInput) {
        setTimeout(() => searchInput.select());
      }
    },
    render: (text) =>
      filteredInfo === null ? (
        text
      ) : searchedColumn === dataIndex && filteredInfo[dataIndex] && text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });
  //Ricerca
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setsearchText(selectedKeys[0]);
    setsearchedColumn(dataIndex);
  };

  //Ricerca
  const handleReset = (clearFilters) => {
    clearFilters();
    setsearchText("");
  };
  //----------------
  useEffect(() => {
    getResi();
  }, []);

  return (
    <Container>
      <PageHeader
        title="Resi"
        description="Dalla sezione Resi potrai visionare le richieste di reso da parte dei clienti e il loro status attuale."
      />
      <Divider />
      <Row align='middle' justify='end' gutter={[15, 15]} style={{ marginBottom: 15 }}>
        <Col>
          <Button
            onClick={_clearFiltri}
            size='large'
          >
            Reset Filtri
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            dataSource={resi}
            columns={_getColumns()}
            onChange={_handleChange}
            size="small"
            scroll={{ x: 576, y: 400 }}
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "50", "100", "200"],
            }}
          />
        </Col>
      </Row>
      <Loading loading={loading} opaque />
    </Container>
  );
};

export default Resi;
