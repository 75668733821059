import React from "react";
import Sidebar from "react-sidebar";
import SidebarMenu from "./SidebarMenu";
import { Row, Col, Button, Badge, Tooltip, Alert, notification } from "antd";
import {
  BellOutlined,
  LogoutOutlined,
  MessageOutlined,
  ShoppingOutlined,
} from "@ant-design/icons";
import { logout } from "../Controllers/Auth";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Settings from "../Config/Settings";
import { isIOS, isMobile, isSafari } from "react-device-detect";
const mql = window.matchMedia(`(min-width: 800px)`);

const OneSignal = window.OneSignal || [];

async function getSubscriptionState() {
  var isPushEnabled = await OneSignal.isPushNotificationsEnabled();
  var isOptedOut = await OneSignal.isOptedOut();
  return {
    isPushEnabled: isPushEnabled,
    isOptedOut: isOptedOut,
  };
}
class Wrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      docked: false,
      open: false,
    };

    this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
    this.toggleOpen = this.toggleOpen.bind(this);
    this.onSetOpen = this.onSetOpen.bind(this);
    mql.addListener(this.mediaQueryChanged);
  }

  _initNotificationEnabled = () => {
    const self = this;
    OneSignal.push(function () {
      getSubscriptionState().then(function (state) {
        if (state.isPushEnabled) {
          self.setState({ enabled: true });
        } else {
          self.setState({ enabled: false });
        }
      });
    });
  };

  _subscribeOneSignal = () => {
    if (Notification.permission === "denied") {
      notification.destroy();
      notification.error({
        message: "Errore nelle autorizzazioni del browser",
        description:
          "Devi autorizzare la ricezione delle notifiche dal tuo browser per poter abilitare la funzionalità",
      });
    }
    OneSignal.push(function () {
      OneSignal.showNativePrompt();
    });
  };

  onSetOpen(open) {
    this.setState({ open });
  }

  mediaQueryChanged() {
    this.setState({
      docked: false,
      open: false,
    });
  }

  toggleOpen(ev) {
    this.setState({ open: !this.state.open });
    if (ev) {
      ev.preventDefault();
    }
  }

  menuButtonClick(ev) {
    ev.preventDefault();
    this.onSetOpen(!this.state.sidebarOpen);
  }

  componentDidMount() {
    this._initNotificationEnabled();
  }

  render() {
    return (
      <Sidebar
        sidebar={<SidebarMenu style={styles.menuContent} />}
        open={this.state.open}
        docked={false}
        onSetOpen={this.onSetOpen}
        transitions={false}
        shadow={false}
      >
        <Row
          align="middle"
          justify="space-between"
          className="header-sidebar-right"
          style={styles.fixedHeader}
        >
          <Col md={9} sm={9}>
            <Row align="middle" justify="start" gutter={15}>
              <Col onClick={this.toggleOpen}>
                <Row justify="center" align="bottom">
                  <div className="menu-hamburger" style={{ marginBottom: 0 }}>
                    <div
                      className={(this.state.open ? "change " : "") + "bar1"}
                    />
                    <div
                      className={(this.state.open ? "change " : "") + "bar2"}
                    />
                    <div
                      className={(this.state.open ? "change " : "") + "bar3"}
                    />
                  </div>
                </Row>
                <Row justify="center" align="top">
                  <span
                    style={{
                      color: Settings.colors.white,
                      cursor: "pointer",
                      fontSize: "12px",
                      fontWeight: 700,
                    }}
                  >
                    MENU'
                  </span>
                </Row>
              </Col>
              <Col>
                <img
                  style={{ height: "36px" }}
                  src={require("../Assets/Immagini/logo-inline.png")}
                  className="logoDesktop"
                />
                <img
                  style={{ height: "36px" }}
                  src={require("../Assets/Immagini/garzone-white-36x36.png")}
                  className="logoMobile"
                />
              </Col>
            </Row>
          </Col>
          <Col md={7}>
            <Row align="middle" justify="end" gutter={25}>
              <Col
                style={styles.clickable}
                onClick={() => this.props.history.push("/ordini")}
              >
                <Row justify="center">
                  <Link to="/ordini">
                    <Tooltip
                      title="Ordini"
                      placement="bottomRight"
                      color={Settings.colors.darkGrey}
                    >
                      <Badge count={this.props.ordini.toReadNumber}>
                        <ShoppingOutlined
                          style={{ fontSize: "24px", color: "white" }}
                        />
                      </Badge>
                    </Tooltip>
                  </Link>
                </Row>
                <Row justify="center">
                  <a
                    style={{
                      fontSize: "12px",
                      textDecoration: "none",
                      color: "white",
                      fontWeight: 700,
                    }}
                  >
                    ORDINI
                  </a>
                </Row>
              </Col>
              <Col
                style={styles.clickable}
                onClick={() => this.props.history.push("/chats")}
              >
                <Row justify="center">
                  <Link to="/chats">
                    <Tooltip
                      title="Chat"
                      placement="bottomRight"
                      color={Settings.colors.darkGrey}
                    >
                      <Badge count={this.props.chats.toReadNumber}>
                        <MessageOutlined
                          style={{ fontSize: "24px", color: "white" }}
                        />
                      </Badge>
                    </Tooltip>
                  </Link>
                </Row>
                <Row justify="center">
                  <a
                    style={{
                      fontSize: "12px",
                      textDecoration: "none",
                      color: "white",
                      fontWeight: 700,
                    }}
                  >
                    CHAT
                  </a>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        {!isMobile && !isIOS && !isSafari && !this.state.enabled ? (
          <Row style={{ marginTop: HEADER_HEIGHT, fontSize: 17 }}>
            <Col span={24}>
              <Alert
                message={
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: 0 }}
                  >
                    <Col>
                      <BellOutlined
                        style={{ marginRight: 5, color: Settings.colors.cyan }}
                      />{" "}
                      Tieniti aggiornato con le notifiche di Garzone
                    </Col>
                    <Col>
                      <Button onClick={this._subscribeOneSignal}>
                        Abilita le notifiche
                      </Button>
                    </Col>
                  </Row>
                }
                type="info"
                showIcon={false}
                closable
                style={{
                  zIndex: 1,
                  width: "100%",
                  borderRadius: 0,
                  padding: "5px 15px",
                }}
              />
            </Col>
          </Row>
        ) : null}
        <div
          style={
            !isMobile && !isSafari && !this.state.enabled
              ? { ...styles.wrapperContent, marginTop: 0 }
              : styles.wrapperContent
          }
        >
          {this.props.children}
        </div>
      </Sidebar>
    );
  }
}

const HEADER_HEIGHT = "64px";
const styles = {
  fixedHeader: {
    position: "fixed",
    width: "100%",
    height: HEADER_HEIGHT,
    backgroundColor: "#516375",
    top: 0,
    left: 0,
    padding: "0 20px",
    zIndex: 100,
  },
  wrapperContent: {
    padding: "38px 15px",
    marginTop: HEADER_HEIGHT,
  },
  menuContent: {
    marginTop: HEADER_HEIGHT,
  },
  contentHeaderMenu: {
    textDecoration: "none",
    padding: "8px",
    fontSize: "18px",
    color: "white",
    marginRight: "8px",
  },
  clickable: {
    cursor: "pointer",
  },
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    chats: state.chat,
    ordini: state.ordini,
  };
}

export default withRouter(connect(mapStateToProps, null)(Wrapper));
