import React from "react";
import { Row, Col, Button, Select } from "antd";
import Settings from "../Config/Settings";
import { getListaComuni } from "../Controllers/Comune";
import { connect } from "react-redux";
import { withRouter } from "react-router";

const { Option } = Select;

class Homepage extends React.Component {
  componentDidMount() {
    console.log(this.props);
    if (this.props.auth.user) {
      this.props.history.push("/dashboard");
    }
  }
  render() {
    return (
      <div className="login-background">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "15px 0",
          }}
        >
          <img
            src={require("../Assets/Immagini/logo.png")}
            style={{ height: 100, marginTop: 35, marginBottom: 39 }}
            alt="logo"
          />
        </div>
        <Row justify="center" style={{ textAlign: "center" }}>
          <Col span={24}>
            <h1 className="home-benvenuto">Benvenuto su Garzone!</h1>
            <h1 className="home-subtitle">
              Registra gratuitamente il tuo negozio
            </h1>
          </Col>
          <Col
            xs={{ span: 24 }}
            md={{ span: 8 }}
            style={{ color: Settings.colors.lightGrey }}
          >
            <p>
              Controlla se Garzone è attivo anche nella tua città e registra
              gratuitamente il tuo negozio.
            </p>
            <p>
              Pochi passaggi e avrai modo di far vedere il tuo negozio ai tuoi
              clienti e da tanti altri nuovi potenziali clienti.
            </p>
          </Col>
          <Col span={24}>
            <h3
              style={{
                color: Settings.colors.white,
                fontWeight: "semibold",
                marginTop: 40,
              }}
            >
              Garzone è il modo migliore di far ripartire il tuo business!
            </h3>
          </Col>
        </Row>
        <Row
          justify="center"
          gutter={20}
          style={{ textAlign: "center", marginTop: 30 }}
        >
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }}>
            <p
              className="home-caption"
              style={{ fontSize: 14, color: Settings.colors.lightGrey }}
            >
              Sei già registrato?
            </p>
            <Button
              size="large"
              block
              type="default"
              ghost
              style={{ marginBottom: 20 }}
              onClick={() => this.props.history.push("/accedi")}
            >
              Accedi al pannello
            </Button>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }}>
            <p
              className="home-caption"
              style={{ fontSize: 14, color: Settings.colors.lightGrey }}
            >
              Non sei ancora registrato?
            </p>
            <Button
              size="large"
              block
              type="primary"
              style={{ marginBottom: "15px" }}
              onClick={() => this.props.history.push("scegli-comune")}
            >
              Iscrivi il tuo negozio
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return { auth: state.auth };
}
export default withRouter(connect(mapStateToProps, null)(Homepage));
