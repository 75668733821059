import React from 'react';
import Settings from '../Config/Settings';

class TerminiCondizioni extends React.Component {
	render() {
		return (
			<div className="privacy" style={{ padding: '30px 8.333333%' }}>

			</div>
		);
	}
}

export default TerminiCondizioni;
