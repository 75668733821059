import React, { Component } from 'react';
import { Tag } from 'antd';
import Settings from '../Config/Settings';
class Chip extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        const {
            title,
            onClick
        } = this.props;

        return ( 
            <Tag style={styles.tag} onClick={onClick}>{title}</Tag>
         );
    }
}

const styles = {
    tag: {
        height: '24px',
        padding: '0 30px',
        borderRadius: '12px',
        fontSize: '14px',
        color: Settings.colors.grey,
        cursor:'pointer'
    }
}

export default Chip;