import React from 'react';
import Container from '../Components/Container';
import PageHeader from "../Components/PageHeader";

class Homepage extends React.Component {
  render() {
    return (
      <Container>
        <PageHeader
          title="Benvenuto su Garzone!"
          description="Da qui potrai gestire il tuo negozio: crea la tua vetrina online, aggiungi prodotti e servizi." />
        <p>Clicca in alto a sinistra sul menù per esplorare le varie sezioni</p>
      </Container>
    );
  }
}

export default Homepage;
