import React from "react";

// import ant design
import {
  Row,
  Col,
  Button,
  Input,
  Select,
  Checkbox,
  Result,
  Layout,
  notification,
  Space,
  Popover,
  Divider,
  Typography,
} from "antd";
import { FileDoneOutlined, QuestionCircleFilled } from "@ant-design/icons";

//import componenti
import Label from "../Components/Label";
import CustomResult from "../Components/CustomResult";
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

//import impostazioni validazione campi
import { validationRegistraNegozio } from "../Config/Validation";
import Settings from "../Config/Settings";

//import controllers
import { registraNegozio } from "../Controllers/Negozio";
import { getComune } from "../Controllers/Comune";
import { getCategorie } from "../Controllers/Categorie";
import { login, resendEmailVerification, logout } from "../Controllers/Auth";

// import components
import Timer from "../Components/Timer";

//impostazioni componenti
const { Option } = Select;
const { Footer } = Layout;
const { Title, Paragraph } = Typography;

const comuni_json = require("../Assets/comuni.json");

class RegistraNegozio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_citta_caps: [],
      step: "caricamento",
      categorie: [],
      loading: false,
      submit_button_text: "Registra la tua attività",
      comune: null,
      // servono per re inviare una email di conferma account dalla pagina "completato" nel caso l'utente abbia bisogno
      success_registered_user_email: "",
      success_registered_user_password: "",
      // timer resend email verification
      run_timer_resend_email: false,
    };
  }

  _submitForm = async (values, { setSubmitting }) => {
    this.setState({
      loading: true,
      submit_button_text: "ATTENDI...",
    });
    const {
      email,
      password,
      nome_referente,
      cognome_referente,
      telefono_referente,
      nome_negozio,
      categoria,
      partita_iva,
      indirizzo,
      cap,
      provincia,
      citta,
      civico,
      privacy_condizioni,
      accordo_comm,
      marketing,
      profilazione
    } = values;
    const { id } = this.state.comune;

    const negozioRecord = {
      id_comune: id,
      email: email,
      password: password,
      nome_referente: nome_referente,
      cognome_referente: cognome_referente,
      telefono_referente: `+${telefono_referente}`,
      nome_negozio: nome_negozio,
      categoria: categoria,
      partita_iva: partita_iva,
      indirizzo: indirizzo,
      cap: cap,
      provincia: provincia,
      citta: citta,
      civico: civico,
      privacy_condizioni: privacy_condizioni,
      accordo_comm: accordo_comm,
      marketing: marketing,
      profilazione: profilazione
    };

    try {
      // registro il negozio (verrà creata anche l'utenza firebase associata)
      const negozio = await registraNegozio(negozioRecord);
      // faccio il login in automatico (serve per inviare una email di conferma)
      const utente = await login(email, password);
      // invio email di conferma indirizzo di posta
      const email_verifica = await resendEmailVerification();
      // faccio logout dell'utente
      await logout();
      // tutto ok!
      this.setState({
        loading: false,
        step: "completato",
        success_registered_user_email: email,
        success_registered_user_password: password,
        run_timer_resend_email: true,
        submit_button_text: "Invia richiesta di adesione",
      });
    } catch (e) {
      this.setState({
        loading: false,
        submit_button_text: "Invia richiesta di adesione",
      });
      let desc = "Si è verificato un errore durante l'invio della richiesta";
      // Intercetto errore sul telefono o sull'email
      if (e.toString().indexOf("phone") !== -1) {
        desc = "Esiste già un account associato a questo numero di telefono";
      } else if (e.toString().indexOf("email") !== -1) {
        desc = "Esiste già un account associato a questo indirizzo email";
      } else if (e.toString().match("TOO_SHORT")) {
        desc = "Il numero di telefono è troppo corto";
      } else if (e.toString().match("Missing recipients")) {
        desc = "L'indirizzo email inserito è inseistente";
      }
      setSubmitting(false);
      notification.destroy();
      notification.warn({
        message: "Errore",
        description: desc,
      });
    }
  };

  _loader = async (slug) => {
    try {
      let comune = await getComune(slug);
      let categorie = await getCategorie();
      this.setState({
        comune: comune.data,
        categorie: categorie.data,
        loading: false,
        step: "registrazione",
      });
      console.log(comune);
    } catch (error) {
      console.log(error);
      notification.error(
        "Si è verificato un errore durante il caricamento della pagina, si prega di riprovare."
      );
    }
  };

  componentDidMount() {
    const slug = this.props.match.params.comune;
    this._loader(slug);
    console.log(this.state.comune);
  }

  render() {
    const initialValues = {
      email: "",
      password: "",
      conferma_password: "",
      nome_referente: "",
      cognome_referente: "",
      telefono_referente: "+39",
      partita_iva: "",
      nome_negozio: "",
      provincia: "",
      citta: null,
      cap: null,
      indirizzo: "",
      civico: "",
      categoria: null,
      privacy_condizioni: false,
      accordo_comm: false,
      marketing: false,
      profilazione: false
    };

    return this.state.step === "caricamento" ? (
      <Row style={{ paddingTop: 40 }}>
        <Col xs={{ span: 8, offset: 8 }} md={{ span: 2, offset: 11 }}>
          <img
            className="loading"
            src={require("../Assets/Immagini/loading.gif")}
          />
        </Col>
      </Row>
    ) : this.state.step === "registrazione" ? (
      <>
        <Row
          style={{
            paddingTop: 20,
            paddingLeft: 25,
            paddingBottom: 20,
            marginBottom: 20,
            backgroundColor: Settings.colors.darkGrey,
            color: Settings.colors.white,
          }}
          gutter={[0, 15]}
          justify="start"
          align="middle"
        >
          <Col>
            <img height="120px" src={this.state.comune.logo} />
          </Col>
          <Col>
            <Typography.Title level={3} style={{ marginBottom: 0, color: Settings.colors.white, marginLeft: 20, }}>{this.state.comune.nome}</Typography.Title>
          </Col>
        </Row>
        <Row justify="center" align="top" gutter={[0, 15]}>
          <Col xs={{ span: 20 }}>
            <Title style={styles.text} level={3}>
              Registra la tua attività commerciale al marketplace della tua
              città
            </Title>
            <Paragraph style={styles.text}>
              Completa tutti i campi e invia la tua richiesta di adesione a
              Garzone al tuo Comune, ti risponderà entro pochi giorni. La
              registrazione è totalmente gratuita.
            </Paragraph>
          </Col>
        </Row>
        <Divider />
        <Formik
          initialValues={initialValues}
          validationSchema={validationRegistraNegozio}
          onSubmit={this._submitForm}
        >
          {({
            touched,
            errors,
            values,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
            isValid,
            isSubmitting,
            dirty,
          }) => (
            <div style={{ paddingBottom: 100, padding: "15px 8.33333333%" }}>
              <Form>
                <Row justify="start" align="top" gutter={[15, 15]}>
                  <Col span={24}>
                    <Label titolo="Dati account" />
                  </Col>
                  <Col xs={24} md={8}>
                    <span
                      style={{
                        marginBottom: 10,
                        color: Settings.colors.darkGrey,
                      }}
                    >
                      <Space>
                        E-Mail
                        <Popover
                          content={
                            <div className="black-popover">
                              Questa sarà la mail con la quale fari l'accesso
                              alla piattaforma negozi
                            </div>
                          }
                        >
                          <QuestionCircleFilled />
                        </Popover>
                      </Space>
                    </span>
                    <Input
                      value={values.email}
                      onChange={handleChange("email")}
                      placeholder="Email"
                      onBlur={handleBlur("email")}
                    />
                    <div className="input-error">
                      {touched.email && errors.email}
                    </div>
                  </Col>
                  <Col xs={24} md={8}>
                    <span
                      style={{
                        marginBottom: 10,
                        color: Settings.colors.darkGrey,
                      }}
                    >
                      Password
                    </span>
                    <Input.Password
                      value={values.password}
                      onChange={handleChange("password")}
                      placeholder="Password"
                      onBlur={handleBlur("password")}
                    />
                    <div className="input-error">
                      {touched.password && errors.password}
                    </div>
                  </Col>
                  <Col xs={24} md={8}>
                    <span
                      style={{
                        marginBottom: 10,
                        color: Settings.colors.darkGrey,
                      }}
                    >
                      Ripeti password
                    </span>
                    <Input.Password
                      value={values.conferma_password}
                      onChange={handleChange("conferma_password")}
                      placeholder="Ripeti password"
                      onBlur={handleBlur("conferma_password")}
                    />
                    <div className="input-error">
                      {touched.conferma_password && errors.conferma_password}
                    </div>
                  </Col>
                </Row>

                <Divider />

                <Row justify="start" align="top" gutter={[15, 15]}>
                  <Col span={24}>
                    <Label titolo="Dati referente negozio" />
                  </Col>
                  <Col xs={24} md={8}>
                    <span
                      style={{
                        marginBottom: 10,
                        color: Settings.colors.darkGrey,
                      }}
                    >
                      Nome
                    </span>
                    <Input
                      value={values.nome_referente}
                      onChange={handleChange("nome_referente")}
                      placeholder="Nome"
                      onBlur={handleBlur("nome_referente")}
                    />
                    <div className="input-error">
                      {touched.nome_referente && errors.nome_referente}
                    </div>
                  </Col>
                  <Col xs={24} md={8}>
                    <span
                      style={{
                        marginBottom: 10,
                        color: Settings.colors.darkGrey,
                      }}
                    >
                      Cognome
                    </span>
                    <Input
                      value={values.cognome_referente}
                      onChange={handleChange("cognome_referente")}
                      placeholder="Cognome"
                      onBlur={handleBlur("cognome_referente")}
                    />
                    <div className="input-error">
                      {touched.cognome_referente && errors.cognome_referente}
                    </div>
                  </Col>
                  <Col xs={24} md={8}>
                    <span
                      style={{
                        marginBottom: 10,
                        color: Settings.colors.darkGrey,
                      }}
                    >
                      Telefono cellulare
                    </span>
                    <PhoneInput
                      value={values.telefono_referente}
                      placeholder="Telefono"
                      country="it"
                      enableSearch
                      countryCodeEditable={false}
                      searchPlaceholder="Cerca"
                      onChange={handleChange("telefono_referente")}
                      onBlur={handleBlur("telefono_referente")}
                      inputClass="input-text"
                      inputStyle={{ width: "100%" }}
                    />
                    <div className="input-error">
                      {touched.telefono_referente && errors.telefono_referente}
                    </div>
                  </Col>
                </Row>

                <Divider />

                <Row justify="start" align="top" gutter={[15, 15]}>
                  <Col xs={24}>
                    <Label titolo="Il tuo negozio" />
                  </Col>
                  <Col xs={24} md={8}>
                    <span
                      style={{
                        marginBottom: 10,
                        color: Settings.colors.darkGrey,
                      }}
                    >
                      Nome negozio
                    </span>
                    <Input
                      maxLength={100}
                      value={values.nome_negozio}
                      onChange={handleChange("nome_negozio")}
                      placeholder="Nome negozio"
                      onBlur={handleBlur("nome_negozio")}
                    />
                    <div className="input-error">
                      {touched.nome_negozio && errors.nome_negozio}
                    </div>
                  </Col>
                  <Col xs={24} md={8}>
                    <span
                      style={{
                        marginBottom: 10,
                        color: Settings.colors.darkGrey,
                      }}
                    >
                      Categoria
                    </span>
                    <Select
                      onChange={(value) => setFieldValue("categoria", value)}
                      size="default"
                      placeholder="Scegli una categoria"
                      style={{ width: "100%" }}
                      onBlur={handleBlur("categoria")}
                      value={values.categoria}
                    >
                      {this.state.categorie.map((c) => (
                        <Option key={c.nome}>{c.nome}</Option>
                      ))}
                    </Select>
                    <div className="input-error">
                      {touched.categoria && errors.categoria}
                    </div>
                  </Col>
                  <Col xs={24} md={8}>
                    <span
                      style={{
                        marginBottom: 10,
                        color: Settings.colors.darkGrey,
                      }}
                    >
                      <Space>
                        Partita IVA/C.F.
                        <Popover
                          content={
                            <div className="black-popover">
                              Non inserire "IT", se la tua partita IVA ne è
                              provvista. La partita iva è composta da 11 cifre.
                            </div>
                          }
                        >
                          <QuestionCircleFilled />
                        </Popover>
                      </Space>
                    </span>
                    <Input
                      value={values.partita_iva}
                      onChange={handleChange("partita_iva")}
                      placeholder="Partita IVA (senza prefisso 'IT') / Codice Fiscale"
                      onBlur={handleBlur("partita_iva")}
                    />
                    <div className="input-error">
                      {touched.partita_iva && errors.partita_iva}
                    </div>
                  </Col>
                </Row>
                <Divider />
                <Row justify="start" align="top" gutter={[15, 15]}>
                  <Col xs={24} md={{ span: 20 }}>
                    <span
                      className="reduce-hashtags"
                      style={{
                        marginBottom: 10,
                        color: Settings.colors.darkGrey,
                      }}
                    >
                      Indirizzo negozio
                    </span>
                    <Input
                      value={values.indirizzo}
                      onChange={handleChange("indirizzo")}
                      placeholder="Indirizzo negozio"
                      onBlur={handleBlur("indirizzo")}
                    />
                    <div className="input-error">
                      {touched.indirizzo && errors.indirizzo}
                    </div>
                  </Col>
                  <Col xs={24} md={{ span: 4 }}>
                    <span
                      className="reduce-hashtags"
                      style={{
                        marginBottom: 10,
                        color: Settings.colors.darkGrey,
                      }}
                    >
                      Civico
                    </span>
                    <Input
                      value={values.civico}
                      onChange={handleChange("civico")}
                      placeholder="N° civico"
                      onBlur={handleBlur("civico")}
                    />
                    <div className="input-error">
                      {touched.civico && errors.civico}
                    </div>
                  </Col>
                  <Col xs={24} md={{ span: 16 }}>
                    <span
                      className="reduce-hashtags"
                      style={{
                        marginBottom: 10,
                        color: Settings.colors.darkGrey,
                      }}
                    >
                      Città
                    </span>
                    <Select
                      showSearch
                      optionFilterProp="children"
                      onChange={(value) => {
                        setFieldValue("citta", value);
                        let comune = comuni_json.find((i) => i.nome == value);
                        setFieldValue(
                          "cap",
                          comune && Array.isArray(comune.cap)
                            ? comune.cap[0]
                            : comune.cap
                        );
                        this.setState({
                          selected_citta_caps:
                            comune && Array.isArray(comune.cap)
                              ? comune.cap
                              : [comune.cap],
                        });
                        setFieldValue("provincia", comune.provincia.nome);
                      }}
                      size="default"
                      placeholder="Scegli la città"
                      style={{ width: "100%" }}
                      onBlur={handleBlur("citta")}
                      value={values.citta}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {comuni_json.map((c) => (
                        <Option key={c.nome}>{c.nome}</Option>
                      ))}
                    </Select>
                    <div className="input-error">
                      {touched.citta && errors.citta}
                    </div>
                  </Col>
                  <Col xs={24} md={8}>
                    <span
                      className="reduce-hashtags"
                      style={{
                        marginBottom: 10,
                        color: Settings.colors.darkGrey,
                      }}
                    >
                      CAP
                    </span>
                    <Select
                      onChange={(value) => setFieldValue("cap", value)}
                      size="default"
                      placeholder="Seleziona o inserisci il cap"
                      style={{ width: "100%" }}
                      onBlur={handleBlur("cap")}
                      value={values.cap}
                    >
                      {values.citta
                        ? this.state.selected_citta_caps.map((c) => (
                          <Option key={c}>{c}</Option>
                        ))
                        : null}
                    </Select>
                    <div className="input-error">
                      {touched.cap && errors.cap}
                    </div>
                  </Col>
                </Row>
                <Divider />
                <Row>
                  <Col span={24}>
                    <Label
                      titolo="Termini e Condizioni d'uso"
                      desc="Per registrarsi al marketplace di Garzone è necessario sottoscrivere i termini e condizioni d’uso del servizio."
                    />
                  </Col>
                  <Col className="input-check" xs={24}>
                    <Checkbox onChange={handleChange("privacy_condizioni")}>
                      Confermo di aver preso visione delle condizioni generali di adesione al marketplace.{" "}
                      <Link
                        target="_blank"
                        style={{ color: Settings.colors.cyan }}
                        to={require("../Assets/Materiali/cond_gen.pdf")}
                      >
                        (Scopri di più)
                      </Link>
                    </Checkbox>
                    <div className="input-error">
                      {errors.privacy_condizioni}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Label
                      titolo="Accordo commerciale"
                      desc="Per accedere al marketplace di Garzone è necessario sottoscrivere l'accordo commerciale."
                    />
                  </Col>
                  <Col className="input-check" xs={24}>
                    <Checkbox onChange={handleChange("accordo_comm")}>
                      Confermo di aver preso visione dell'accordo commerciale e ne accetto i contenuti.{" "}
                      <Link
                        target="_blank"
                        style={{ color: Settings.colors.cyan }}
                        to={require("../Assets/Materiali/off_comm.pdf")}
                      >
                        (Scopri di più)
                      </Link>
                    </Checkbox>
                    <div className="input-error">
                      {errors.accordo_comm}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Label
                      titolo="Comunicazioni commerciali e newsletter"
                    />
                  </Col>
                  <Col className="input-check" xs={24}>
                    <Checkbox onChange={handleChange("marketing")}>
                      Letta la {" "}<Link target="_blank" to={require("../Assets/Materiali/privacy.pdf")}>Privacy policy</Link>, presto il mio consenso per l’invio a mezzo email, da parte di questo sito, di comunicazioni informative e promozionali, inclusa la newsletter, riferite a prodotti e/o servizi propri e/o di terzi e per lo svolgimento di ricerche di mercato.
                    </Checkbox>
                    <div className="input-error">
                      {errors.marketing}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Label
                      titolo="Profilazione"
                    />
                  </Col>
                  <Col className="input-check" xs={24}>
                    <Checkbox onChange={handleChange("profilazione")}>
                      Letta la {" "}<Link target="_blank" to={require("../Assets/Materiali/privacy.pdf")}>Privacy policy</Link>, presto il mio consenso per l’invio a mezzo e-mail, da parte di questo sito, di comunicazioni informative e promozionali, inclusa la newsletter, riferite a prodotti e/o servizi di mio specifico interesse.
                    </Checkbox>
                    <div className="input-error">
                      {errors.profilazione}
                    </div>
                  </Col>
                </Row>
                <Row style={{ marginTop: 40, marginBottom: 100 }}>
                  <Col span={24}>
                    <Button
                      icon={<FileDoneOutlined />}
                      loading={this.state.loading}
                      disabled={!(isValid && dirty) || isSubmitting}
                      block={true}
                      size="large"
                      onClick={handleSubmit}
                      type="primary"
                    >
                      {this.state.submit_button_text}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          )}
        </Formik>
        <Footer className="footer" style={{ textAlign: "center" }}>
          © Copyright 2020 - Tutti i diritti riservati - dglen srl - Via Turati
          26, 20121 Milano - Telefono: +39 026592398 – Email:{" "}
          <a
            style={{ color: Settings.colors.cyan }}
            href="mailto:info@garzone.store"
          >
            info@garzone.store
          </a>{" "}
          - P.IVA 09686140964 - Capitale Sociale € 10.000,00 -{" "}
          <Link style={{ color: Settings.colors.cyan }} to="/termini-e-condizioni">
            Privacy policy
          </Link>

        </Footer>
      </>
    ) : this.state.step === "completato" ? (
      <div className="login-background">
        <Row style={{ padding: "40px 20px" }}>
          <Col xs={{ span: 24, offset: 0 }} md={{ span: 12, offset: 6 }}>
            <CustomResult
              icon="success"
              title="Grazie per esserti registrato!"
              subtitle="Il tuo comune ha ricevuto la tua richiesta di adesione. Nel momento in cui il tuo profilo verrà approvato
					riceverai una mail entro pochi giorni."
            />
            <div style={{ textAlign: "center" }}>
              <p>
                <span style={{ color: "white" }}>
                  CONFERMA IL TUO ACCOUNT cliccando sul link che ti abbiamo
                  mandato per email (controlla anche la casella SPAM).
                </span>
                <br />
                <br />
                Non hai ricevuto nessuna email?
              </p>
              {this.state.run_timer_resend_email === true ? (
                <p style={{ fontSize: "14px" }}>
                  Attendi
                  <Timer
                    seconds={90}
                    isRunning={this.state.run_timer_resend_email}
                    onTimerEnds={() => {
                      this.setState({ run_timer_resend_email: false });
                    }}
                  />
                  secondi prima di richiedere una nuova email
                </p>
              ) : (
                <Button
                  type="primary"
                  onClick={async () => {
                    this.setState({ run_timer_resend_email: true });
                    // faccio il login in automatico (serve per inviare una email di conferma)
                    const utente = await login(
                      this.state.success_registered_user_email,
                      this.state.success_registered_user_password
                    );
                    // invio email di conferma indirizzo di posta
                    const email_verifica = await resendEmailVerification();
                    // faccio logout dell'utente
                    await logout();
                    // mostro il risultato
                    notification.destroy();
                    notification.success({
                      message: "PERFETTO!",
                      description:
                        "Email inviata al tuo indirizzo di posta. Controlla anche la casella spam e conferma il tuo account cliccando sul link che trovi all'interno della email",
                    });
                  }}
                >
                  Invia email di conferma account
                </Button>
              )}
              <Button
                type="link"
                block
                onClick={() => this.props.history.push("/")}
                style={{ marginTop: "30px" }}
              >
                Torna alla home
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    ) : this.state.step === "errore" ? (
      <Row style={{ paddingTop: 40 }}>
        <Col xs={{ span: 24, offset: 0 }} md={{ span: 8, offset: 8 }}>
          <Result
            status="404"
            title="Si è verificato un errore"
            subTitle="Non siamo riusciti a caricare il comune, controlla che il link di registrazione sia corretto"
            extra={
              <Button
                type="primary"
                onClick={() => this.props.history.push("/")}
              >
                Torna alla home
              </Button>
            }
          />
          ,
        </Col>
      </Row>
    ) : null;
  }
}

const styles = {
  text: {
    color: Settings.colors.darkGrey,
  },
};

export default RegistraNegozio;
