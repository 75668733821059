import { Col, Divider, notification, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import Container from "../Components/Container";
import LoadingBlock from "../Components/LoadingBlock";
import ModuloAgenda from "../Components/ModuloAgenda";
import ModuloChat from "../Components/ModuloChat";
import ModuloOrdini from "../Components/ModuloOrdini";
import ModuloStripe from "../Components/ModuloStripe";
import PageHeader from "../Components/PageHeader";
import StripeResAlert from "../Components/StripeResAlert";
import { getDatiNegozio } from "../Controllers/Negozio";

const Moduli = () => {
  const [loading, setLoading] = useState(true);
  const [negozio, setNegozio] = useState(null);

  const { id_negozio } = useSelector((state) => state.auth.user);

  useEffect(() => {
    _getNegozio();
  }, []);

  const _getNegozio = async () => {
    setLoading(true);
    try {
      const { data } = await getDatiNegozio(id_negozio);
      setNegozio(data);
    } catch (error) {
      notification.destroy();
      notification.error({
        message: "Errore",
        description: "Errore nella richiesta",
      });
      console.error(error);
    }

    setLoading(false);
  };

  const { search } = useLocation();
  const esito = new URLSearchParams(search).get("stripe_res");

  return (
    <Container>
      <PageHeader
        title="Funzionalità"
        description="Dalla sezione Funzionalità potrai gestire i moduli aggiuntivi per il tuo negozio come chat, ordini e appuntamenti."
      />
      <Divider />
      {esito && (
        <Row style={{ marginBottom: 15 }}>
          <StripeResAlert res={esito} />
        </Row>
      )}

      {!loading ? (
        <Row
          justify="start"
          align="top"
          gutter={[15, 15]}
          style={{ marginBottom: 15 }}
        >
          <Col md={12} sm={24}>
            <ModuloOrdini negozio={negozio} getNegozio={_getNegozio} />
          </Col>
          {negozio.modulo_ordini && (
            <Col md={12} sm={24}>
              <ModuloStripe negozio={negozio} getNegozio={_getNegozio} />
            </Col>
          )}
          <Col md={12} sm={24}>
            <ModuloChat negozio={negozio} getNegozio={_getNegozio} />
          </Col>
          <Col md={12} sm={24}>
            <ModuloAgenda negozio={negozio} getNegozio={_getNegozio} />
          </Col>
        </Row>
      ) : (
        <LoadingBlock loading={true} />
      )}
    </Container>
  );
};

export default Moduli;
