import { InfoCircleFilled, LeftOutlined } from "@ant-design/icons";
import {
  Col,
  Divider,
  Input,
  Button,
  notification,
  Row,
  Tooltip,
  Typography,
  Table,
  Select,
  Modal,
  InputNumber,
  Tag,
} from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import OrderList from "../Components/OrderList";

import LoadingBlock from "../Components/LoadingBlock";
import Settings from "../Config/Settings";
import firebase from "firebase";
import Container from "../Components/Container";

import { Link } from "react-router-dom";

import {
  getUtenteSql,
  getOrder,
  getProdotto,
  changeOrderStatus,
  getUtenteFirebase,
} from "../Controllers/Negozio";
import { _getOrderColor } from "../Config/Utility";
import ButtonCreaPreventivo from "../Components/ButtonCreaPreventivo";
import InfoOrderStatus from "../Components/InfoOrderStatus";
const { Option } = Select;
class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ordine: null,
      loadingChat: true,
      loadingMessages: true,
      visible_menu: false,
      chats: this.props.chats,
      chatInfo: null,
      userToData: null,
      sending: false,
      prodotti: [],
      numProd: 0,
      totale: 0,
      costoConsegna: 0,
      infoPressed: window.innerWidth < 992 ? false : true,
      confermaAnnullaVisible: false,
      loadingChangeStatus: false,
      prev_status: null,
      change_status_visible: false,
      motivo_annullamento: "",
      costo_consegna: 0,
    };
  }

  _closeMenu = () => {
    this.setState({ visible_menu: false });
  };

  _openMenu = () => {
    this.setState({ visible_menu: true });
  };
  annullaOrdine = () => {
    if (
      this.state.ordine.data.status === "annullato" ||
      this.state.ordine.data.status === "consegnato"
    ) {
      notification.destroy();
      notification.error({
        title: "Errore",
        message: "Non puoi annullare questo ordine",
      });
    } else {
      this.setState({ confermaAnnullaVisible: true });
    }
  };

  accettaOrdine = () => {
    if (this.state.ordine.data.status !== "In attesa di conferma") {
      notification.destroy();
      notification.error({
        title: "Errore",
        message: "Si è verificato un errore",
      });
    } else {
      this.setState({ confermaAccettaVisible: true });
    }
  };

  confermaAccetta = async () => {
    if (this.state.ordine.data.status !== "In attesa di conferma") {
      notification.destroy();
      notification.error({
        title: "Errore",
        message: "Si è verificato un errore",
      });
    } else {
      console.log(this.state.ordine.data);
      this.setState({ loadingChangeStatus: true });
      try {
        await changeOrderStatus({
          id: this.state.ordine.data.firebase_db_order_id,
          status:
            this.state.ordine.data.tipo_pagamento === "carta"
              ? "in attesa di pagamento"
              : "in lavorazione",
          from: this.state.ordine.data.from,
          to: this.state.ordine.data.to,
          costo_consegna: this.state.costo_consegna,
        });
        this.setState({
          loadingChangeStatus: false,
          confermaAccettaVisible: false,
        });
        notification.destroy();
        notification.success({
          title: "Perfetto",
          message: "Lo stato del tuo ordine è stato aggiornato correttamente",
        });
        window.location.reload();
      } catch (error) {
        console.log("Errore", error);
      }
    }
  };

  _getOrder = async (order_uid) => {
    const { orders } = this.props.ordini;
    const orderInfo = orders.find((i) => i.order_uid == order_uid);
    if (!orderInfo) {
      window.location.href = "/404";
      return;
    }
    const order = await getOrder({
      firebaseOrderId: order_uid,
      uid: firebase.auth().currentUser.uid,
    });
    let prodottiJson = order.data.dettagli;
    let prodotti = JSON.parse(prodottiJson);
    let res = await this.processProdotti(prodotti);

    const chatSubtitle = "subtitle";
    //const sqlUserTo = await getUtenteSql(order.data.from);
    const fbUserTo = await getUtenteFirebase(order.data.from);
    this.setState({
      chatInfo: {
        ...fbUserTo.data,
        ...orderInfo,
        chatSubtitle,
      },
      loadingChat: false,
      loadingMessages: false,
      numProd: res.numeroProdotti,
      totale: res.totale,
      ordine: order,
      prodotti: res.prodotti,
      costoConsegna: order.data.costo_consegna,
      prev_status: order.data.status,
      selected_status: order.data.status,
    });
  };
  processProdotti = async (prodotti) => {
    let prodottiFinal = [];

    let currentNum = 0;
    let currentTot = 0;
    for (let i = 0; i < prodotti.length; i++) {
      currentNum += parseFloat(prodotti[i].quantita);
      currentTot += parseFloat(prodotti[i].totale);
      let prodotto = prodotti[i].nome
        ? null
        : await getProdotto(prodotti[i].id);
      const record = {
        Quantita: prodotti[i].quantita,
        Prodotto: prodotto !== null ? prodotto.data.nome : prodotti[i].nome,
        PrezzoUnitario:
          (prodotti[i].totale / prodotti[i].quantita).toFixed(2) + " €",
        PrezzoTotale: prodotti[i].totale.toFixed(2) + " €",
      };
      prodottiFinal.push(record);
    }
    return {
      prodotti: prodottiFinal,
      numeroProdotti: currentNum,
      totale: currentTot,
    };
  };

  componentDidMount() {
    let {
      match: { params },
    } = this.props;
    const { order_uid } = params;
    this.setState({ loadingChat: true, loadingMessages: true });
    if (this.state.chatInfo === null || this.state.ordine === null)
      this._getOrder(order_uid);
  }

  _scrollToBottomMessagesBox = () => {
    let objDiv = document.getElementById("chat");
    objDiv.scrollTop = objDiv.scrollHeight;
  };

  componentDidUpdate(prevProps) {
    let {
      match: { params },
    } = this.props;
    const { order_uid } = params;
    if (
      prevProps.ordini.toReadNumber !== this.props.ordini.toReadNumber ||
      prevProps.match.params.order_uid !== order_uid
    ) {
      this.setState({
        loadingChat: true,
        loadingMessages: true,
        change_status_loading: false,
        change_status_visible: false,
      });
      this._getOrder(order_uid);
    }
  }

  confermaAnnulla = async () => {
    if (
      this.state.ordine.data.status === "annullato" ||
      this.state.ordine.data.status === "consegnato"
    ) {
      notification.destroy();
      notification.error({
        title: "Errore",
        message: "Non puoi annullare un ordine che è già stato confermato",
      });
    } else {
      this.setState({ loadingChangeStatus: true });
      try {
        await changeOrderStatus({
          id: this.state.ordine.data.firebase_db_order_id,
          status: "annullato",
          from: this.state.ordine.data.from,
          to: this.state.ordine.data.to,
        });
        this.setState({
          loadingChangeStatus: false,
          confermaAnnullaVisible: false,
        });
        notification.destroy();
        notification.success({
          title: "Perfetto",
          message: "Lo stato del tuo ordine è stato aggiornato correttamente",
        });
        window.location.reload();
      } catch (error) {
        console.log("Errore", error);
      }
    }
  };

  render() {
    const {
      loadingChat,
      loadingMessages,
      visible_menu,
      messages,
      ordine,
      userToData,
      chatInfo,
    } = this.state;
    let prezzoTotale = (
      parseFloat(this.state.totale) + parseFloat(this.state.costoConsegna)
    ).toFixed(2);
    const columns = [
      { title: "Quantità", dataIndex: "Quantita", key: "Quantita" },
      { title: "Prodotto", dataIndex: "Prodotto", key: "Prodotto" },
      {
        title: "Prezzo Unitario",
        dataIndex: "PrezzoUnitario",
        key: "PrezzoUnitario",
      },
      {
        title: "Prezzo Totale",
        dataIndex: "PrezzoTotale",
        key: "PrezzoTotale",
      },
    ];
    return (
      <>
        <Container>
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          >
            <div
              style={{
                position: "absolute",
                width: "400px",
                height: "100%",
                overflowY: "scroll",
                top: 0,
                left: 0,
                padding: "15px",
                paddingTop: "79px",
                zIndex: 9999,
                backgroundColor: Settings.colors.white,
                borderRight: "1px solid",
                borderColor: Settings.colors.lightGrey,
              }}
              className="chatPanel"
            >
              <OrderList />
            </div>
            <div
              style={{
                position: "absolute",
                left: "400px",
                top: 0,
                width: "calc(100% - 400px)",
                height: "100%",
                zIndex: 9999,
              }}
              className="chatList"
            >
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "70px",
                  top: "64px",
                  left: 0,
                  borderBottom: "1px solid",
                  zIndex: 9999,
                  backgroundColor: Settings.colors.white,
                  borderColor: Settings.colors.lightGrey,
                }}
              >
                {loadingChat ? null : (
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{
                      height: "100%",
                      paddingLeft: "15px",
                      paddingRight: "15px",
                    }}
                  >
                    <Col>
                      <Row justify="start" align="middle" gutter={15}>
                        <Col className="backToChats">
                          <Link to="/ordini">
                            <LeftOutlined /> Ordini
                          </Link>
                        </Col>

                        <Col className="orderStatus">
                          <Row style={{ marginBottom: "5px" }}>
                            <Typography.Title
                              level={3}
                              style={styles.chat_title}
                            >
                              Modifica lo stato dell'ordine quando procedi con
                              la sua evasione
                            </Typography.Title>
                          </Row>
                          <Row align="middle" style={styles.chat_subtitle}>
                            <Col>
                              <div
                                style={{
                                  width: "14px",
                                  height: "14px",
                                  borderRadius: "14px",
                                  marginRight: "14px",
                                  backgroundColor: _getOrderColor(
                                    ordine.data.status
                                  ),
                                }}
                              ></div>
                            </Col>
                            <Col flex={1}>
                              <Select
                                disabled={
                                  (ordine.data.status !== "in lavorazione" ||
                                    this.state.selected_status !==
                                      "in lavorazione") &&
                                  (ordine.data.status !== "in consegna" ||
                                    this.state.selected_status !==
                                      "in consegna") &&
                                  (ordine.data.status !==
                                    "pronto per il ritiro" ||
                                    this.state.selected_status !==
                                      "pronto per il ritiro")
                                }
                                onSelect={(value) => {
                                  this.setState({
                                    selected_status: value,
                                    change_status_visible: true,
                                  });
                                }}
                                size="default"
                                style={{ width: "100%" }}
                                defaultValue={ordine.data.status}
                                value={
                                  this.state.selected_status ??
                                  ordine.data.status
                                }
                              >
                                {this.state.selected_status !==
                                "in lavorazione" ? (
                                  <Option
                                    key="in lavorazione"
                                    value="in lavorazione"
                                  >
                                    In lavorazione
                                  </Option>
                                ) : null}

                                {this.state.ordine.data.tipo === "ritiro" &&
                                this.state.selected_status !==
                                  "pronto per il ritiro" ? (
                                  <Option
                                    key="pronto per il ritiro"
                                    value="pronto per il ritiro"
                                  >
                                    Pronto per il ritiro
                                  </Option>
                                ) : null}
                                {this.state.ordine.data.tipo === "consegna" &&
                                this.state.selected_status !== "in consegna" ? (
                                  <Option key="in consegna" value="in consegna">
                                    In consegna
                                  </Option>
                                ) : null}
                                {this.state.selected_status !== "consegnato" ? (
                                  <Option key="consegnato" value="consegnato">
                                    Consegnato
                                  </Option>
                                ) : null}
                              </Select>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Tooltip
                        title="Informazioni cliente"
                        placement="bottomRight"
                        color={Settings.colors.darkGrey}
                      >
                        <InfoCircleFilled
                          style={{
                            fontSize: 32,
                            color: this.state.infoPressed
                              ? Settings.colors.cyan
                              : Settings.colors.darkGrey,
                          }}
                          onClick={() =>
                            this.setState({
                              infoPressed: !this.state.infoPressed,
                            })
                          }
                        />
                      </Tooltip>
                    </Col>
                  </Row>
                )}
              </div>
              <div
                style={{
                  position: "absolute",
                  width: this.state.infoPressed ? "calc(100% - 300px)" : "100%",
                  height: "calc(100% - 203px)",
                  padding: "25px",
                  bottom: "75px",
                  top: "133px",
                  left: 0,
                  borderBottom: "1px solid",
                  overflowY: "scroll",
                  borderColor: Settings.colors.lightGrey,
                }}
                id="chat"
                className="chatList"
              >
                {loadingMessages ? (
                  <LoadingBlock loading={true} />
                ) : (
                  <Row gutter={[40, 40]}>
                    <Col xs={24} className="orderStatusPanel">
                      <Row style={{ marginBottom: "5px" }}>
                        <Typography.Title level={3} style={styles.chat_title}>
                          Modifica lo stato dell'ordine quando procedi con la
                          sua evasione
                        </Typography.Title>
                      </Row>
                      <Row align="middle" style={styles.chat_subtitle}>
                        <Col>
                          <div
                            style={{
                              width: "14px",
                              height: "14px",
                              borderRadius: "14px",
                              marginRight: "14px",
                              backgroundColor: _getOrderColor(
                                ordine.data.status
                              ),
                            }}
                          ></div>
                        </Col>
                        <Col flex={1}>
                          <Select
                            disabled={
                              (ordine.data.status !== "in lavorazione" ||
                                this.state.selected_status !==
                                  "in lavorazione") &&
                              (ordine.data.status !== "in consegna" ||
                                this.state.selected_status !== "in consegna") &&
                              (ordine.data.status !== "pronto per il ritiro" ||
                                this.state.selected_status !==
                                  "pronto per il ritiro")
                            }
                            onSelect={(value) => {
                              this.setState({
                                selected_status: value,
                                change_status_visible: true,
                              });
                            }}
                            size="default"
                            style={{ width: "100%" }}
                            defaultValue={ordine.data.status}
                            value={
                              this.state.selected_status ?? ordine.data.status
                            }
                          >
                            <Option
                              key="In attesa di conferma"
                              value="In attesa di conferma"
                              disabled
                            >
                              In attesa di conferma
                            </Option>
                            <Option
                              key="in lavorazione"
                              value="in lavorazione"
                              disabled={
                                this.state.selected_status ===
                                  "in lavorazione" ||
                                (this.state.selected_status !==
                                  "pronto per il ritiro" &&
                                  this.state.selected_status !== "in consegna")
                              }
                            >
                              In lavorazione
                            </Option>
                            {this.state.ordine.data.tipo === "ritiro" ? (
                              <Option
                                key="pronto per il ritiro"
                                value="pronto per il ritiro"
                                disabled={
                                  this.state.selected_status ===
                                  "pronto per il ritiro"
                                }
                              >
                                Pronto per il ritiro
                              </Option>
                            ) : null}
                            {this.state.ordine.data.tipo === "consegna" ? (
                              <Option
                                key="in consegna"
                                value="in consegna"
                                disabled={
                                  this.state.selected_status === "in consegna"
                                }
                              >
                                In consegna
                              </Option>
                            ) : null}
                            <Option
                              key="consegnato"
                              value="consegnato"
                              disabled={
                                this.state.selected_status === "consegnato"
                              }
                            >
                              Consegnato
                            </Option>
                            <Option key="annullato" value="annullato">
                              Annullato
                            </Option>
                          </Select>
                        </Col>
                      </Row>
                    </Col>
                    {/* Alert informazioni status dell'ordine */}
                    {!loadingMessages &&
                    this.state.ordine &&
                    this.state.ordine.data.status ? (
                      <Col span={24}>
                        <InfoOrderStatus
                          status={this.state.ordine.data.status}
                        />
                      </Col>
                    ) : null}
                    <Col xs={24} sm={24} md={12}>
                      <Row>
                        <span
                          style={{
                            fontSize: "17px",
                            color: Settings.colors.darkGrey,
                          }}
                        >
                          <strong>ID</strong>
                        </span>
                      </Row>
                      <Row>
                        <span
                          style={{
                            fontSize: "17px",
                            color: Settings.colors.darkGrey,
                          }}
                        >
                          {ordine.data.firebase_db_order_id}
                        </span>
                      </Row>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Row>
                        <span
                          style={{
                            fontSize: "17px",
                            color: Settings.colors.darkGrey,
                          }}
                        >
                          <strong>Cliente</strong>
                        </span>
                      </Row>
                      <Row>
                        <span
                          style={{
                            fontSize: "17px",
                            color: Settings.colors.darkGrey,
                          }}
                        >
                          {chatInfo.order_title}
                        </span>
                      </Row>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Row>
                        <span
                          style={{
                            fontSize: "17px",
                            color: Settings.colors.darkGrey,
                          }}
                        >
                          <strong>Data Ordine</strong>
                        </span>
                      </Row>
                      <Row>
                        <span
                          style={{
                            fontSize: "17px",
                            color: Settings.colors.darkGrey,
                          }}
                        >
                          {ordine.data.creazione}
                        </span>
                      </Row>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Row>
                        <span
                          style={{
                            fontSize: "17px",
                            color: Settings.colors.darkGrey,
                          }}
                        >
                          <strong>Modalità d'ordine</strong>
                        </span>
                      </Row>
                      <Row>
                        <span
                          style={{
                            fontSize: "17px",
                            color: Settings.colors.darkGrey,
                          }}
                        >
                          {ordine.data.tipo === "ritiro"
                            ? "Ritira in negozio"
                            : "Consegna a domicilio"}
                        </span>
                      </Row>
                    </Col>
                    {ordine.data.tipo !== "ritiro" ? (
                      <Col xs={24} sm={24} md={12}>
                        <Row>
                          <span
                            style={{
                              fontSize: "17px",
                              color: Settings.colors.darkGrey,
                            }}
                          >
                            <strong>Indirizzo di consegna</strong>
                          </span>
                        </Row>
                        <Row>
                          <span
                            style={{
                              fontSize: "17px",
                              color: Settings.colors.darkGrey,
                            }}
                          >
                            <a
                              href={`http://maps.google.com/?q=${ordine.data.indirizzo}`}
                              target="_blank"
                            >
                              {ordine.data.indirizzo}
                            </a>
                          </span>
                        </Row>
                      </Col>
                    ) : null}
                    <Col span={24} style={{ paddingBottom: 0 }}>
                      <span
                        style={{
                          fontSize: "17px",
                          color: Settings.colors.darkGrey,
                        }}
                      >
                        <strong>
                          Prodott{this.state.numProd > 1 ? "i" : "o"}
                          {` (${this.state.numProd})`}
                        </strong>
                      </span>
                    </Col>
                    <Col span={24} style={{ paddingTop: 0 }}>
                      <Table
                        pagination={false}
                        columns={columns}
                        dataSource={this.state.prodotti}
                        size="small"
                      />
                    </Col>
                    <Col span={24}>
                      <Row justify="space-between">
                        <span style={styles.text}>Subtotale</span>
                        <span style={styles.text}>
                          {parseFloat(this.state.totale).toFixed(2)} €
                        </span>
                      </Row>
                    </Col>
                    {ordine.data.tipo !== "ritiro" ? (
                      <Col span={24}>
                        <Row justify="space-between">
                          <span style={styles.text}>Costo consegna *</span>
                          <span style={styles.text}>
                            {ordine.data.costo_consegna !== 0
                              ? `${ordine.data.costo_consegna.toFixed(2)} €`
                              : ordine.data.status === "In attesa di conferma"
                              ? "Da definire"
                              : `${ordine.data.costo_consegna.toFixed(2)} €`}
                          </span>
                        </Row>
                        <Row>
                          <Col span={24}>
                            <span
                              style={{
                                fontSize: "12px",
                                color: Settings.colors.darkGrey,
                              }}
                            >
                              <strong>
                                <i>
                                  *Dettagli consegna:{" "}
                                  {this.props.auth.user.dettagli_consegna}
                                </i>
                              </strong>
                            </span>
                          </Col>
                        </Row>
                      </Col>
                    ) : null}
                    <Col span={24}>
                      <Row justify="space-between">
                        <span style={styles.text}>Tipo di pagamento</span>
                        <span style={styles.text}>
                          <strong>
                            {ordine.data.tipo_pagamento === "carta"
                              ? "Pagamento Online"
                              : ordine.data.tipo === "ritiro"
                              ? "Pagamento al ritiro"
                              : "Pagamento alla consegna"}
                          </strong>
                        </span>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row justify="space-between">
                        <span style={styles.text}>Totale</span>
                        <span style={styles.text}>
                          <strong>{prezzoTotale} €</strong>
                        </span>
                      </Row>
                    </Col>
                    {ordine.data.note !== "" ? (
                      <Col span={24}>
                        <span style={styles.text}>
                          <strong>Note</strong>
                        </span>{" "}
                        <br></br>
                        <span style={styles.text}>{ordine.data.note}</span>
                      </Col>
                    ) : null}
                    {ordine.data.motivo_annullamento !== "" &&
                    ordine.data.motivo_annullamento !== null ? (
                      <Col span={24}>
                        <span style={styles.text}>
                          <strong>Motivo annullamento ordine</strong>
                        </span>{" "}
                        <br></br>
                        <span
                          style={{ ...styles.text, color: Settings.colors.red }}
                        >
                          {ordine.data.motivo_annullamento}
                        </span>
                      </Col>
                    ) : null}
                  </Row>
                )}
              </div>
              {!loadingChat &&
              !loadingMessages &&
              ordine.data.status !== "consegnato" &&
              ordine.data.status !== "annullato" ? (
                <div
                  style={{
                    background: Settings.colors.white,
                    position: "absolute",
                    padding: "15px",
                    width: "100%",
                    height: "70px",
                    bottom: 0,
                    paddingRight: this.state.infoPressed ? "315px" : "15px",
                  }}
                  className="chatList"
                >
                  <Row justify="start" align="middle" gutter={[15, 15]}>
                    <Col style={{ marginTop: "5px" }}>
                      <Button
                        type="primary"
                        danger
                        onClick={() => {
                          this.annullaOrdine();
                        }}
                      >
                        Annulla
                      </Button>
                    </Col>
                    {this.state.ordine.data.status ===
                      "In attesa di conferma" && (
                      <Col style={{ marginTop: "5px" }}>
                        <Button
                          type="primary"
                          onClick={() => {
                            this.accettaOrdine();
                          }}
                        >
                          Accetta ordine
                        </Button>
                      </Col>
                    )}
                    {ordine.data.status === "Preventivo" ? (
                      <Col style={{ marginTop: "5px" }}>
                        <ButtonCreaPreventivo
                          precompiled
                          modify={true}
                          info={chatInfo}
                          extra={{
                            consegna: ordine.data.tipo,
                            tipo_pagamento: ordine.data.tipo_pagamento,
                            indirizzo: ordine.data.indirizzo,
                          }}
                          costo_consegna={ordine.data.costo_consegna}
                          prodotti={JSON.parse(ordine.data.dettagli)}
                          callbackUpdateComplete={() => {
                            this._getOrder(ordine.data.firebase_db_order_id);
                          }}
                          orderId={ordine.data.id}
                          realtimeDbId={ordine.data.firebase_db_order_id}
                          note={ordine.data.note} //TODO: aggiungere le note
                        />
                      </Col>
                    ) : null}
                  </Row>
                </div>
              ) : null}
              <div
                style={{
                  display: this.state.infoPressed ? "initial" : "none",
                  padding: "15px",
                  position: "absolute",
                  width: "300px",
                  height: "calc(100% - 133px)",
                  right: 0,
                  top: "133px",
                  borderLeft: "1px solid",
                  borderColor: Settings.colors.lightGrey,
                  zIndex: 9999,
                  overflowY: "scroll",
                  paddingTop: "30px",
                  textAlign: "center",
                }}
                className="chatDetails"
              >
                {loadingChat ? null : (
                  <>
                    <Row justify="center" align="middle" style={styles.section}>
                      <Tag
                        style={{
                          fontSize: "14px",
                          color: Settings.colors.white,
                          backgroundColor: Settings.colors.darkGrey,
                          cursor: "pointer",
                          margin: 0,
                        }}
                      >
                        CLIENTE
                      </Tag>
                    </Row>
                    <Row justify="center" align="middle" style={styles.section}>
                      <Typography.Title level={3} style={styles.rightTitle}>
                        {chatInfo.order_title}
                      </Typography.Title>
                    </Row>
                    <Row justify="center" align="middle" style={styles.section}>
                      <a href={`mailto:${chatInfo.email}`}>{chatInfo.email}</a>
                    </Row>
                    {chatInfo.phoneNumber ? (
                      <Row
                        justify="center"
                        align="middle"
                        style={styles.section}
                      >
                        <a href={`tel:${chatInfo.phoneNumber}`}>
                          {chatInfo.phoneNumber}
                        </a>
                      </Row>
                    ) : null}
                  </>
                )}
              </div>
            </div>
          </div>
          <Modal
            destroyOnClose
            title={<h2 style={styles.rightTitle}>Annulla ordine</h2>}
            visible={this.state.confermaAnnullaVisible}
            footer={null}
            onCancel={() => {
              this.setState({ confermaAnnullaVisible: false });
            }}
          >
            <Row gutter={[0, 30]}>
              <Col span={24}>
                <span>Sei sicuro di voler annullare l'ordine?</span>
              </Col>
              <Col span={24}>
                <Button
                  style={{ width: "100%" }}
                  type="primary"
                  onClick={() => this.confermaAnnulla()}
                  disabled={this.state.loadingChangeStatus}
                  danger
                >
                  Conferma annullamento
                </Button>
              </Col>
            </Row>
          </Modal>

          <Modal
            destroyOnClose
            title={
              <h2 style={styles.rightTitle}>Cambio di stato dell'ordine</h2>
            }
            visible={this.state.change_status_visible}
            footer={
              <Row align="middle" justify="center" gutter={[15, 15]}>
                <Col md={12} sm={24} xs={24}>
                  <Button
                    block
                    onClick={() =>
                      this.setState({
                        change_status_visible: false,
                        selected_status: this.state.prev_status,
                        costo_consegna: 0,
                        motivo_annullamento: "",
                      })
                    }
                  >
                    Annulla
                  </Button>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Button
                    block
                    type="primary"
                    loading={this.state.change_status_loading}
                    onClick={
                      this.state.selected_status === "annullato"
                        ? async () => {
                            this.setState({ change_status_loading: true });
                            if (this.state.motivo_annullamento !== "") {
                              try {
                                await changeOrderStatus({
                                  id: this.state.ordine.data
                                    .firebase_db_order_id,
                                  status: this.state.selected_status,
                                  from: this.state.ordine.data.from,
                                  to: this.state.ordine.data.to,
                                  motivo_annullamento:
                                    this.state.motivo_annullamento,
                                  costo_consegna: this.state.costoConsegna,
                                });
                                this.setState({
                                  selected_status: this.state.selected_status,
                                  prev_status: this.state.selected_status,
                                  motivo_annullamento: "",
                                });
                              } catch (error) {
                                console.log("errore", error);
                                notification.error({
                                  title: "Errore",
                                  description:
                                    "Si è verificato un errore durante il cambio di stato dell'ordine, contatta l'assistenza.",
                                });
                                this.setState({
                                  selected_status: this.state.prev_status,
                                  motivo_annullamento: "",
                                });
                              }
                            } else {
                              notification.destroy();
                              notification.error({
                                title: "Inserisci motivazione",
                                description:
                                  "Devi inserire una motivazione per l'annullamento dell'ordine da notificare al cliente!",
                              });
                              //this.setState({ change_status_loading: false });
                            }
                          }
                        : this.state.selected_status === "in lavorazione" &&
                          this.state.ordine.data.tipo === "consegna"
                        ? async () => {
                            try {
                              this.setState({
                                change_status_loading: true,
                                loadingChat: true,
                              });
                              await changeOrderStatus({
                                id: this.state.ordine.data.firebase_db_order_id,
                                status: this.state.selected_status,
                                from: this.state.ordine.data.from,
                                to: this.state.ordine.data.to,
                                costo_consegna: this.state.costo_consegna,
                              });
                              this.setState({
                                selected_status: this.state.selected_status,
                                prev_status: this.state.selected_status,
                                change_status_visible: false,
                                costo_consegna: 0,
                                //change_status_loading: false,
                              });
                            } catch (error) {
                              console.log("errore", error);
                              notification.error({
                                title: "Errore",
                                description:
                                  "Si è verificato un errore durante il cambio di stato dell'ordine, contatta l'assistenza.",
                              });
                              this.setState({
                                selected_status: this.state.prev_status,
                              });
                            } finally {
                              //this.setState({ change_status_loading: false });
                            }
                          }
                        : async () => {
                            try {
                              this.setState({
                                change_status_loading: true,
                                loadingChat: true,
                              });
                              await changeOrderStatus({
                                id: this.state.ordine.data.firebase_db_order_id,
                                status: this.state.selected_status,
                                from: this.state.ordine.data.from,
                                to: this.state.ordine.data.to,
                                costo_consegna: this.state.costoConsegna,
                              });
                              this.setState({
                                selected_status: this.state.selected_status,
                                prev_status: this.state.selected_status,
                                change_status_visible: false,
                              });
                            } catch (error) {
                              console.log("errore", error);
                              notification.error({
                                title: "Errore",
                                description:
                                  "Si è verificato un errore durante il cambio di stato dell'ordine, contatta l'assistenza.",
                              });
                              this.setState({
                                selected_status: this.state.prev_status,
                              });
                            } finally {
                              //this.setState({ change_status_loading: false });
                            }
                          }
                    }
                  >
                    Conferma
                  </Button>
                </Col>
              </Row>
            }
            closable={false}
            onCancel={() => {
              this.setState({
                change_status_visible: false,
                selected_status: this.state.prev_status,
                costo_consegna: 0,
                motivo_annullamento: "",
              });
            }}
          >
            <Row>{`Sei sicuro di voler cambiare stato all'ordine da "${
              this.state.prev_status
            }" a "${this.state.selected_status}"? ${
              this.state.selected_status === "consegnato"
                ? "Dopo aver cambiato lo stato in 'consegnato' non potrai più modificarlo"
                : ""
            }`}</Row>
            {this.state.selected_status === "annullato" ? (
              <>
                <Row>
                  <strong>
                    Annullando l'ordine non potrai più effettuare cambi di
                    stato.
                  </strong>
                </Row>
                <Divider />
                <Row>
                  <Col xs={24} sm={24} md={24}>
                    <span style={styles.spanCol}>Motivo Annullamento</span>
                    <Input.TextArea
                      maxLength={1500}
                      autoSize={{ minRows: 7, maxRows: 7 }}
                      value={this.state.motivo_annullamento}
                      onChange={(e) =>
                        this.setState({ motivo_annullamento: e.target.value })
                      }
                      placeholder="Inserisci il motivo dell'annullamento per farlo sapere al cliente..."
                    />
                  </Col>
                </Row>
              </>
            ) : null}
            {this.state.selected_status === "in lavorazione" &&
            this.state.ordine.data.tipo === "consegna" ? (
              <>
                <Row>
                  <strong>
                    Dopo la conferma il cliente sarà avvisato del costo di
                    consegna inserito.
                  </strong>
                </Row>
                <Divider />
                <Row>
                  <Col xs={24} sm={24} md={24}>
                    <span style={{ ...styles.spanCol, marginRight: "5px" }}>
                      Inserisci costo di consegna:
                    </span>
                    <InputNumber
                      style={{ marginRight: "5px" }}
                      min={0}
                      max={100}
                      step={0.01}
                      decimalSeparator={","}
                      defaultValue={0}
                      value={this.state.costo_consegna}
                      onChange={(value) =>
                        this.setState({ costo_consegna: value })
                      }
                    />
                    €
                  </Col>
                </Row>
              </>
            ) : null}
          </Modal>
          <Modal
            destroyOnClose
            title={<h2 style={styles.rightTitle}>Accetta ordine</h2>}
            visible={this.state.confermaAccettaVisible}
            footer={null}
            onCancel={() => {
              this.setState({ confermaAccettaVisible: false });
            }}
          >
            <Row gutter={[0, 30]}>
              {this.state.ordine &&
              this.state.ordine.data.status === "In attesa di conferma" &&
              this.state.ordine.data.tipo === "consegna" ? (
                <>
                  <Col xs={24} sm={24} md={24}>
                    <span style={{ ...styles.spanCol, marginRight: "5px" }}>
                      Inserisci costo di consegna:
                    </span>
                    <InputNumber
                      style={{ marginRight: "5px" }}
                      min={0}
                      max={100}
                      step={0.01}
                      decimalSeparator={","}
                      defaultValue={0}
                      value={this.state.costo_consegna}
                      onChange={(value) =>
                        this.setState({ costo_consegna: value })
                      }
                    />
                    €
                  </Col>
                </>
              ) : null}
              <Col span={24}>
                <span>Sei sicuro di voler accettare l'ordine?</span>
              </Col>
              <Col span={24}>
                <Button
                  style={{ width: "100%" }}
                  type="primary"
                  onClick={() => this.confermaAccetta()}
                  disabled={this.state.loadingChangeStatus}
                >
                  Accetta l'ordine
                </Button>
              </Col>
            </Row>
          </Modal>
        </Container>
      </>
    );
  }
}

const styles = {
  chat_image: {
    height: "40px",
    borderRadius: "4px",
    overflow: "hidden",
    marginTop: "7.5px",
  },
  chat_title: {
    fontSize: 14,
    fontWeight: 700,
    color: Settings.colors.darkGrey,
    margin: 0,
  },
  chat_subtitle: {
    fontSize: 14,
    fontWeight: 500,
    color: Settings.colors.grey,
    margin: 0,
  },
  rightTitle: {
    fontSize: 22,
    fontWeight: 700,
    color: Settings.colors.darkGrey,
    margin: 0,
  },
  rightSubtitle: {
    fontSize: 14,
    fontWeight: 500,
    color: Settings.colors.grey,
  },
  section: {
    marginBottom: "10px",
  },
  text: {
    fontSize: "17px",
    color: Settings.colors.darkGrey,
  },
  spanCol: {
    marginBottom: 10,
    color: Settings.colors.darkGrey,
    fontWeight: 700,
  },
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    ordini: state.ordini,
  };
}

export default connect(mapStateToProps, null)(Orders);
