import { EditOutlined, SaveOutlined } from '@ant-design/icons';
import { Col, Divider, Input, Row, Select, Switch, Checkbox, Button, notification } from 'antd';
import Form from 'antd/lib/form/Form';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import Settings from '../Config/Settings';
import { validationModuloChat } from '../Config/Validation';
import { aggiornaModuloChat } from '../Controllers/Negozio';

const ModuloChat = ({ negozio, getNegozio }) => {

  const _setModuloChat = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    try {
      await aggiornaModuloChat(negozio.id, values);
      notification.destroy();
      notification.success({
        message: 'Congratulazioni!',
        description: 'Hai aggiornato con successo il modulo chat'
      })
      getNegozio()
    } catch (error) {
      notification.destroy();
      notification.error({
        message: 'Errore',
        description: 'Errore nella richiesta'
      })
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  }

  const initialValues = {
    chat: negozio.chat,
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationModuloChat}
      onSubmit={_setModuloChat}
      validateOnMount
      validateOnBlur
      validateOnChange
    >
      {({
        touched,
        errors,
        values,
        handleChange,
        handleSubmit,
        handleBlur,
        setFieldValue,
        setTouched,
        isValid,
        dirty,
        isSubmitting,
      }) => (
        <Form>
          <Row>
            <Col
              style={{
                backgroundColor: Settings.colors.darkerGrey,
                color: Settings.colors.white,
                borderRadius: "4px",
                padding: "10px",
              }}

              span={24}
            >
              <span style={{ fontSize: "24px" }}>Modulo Chat</span>{" "}
              <br></br>
              <br></br>
              <span>
                Permetti ai clienti di inviare richieste di informazioni
                tramite la chat interna di Garzone.
              </span>
              <br />
              <br></br>
              <Switch
                defaultChecked={values.chat}
                onChange={(e) => {
                  setFieldValue("chat", e);
                }}
              />
              <span style={{ marginLeft: "15px" }}>Abilita Modulo</span>
              <Divider />
              <Row justify="center" align="middle">
                <Col span={24}>
                  <Button
                    icon={<EditOutlined />}
                    loading={isSubmitting}
                    disabled={!(isValid && dirty) || isSubmitting}
                    block={true}
                    size="large"
                    onClick={handleSubmit}
                    type="primary"
                  >
                    {isSubmitting ? "Attendi..." : "Aggiorna"}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>

        </Form>
      )}
    </Formik>
  )
}

export default ModuloChat;