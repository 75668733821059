import { Col, Row } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import ChatList from "../Components/ChatList";
import Container from "../Components/Container";
import Loading from "../Components/Loading";
import OrderList from "../Components/OrderList";
import Settings from "../Config/Settings";

class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      visible_menu: false,
    };
  }

  _closeMenu = () => {
    this.setState({ visible_menu: false });
  };

  _openMenu = () => {
    this.setState({ visible_menu: true });
  };

  render() {
    const { loading, visible_menu } = this.state;
    return (
      <>
        <Container>
          <div style={{ position: "fixed", top: 0, left: 0, width: "100%", height: "100%" }}>
            <div style={{ position: "absolute", width: "400px", height: "100%", overflowY: "scroll", top: 0, left: 0, padding: '15px', paddingTop: "79px", zIndex: 9999, backgroundColor: Settings.colors.white, borderRight: '1px solid', borderColor: Settings.colors.lightGrey }} className="chatList">
              <OrderList />
            </div>
          </div>
        </Container>


        <Loading loading={loading} opaque />
      </>
    );
  }
}


export default Orders;
