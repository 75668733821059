
import React, { Component } from 'react';

import { ShopOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';

import CustomResult from '../Components/CustomResult';

class FunzioneNonAbilitata extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div style={styles.loginBackground}>
                <Row justify="center" align="middle">
                    <Col>
                        <img src={require("../Assets/Immagini/logo.png")} style={styles.logoStyle} alt="logo" />
                    </Col>
                </Row>
                <Row justify='center' align='middle' gutter={[15, 15]}>
                    <CustomResult
                        icon="info"
                        title="Funzionalità disabilitata"
                        subtitle="Non hai abilitato questa funzionalità, recati nel pannello apposito per abilitarla"
                        button={{ title: "Vai al pannello di controllo", route: () => this.props.history.push("/il-mio-negozio"), icon: <ShopOutlined /> }}
                    />
                </Row>
            </div>
        );
    }
}
const styles = {
    loginBackground: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: `url(${require('../Assets/Immagini/background.png')})`,
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        padding: '15px'
    },
    logoStyle: {
        height: 100,
        marginTop: 35,
        marginBottom: 35
    },
}

export default FunzioneNonAbilitata;