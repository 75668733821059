import React from "react";
import { Row, Col, Button, Select, Switch, notification } from "antd";
import Settings from "../Config/Settings";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Formik } from "formik";
import Form from "antd/lib/form/Form";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { aggiornaFlagNegozio } from "../Controllers/Negozio";

const { Option } = Select;

class Condizioni extends React.Component {
  _submitForm = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    const { privacy, accordo_comm } = values;

    try {
      const { data } = await aggiornaFlagNegozio(
        this.props.auth.user.id_negozio,
        {
          privacy_condizioni: privacy,
          accordo_comm: accordo_comm,
        }
      );
      notification.destroy();
      notification.success({
        message: "Dati aggiornati",
        description: "Il tuoi dati sono stati correttamente aggiornati",
      });
      window.location.href = "/il-mio-negozio";
    } catch (e) {
      notification.destroy();
      notification.error({
        message: "Errore!",
        description:
          "Non è stato possibile processare la tua richiesta, contatta l'assistenza",
      });
      console.error("Errore", e);
    } finally {
      setSubmitting(false);
    }
  };

  componentDidMount() {
    if (!this.props.auth.user) {
      this.props.history.push("/accedi");
    }
  }

  render() {
    return (
      <div className="login-background">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "15px 0",
          }}
        >
          <img
            src={require("../Assets/Immagini/logo.png")}
            style={{ height: 100, marginTop: 35, marginBottom: 39 }}
            alt="logo"
          />
        </div>
        <Row justify="center" style={{ textAlign: "center" }}>
          <Col span={24}>
            <h1 className="home-benvenuto">
              Accetta i termini e le condizioni del servizio per proseguire su
              Garzone
            </h1>
          </Col>
          <Col span={24} style={{ color: Settings.colors.lightGrey }}>
            <Formik
              validationSchema={Yup.object().shape({
                privacy: Yup.bool().oneOf(
                  [true],
                  "Devi accettare le condizioni di privacy per continuare"
                ),
                accordo_comm: Yup.bool().oneOf(
                  [true],
                  "Devi accettare l'accordo commerciale per proseguire"
                ),
              })}
              validateOnMount={true}
              validateOnChange={true}
              initialValues={{
                privacy: this.props.location.state.privacy,
                accordo_comm: this.props.location.state.accordo_comm,
              }}
              onSubmit={this._submitForm}
            >
              {({
                touched,
                errors,
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isValid,
                isSubmitting,
                dirty,
              }) => (
                <Form>
                  <Col span={24}>
                    <Row
                      justify="center"
                      align="middle"
                      gutter={15}
                      style={{ marginTop: "25px" }}
                    >
                      <Col>
                        <Switch
                          checked={values.privacy}
                          onChange={(value) => setFieldValue("privacy", value)}
                        />
                      </Col>
                      <Col>
                        <span style={styles.switchText}>
                          Confermo di aver preso visione delle condizioni
                          generali di adesione al marketplace.{" "}
                          <Link
                            target="_blank"
                            style={{ color: Settings.colors.cyan }}
                            to={require("../Assets/Materiali/cond_gen.pdf")}
                          >
                            (Scopri di più)
                          </Link>
                        </span>
                        <span style={styles.inputError}>
                          {touched.privacy && errors.privacy}
                        </span>
                      </Col>
                    </Row>

                    <Row
                      justify="center"
                      align="middle"
                      gutter={15}
                      style={{ marginTop: "25px" }}
                    >
                      <Col>
                        <Switch
                          checked={values.accordo_comm}
                          onChange={(value) =>
                            setFieldValue("accordo_comm", value)
                          }
                        />
                      </Col>
                      <Col>
                        <span style={styles.switchText}>
                          Confermo di aver preso visione dell'accordo
                          commerciale e ne accetto i contenuti.{" "}
                          <Link
                            target="_blank"
                            style={{ color: Settings.colors.cyan }}
                            to={require("../Assets/Materiali/off_comm.pdf")}
                          >
                            (Scopri di più)
                          </Link>
                        </span>
                        <span style={styles.inputError}>
                          {touched.accordo_comm && errors.accordo_comm}
                        </span>
                      </Col>
                    </Row>
                  </Col>
                  <Row
                    justify="center"
                    gutter={20}
                    style={{ textAlign: "center", marginTop: 30 }}
                  >
                    <Col>
                      <Button
                        size="large"
                        block
                        loading={isSubmitting}
                        type="primary"
                        style={{ marginBottom: 20 }}
                        disabled={!(isValid && dirty) || isSubmitting}
                        onClick={handleSubmit}
                      >
                        Aggiorna le condizioni
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </div>
    );
  }
}
const styles = {
  switchText: {
    color: Settings.colors.white,
  },
  inputError: {
    color: Settings.colors.red,
    fontSize: "14px",
  },
};
function mapStateToProps(state) {
  return { auth: state.auth };
}
export default withRouter(connect(mapStateToProps, null)(Condizioni));
