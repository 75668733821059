import React from "react";
import moment from 'moment';
import 'moment/locale/it';
import locale from 'antd/es/date-picker/locale/it_IT';
import { connect } from "react-redux";
//import antd
import { Row, Col, Button, Input, Modal, Drawer, Divider, Table, Space, notification, message, Tag, DatePicker, ConfigProvider, Empty } from "antd";
import {
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  CloseOutlined,
  PlusOutlined
} from "@ant-design/icons";

//import components and styles
import { Formik, Form } from "formik";
import Highlighter from "react-highlight-words";
import Container from "../Components/Container";
import PageHeader from "../Components/PageHeader";

//import settings from config
import Settings from "../Config/Settings";
import { validationSchemaPromozione } from "../Config/Validation";

// import Registrazione controller functions
import {
  eliminaPromozione,
  aggiornaPromozione,
  creaPromozione,
  getPromozioni,
  getPromozione
} from "../Controllers/Promozioni";

import ImageCropper from "../Components/ImageCropper";
import Label from "../Components/Label";
import Loading from "../Components/Loading";

//Costanti
const { confirm } = Modal;
const { TextArea } = Input;

class Promozioni extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      loading_logo: false,

      drawer_visible: false,

      searchText: "",
      searchedColumn: "",
      filteredInfo: null,
      sortedInfo: null,
      selected_promo: null,
      selected_index: null,

      promozioni: []
    };
  }

  //#region Helpers Gestione Promozioni
  _eliminaPromozione = (promozione, indice) => {
    confirm({
      title: "Elimina promozione",
      icon: <ExclamationCircleOutlined />,
      content: `Sei sicuro di voler eliminare la promozione: ${promozione.titolo}?`,
      okText: "Elimina",
      okType: "danger",
      cancelText: "Chiudi",
      onOk: async () => {
        try {
          //Rimuovo promozione dal db
          await eliminaPromozione(promozione);

          //Rimuovo promozione nello stato
          let promozioni = [...this.state.promozioni];
          promozioni.splice(indice, 1);
          this.setState({ promozioni: promozioni });
          notification.destroy();
          notification.success({
            message: "PERFETTO!",
            description: `Hai eliminato ${promozione.titolo} con successo`
          });
        } catch (error) {
          console.log("errore");
          notification.error({ title: "Errore", description: "Si è verificato un errore durante l'eliminazione della promozione" });
        }
      },
    });
  };

  _submitForm = async (values, { setSubmitting }) => {
    const { selected_promo } = this.state;
    const { user } = this.props.auth;
    let promozioni = [...this.state.promozioni];

    let message, description;
    const {
      titolo,
      descrizione,
      immagine,
      data_scadenza
    } = values;

    if (moment(data_scadenza, 'DD/MM/YYYY HH:mm').isSameOrBefore(moment())) {
      notification.destroy();
      notification.error({
        message: 'Data di scadenza non valida!',
        description: 'Modifica la data di scadenza, deve essere successiva alla data di oggi'
      });
      return;
    }

    const promozioneRecord = {
      id_negozio: user.id_negozio,
      titolo: titolo,
      descrizione: descrizione,
      immagine: immagine,
      data_scadenza: data_scadenza
    }

    try {
      let promozione;
      if (selected_promo) {
        const { id } = selected_promo;

        promozione = await aggiornaPromozione(id, promozioneRecord);
        message = "Promozione aggiornata!";
        description = `La promozione ${titolo} è stata correttamente aggiornata`;

        const foundIndex = promozioni.findIndex(x => x.id === id);
        promozioni[foundIndex] = {
          id: id,
          ...promozioneRecord,
          immagine: promozione.data.img_url ?? immagine
        };

        this.setState({
          promozioni: promozioni
        });

      } else {
        promozione = await creaPromozione(promozioneRecord);

        message = "Promozione creata";
        description = `La promozione ${titolo} è stata correttamente creata`;

        promozioni.unshift({
          id: promozione.data.id,
          ...promozioneRecord,
          immagine: promozione.data.img_url
        });
        this.setState({
          promozioni: promozioni
        });
      }

      this.setState({
        drawer_visible: false,
        selected_promo: null,
        selected_index: null
      });

      notification.destroy();
      notification.success({
        message: message,
        description: description,
      });
    } catch (e) {
      console.log("Errore", e);
      setSubmitting(false);
      notification.destroy();
      if (e.code.includes("aborted")) {
        notification.warn({
          message: 'Si è verificato un errore interno',
          description: e.details.dettaglio
        });
        this.setState({ drawer_visible: false });
      } else {
        notification.warn({
          message: "Errore",
          description: "Si è verificato un errore",
        });
      }
    }
  };
  //#endregion

  //#region Helpers Table
  _clearFiltri = () => {
    this.setState({
      filteredInfo: null,
      sortedInfo: null
    })
  }

  _getColumns = () => {
    let { sortedInfo, filteredInfo } = this.state;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};
    return [
      {
        title: "Promozione",
        dataIndex: "titolo",
        key: "titolo",
        width: 150,
        sorter: (a, b) => a.titolo.localeCompare(b.titolo),
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("titolo", "titolo"),
        filteredValue: filteredInfo.titolo || null,
        sortOrder: sortedInfo.columnKey === 'titolo' && sortedInfo.order,
      },
      {
        title: "Data di Scadenza",
        dataIndex: "data_scadenza",
        key: "data_scadenza",
        width: 150,
        sorter: (a, b) => a.data_scadenza.localeCompare(b.data_scadenza),
        render: (text, record, index) => <span>{moment(record.data_scadenza, 'DD/MM/YYYY HH:mm').toString()}</span>,
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("data_scadenza", "data_scadenza"),
        filteredValue: filteredInfo.data_scadenza || null,
        sortOrder: sortedInfo.columnKey === 'data_scadenza' && sortedInfo.order,
      },
      {
        title: "Stato",
        key: "expired",
        width: 100,
        render: (text, record, index) => <span>{moment(record.data_scadenza, 'DD/MM/YYYY HH:mm').isSameOrBefore(moment()) ? <Tag color="error">Scaduta</Tag> : <Tag color="green">In corso</Tag>}</span>,
      },
      {
        title: "",
        dataIndex: "settings",
        key: "settings",
        fixed: "right",
        width: 100,
        render: (text, record, index) => (
          <Row justify="space-around" align="middle">
            <Col>
              <DeleteOutlined
                onClick={async () => {
                  this._eliminaPromozione(record, index);
                }}
                style={{ color: Settings.colors.grey, fontSize: 18 }}
              />
            </Col>
            <Col>
              <EditOutlined
                style={{ color: Settings.colors.grey, fontSize: 18 }}
                onClick={async () => {
                  this.setState({
                    loaded: false
                  });
                  const { data } = await getPromozione(record.id);
                  this.setState({
                    drawer_visible: true,
                    loaded: true,
                    selected_promo: data,
                    selected_index: index
                  })
                }}
              />
            </Col>
          </Row>
        ),
      },
    ];
  };

  //Ricerca
  getColumnSearchProps = (title, dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Cerca ${title}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Cerca
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.filteredInfo == null
        ? text
        :
        this.state.searchedColumn === dataIndex && this.state.filteredInfo[dataIndex] != null ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          text
        ),
  });

  //Ricerca
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  //Ricerca
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  _handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  //#endregion

  //#region Lifecycle componente
  _loader = async () => {
    const { user } = this.props.auth;
    try {
      this.setState({
        loaded: false
      });

      let promozioni = await getPromozioni(user.id_negozio);

      this.setState({
        promozioni: promozioni.data,
        loaded: true
      });
    } catch (error) {
      message.error("Si è verificato un errore durante il caricamento della pagina, si prega di riprovare.");
    }
  };

  componentDidMount() {
    this._loader();
  }

  render() {
    const { selected_promo, loaded } = this.state;
    const {
      titolo,
      descrizione,
      immagine,
      data_scadenza
    } = selected_promo || {};
    const initialValues = {
      titolo: titolo ?? '',
      descrizione: descrizione ?? '',
      immagine: immagine ?? null,
      data_scadenza: data_scadenza ? moment(data_scadenza, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY HH:mm') : moment().add('days', 1).format('DD/MM/YYYY HH:mm')
    }

    return (
      <>
        <Container>
          <PageHeader
            title="Promozioni"
            description="In questa sezione ci sono tutte le promozioni del tuo negozio." />
          <Divider />
          <Row align='middle' justify='end' gutter={[15, 15]} style={{ marginBottom: 15 }}>
            <Col>
              <Button
                onClick={this._clearFiltri}
                size='large'
              >
                Reset Filtri
              </Button>
            </Col>
            <Col>
              <Button
                icon={<PlusOutlined />}
                onClick={() => this.setState({ drawer_visible: true })}
                type="primary"
                size='large'
              >
                Aggiungi promozione
              </Button>
            </Col>
          </Row>
          <ConfigProvider
            renderEmpty={() => <Empty description={<span>Nessuna promozione, creane una premendo su "Aggiungi promozione"</span>} />}
          >
            <Table
              bordered
              dataSource={this.state.promozioni}
              columns={this._getColumns()}
              onChange={this._handleChange}
              size="small"
              scroll={{ x: 576, y: 400 }}
              pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ["10", "20", "50", "100", "200"] }}
            />
          </ConfigProvider>
          {/*Drawer creazione/modifica promozione*/}
          <Drawer
            destroyOnClose
            placement="right"
            closable={true}
            onClose={() => this.setState({
              drawer_visible: false,
              selected_promo: null,
              selected_index: null
            })}
            visible={this.state.drawer_visible}
            width="100%"
            closeIcon={<CloseOutlined style={{ fontSize: 24 }} />}
          >
            <Row justify='start' align='middle' gutter={[15, 15]}>
              <Col xs={24}>
                <PageHeader
                  title={selected_promo
                    ? "Modifica promozione"
                    : "Aggiungi promozione"}
                  description={selected_promo
                    ? `Aggiorna i dettagli della promozione: ${selected_promo.titolo}`
                    : "Aggiungi una nuova promozione."}
                />
              </Col>
            </Row>
            <Divider />
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchemaPromozione}
              onSubmit={this._submitForm}
            >
              {({ touched, errors, values, handleChange, handleSubmit, handleBlur, setFieldValue, isValid, dirty, isSubmitting }) => (
                <Form>
                  <Row justify='start' align='top' gutter={[15, 15]}>
                    <Col xs={24}>
                      <Label titolo="Dati principali" />
                    </Col>
                    <Col xs={24} sm={24} md={6}>
                      <span style={{ marginBottom: 10, color: Settings.colors.darkGrey }}>Immagine</span>
                      <div style={{ width: "100%" }}>
                        <ImageCropper
                          cropSize={{ width: 400, height: 300 }}
                          aspect={4 / 3}
                          image={
                            values.immagine ? values.immagine : null
                          }
                          onCropSuccess={blobUrl => setFieldValue("immagine", blobUrl)}
                          onChange={handleChange("immagine")}
                        />
                      </div>
                      <div className="input-error">{touched.immagine && errors.immagine}</div>
                    </Col>
                    <Col xs={24} sm={24} md={18}>
                      <span style={{ marginBottom: 10, color: Settings.colors.darkGrey }}>Titolo</span>
                      <Input
                        value={values.titolo}
                        onChange={handleChange("titolo")}
                        placeholder="Inserisci titolo promozione..."
                        onBlur={handleBlur("titolo")}
                      />
                      <div className="input-error">{touched.titolo && errors.titolo}</div>
                    </Col>
                    <Col xs={24} sm={24} md={24}>
                      <span style={{ color: Settings.colors.darkGrey }}>Descrizione (opzionale)</span>
                      <TextArea
                        value={values.descrizione}
                        onChange={handleChange("descrizione")}
                        placeholder="Inserisci una descrizione per la promozione"
                        autoSize={{ minRows: 7, maxRows: 7 }}
                        onBlur={handleBlur("descrizione")}
                        style={{ height: 180 }}
                      />
                      <div className="input-error">{touched.descrizione && errors.descrizione}</div>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <span style={{ color: Settings.colors.darkGrey }}>Data Scadenza</span>
                      <DatePicker
                        showTime
                        allowClear={false}
                        disabledDate={(date) => moment() && date < moment().endOf('day')}
                        locale={locale}
                        style={{ width: '100%' }}
                        format={'DD/MM/YYYY HH:mm'}
                        value={moment(values.data_scadenza, 'DD/MM/YYYY HH:mm')}
                        onChange={(_, dateString) => setFieldValue("data_scadenza", dateString)}
                      />
                      <div className="input-error">{touched.data_scadenza && errors.data_scadenza}</div>
                    </Col>
                  </Row>
                  <Divider />
                  <Row justify='center' align='middle'>
                    <Col span={24}>
                      <Button
                        loading={isSubmitting}
                        size='large'
                        disabled={!dirty || isSubmitting}
                        icon={selected_promo ? <EditOutlined /> : <PlusOutlined />}
                        block={true}
                        onClick={() => {
                          if (!isValid)
                            notification.error({
                              message: 'Errore',
                              description: 'Ricontrolla i dati inseriti'
                            });
                          handleSubmit();
                        }}
                        type="primary"
                      >
                        {this.state.selected_promo ? <span>Aggiorna</span> : <span>Aggiungi</span>}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Drawer >
          {/*End Drawer creazione/modifica promozione*/}
        </ Container >
        <Loading loading={!loaded} opaque />
      </>
    )
  }
  //#endregion
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(Promozioni);