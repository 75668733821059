// -------------------------------------------------

// successLogin action
// set is_logged_in to true inside global state
export function successLogin(payload) {
	return {
		type: 'SUCCESS_LOGIN',
		payload: payload
	};
}

// -------------------------------------------------

// successLogou action
// set is_logged_in to false inside global state
export function successLogout() {
	return {
		type: 'SUCCESS_LOGOUT'
	};
}

// -------------------------------------------------
