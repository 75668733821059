import {
  FormOutlined,
  PropertySafetyFilled,
  RightSquareFilled,
  SendOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Input,
  InputNumber,
  notification,
  Radio,
  Row,
  Space,
  Tooltip,
} from "antd";
import Modal from "antd/lib/modal/Modal";
import { Formik } from "formik";
import * as Yup from "yup";
import React, { Component } from "react";
import Settings from "../Config/Settings";
import { connect } from "react-redux";
import Form from "antd/lib/form/Form";
import TextArea from "antd/lib/input/TextArea";
import ListaProdottiPreventivo from "./ListaProdottiPreventivo";
import { creaPreventivo, aggiornaPreventivo } from "../Controllers/Negozio";
import { withRouter } from "react-router";

class ButtonCreaPreventivo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      negozio: null,
      modal_visible: false,
      loading: false,
      info: props.info,
    };
  }

  _openPreventivoModal = () => {
    console.log("INFO", this.props);
    this.setState({ modal_visible: true });
  };

  _closePreventivoModal = () => {
    this.setState({ modal_visible: false, negozio: null, loading: false });
  };

  _postPreventivo = async (values, { setSubmitting, resetForm }) => {
    console.log(this.props.info);
    this.setState({ loading: true });
    setSubmitting(true);
    console.log(this.state);
    console.log(this.props);
    const preventivoRecord = {
      prodotti: JSON.stringify(values.prodotti),
      tipoConsegna: values.consegna.startsWith("Consegna")
        ? "consegna"
        : "ritiro",
      //tipoPagamento: values.tipo_pagamento,
      tipoPagamento: "contante",
      idNegozio: this.props.auth.user.id_negozio,
      idCliente: this.props.info.id,
      note: values.note !== null ? values.note : "",
      creazione: Date.now(),
      ultimaModifica: Date.now(),
      costoConsegna: values.costoConsegna,
      indirizzo: values.indirizzo ?? "",
    };
    try {
      await creaPreventivo(preventivoRecord);
      setSubmitting(false);
      this.setState({ loading: true });
      this._closePreventivoModal();
      notification.destroy();
      notification.success({
        title: "Perfetto!",
        message:
          "La creazione del tuo preventivo è andata a buon fine. Ora dovrai aspettare che il cliente accetti la richiesta per proseguire con l'ordine",
      });
      this.props.history.push("/ordini");
    } catch (error) {
      console.log("Error", error);
      notification.destroy();
      notification.error({
        title: "Errore!",
        message:
          "La creazione del tuo preventivo non è andata a buon fine, contatta l'assistenza",
      });
    }
  };
  _updatePreventivo = async (values, { setSubmitting, resetForm }) => {
    console.log(this.props.orderId);
    this.setState({ loading: true });
    setSubmitting(true);
    console.log(this.state);
    console.log(this.props);
    const preventivoRecord = {
      realtimeDbKey: this.props.realtimeDbId,
      idOrdine: this.props.orderId,
      prodotti: JSON.stringify(values.prodotti),
      tipoConsegna: values.consegna.startsWith("Consegna")
        ? "consegna"
        : "ritiro",
      tipoPagamento: values.tipo_pagamento,
      idNegozio: this.props.auth.user.id_negozio,
      idCliente: this.props.info.sql_id_to,
      note: values.note !== null ? values.note : "",
      ultimaModifica: Date.now(),
      costoConsegna: values.costoConsegna,
      indirizzo: values.indirizzo ?? "",
    };
    try {
      await aggiornaPreventivo(preventivoRecord);
      setSubmitting(false);
      this.setState({ loading: true });
      this._closePreventivoModal();
      this.props.callbackUpdateComplete();
      notification.destroy();
      notification.success({
        title: "Perfetto!",
        message: "L'aggiornamento del tuo preventivo è andata a buon fine",
      });
    } catch (error) {
      console.log("Error", error);
      notification.destroy();
      notification.error({
        title: "Errore!",
        message:
          "L'aggiornamento  del tuo preventivo non è andata a buon fine, contatta l'assistenza",
      });
    }
  };
  render() {
    const { modal_visible } = this.state;
    return (
      <>
        {this.props.circular ? (
          <Tooltip
            title="Crea un preventivo"
            placement="bottomRight"
            color={Settings.colors.darkGrey}
          >
            <Button
              shape="circle"
              type="primary"
              block={this.props.block}
              icon={<FormOutlined />}
              onClick={this._openPreventivoModal}
            ></Button>
          </Tooltip>
        ) : (
          <Button
            type="primary"
            block={this.props.block}
            icon={<FormOutlined />}
            onClick={this._openPreventivoModal}
          >
            {this.props.modify ? "Modifica preventivo" : "Crea Preventivo"}
          </Button>
        )}

        <Modal
          destroyOnClose
          title="Crea preventivo"
          placement="right"
          closable={true}
          onCancel={this._closePreventivoModal}
          visible={modal_visible}
          footer={null}
          width={600}
        >
          <Formik
            validationSchema={Yup.object().shape({
              consegna: Yup.string().required(
                "Seleziona la modalità di consegna"
              ),
              // tipo_pagamento: Yup.string().required(
              //   "Seleziona la modalità di pagamento"
              // ),
              prodotti: Yup.array().required().min(1),
              note: Yup.string(),
              costoConsegna: Yup.number(),
            })}
            initialValues={{
              note: this.props.note ?? "",
              costoConsegna: this.props.costo_consegna
                ? this.props.costo_consegna
                : 0.0,
              consegna: this.props.extra
                ? this.props.extra.consegna === "ritiro"
                  ? "Ritira in negozio"
                  : "Consegna a domicilio"
                : null,
              tipo_pagamento: this.props.extra
                ? this.props.extra.tipo_pagamento === "carta"
                  ? "carta"
                  : "contante"
                : null,
              indirizzo: this.props.extra ? this.props.extra.indirizzo : null,
            }}
            validateOnMount={true}
            validateOnChange={true}
            onSubmit={
              this.props.modify ? this._updatePreventivo : this._postPreventivo
            }
          >
            {({
              touched,
              errors,
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isValid,
              isSubmitting,
              dirty,
            }) => (
              <Form>
                <Row style={{ marginBottom: 15 }}>
                  <Col span={24}>
                    <span
                      style={{
                        color: Settings.colors.darkGrey,
                      }}
                    >
                      Lista Prodotti
                    </span>
                  </Col>
                  <Col span={24}>
                    <ListaProdottiPreventivo
                      consegnaVisible={
                        values.consegna &&
                        values.consegna.startsWith("Consegna")
                      }
                      initialProdotti={this.props.prodotti}
                      initialCostoConsegna={this.props.costo_consegna}
                      updateProdotti={(prodotti) =>
                        setFieldValue("prodotti", prodotti)
                      }
                      updateCostoConsegna={(costoConsegna) =>
                        setFieldValue("costoConsegna", costoConsegna)
                      }
                    />
                  </Col>
                </Row>
                <Divider />
                <Row style={{ marginBottom: 15 }}>
                  <Col span={24}>
                    <span
                      style={{
                        color: Settings.colors.darkGrey,
                      }}
                    >
                      Modalità Ordine
                    </span>
                  </Col>
                  <Col span={24}>
                    <Radio.Group
                      disabled={this.props.precompiled}
                      value={values.consegna}
                      onChange={(e) => {
                        setFieldValue("consegna", e.target.value);
                      }}
                    >
                      {this.props.auth.user.ritiro && (
                        <Radio
                          style={styles.radioStyle}
                          value="Ritira in negozio"
                        >
                          Ritira in negozio
                        </Radio>
                      )}
                      {this.props.auth.user.consegna && (
                        <Radio
                          style={styles.radioStyle}
                          value="Consegna a domicilio"
                        >
                          Consegna a domicilio
                        </Radio>
                      )}
                    </Radio.Group>
                  </Col>
                </Row>

                {this.props.extra && this.props.extra.indirizzo !== null && (
                  <Row style={{ marginBottom: 15 }}>
                    <Col span={24}>
                      <span
                        style={{
                          color: Settings.colors.darkGrey,
                        }}
                      >
                        Indirizzo di consegna
                      </span>
                    </Col>
                    <Col span={24}>
                      <Input disabled value={values.indirizzo} />
                    </Col>
                  </Row>
                )}

                {/* <Row style={{ marginBottom: 15 }}>
                  <Col span={24}>
                    <span
                      style={{
                        color: Settings.colors.darkGrey,
                      }}
                    >
                      Modalità Pagamento
                    </span>
                  </Col>
                  <Col span={24}>
                    <Radio.Group
                      disabled={this.props.precompiled}
                      value={values.tipo_pagamento}
                      onChange={(e) => {
                        setFieldValue("tipo_pagamento", e.target.value);
                      }}
                      style={styles.smallText}
                    >
                      {this.props.auth.user.carta && (
                        <Radio style={styles.radioStyle} value="carta">
                          Carta di Credito/Bancomat
                        </Radio>
                      )}
                      {this.props.auth.user.contante && (
                        <Radio
                          style={styles.radioStyle}
                          value={values.consegna ? "contante" : ""}
                        >
                          {values.consegna
                            ? values.consegna.startsWith("Consegna")
                              ? "Alla consegna"
                              : "Al ritiro"
                            : "Alla consegna/Al ritiro"}
                        </Radio>
                      )}
                    </Radio.Group>
                    <span style={styles.inputError}>
                      {touched.tipo_pagamento && errors.tipo_pagamento}
                    </span>
                  </Col>
                </Row> */}
                <Row style={{ marginBottom: 15 }}>
                  <Col span={24}>
                    <span
                      style={{
                        color: Settings.colors.darkGrey,
                      }}
                    >
                      Note
                    </span>
                  </Col>
                  <Col span={24}>
                    <TextArea
                      value={values.note}
                      onChange={handleChange("note")}
                      placeholder="Inserisci delle note per il preventivo"
                      autoSize={{ minRows: 7, maxRows: 7 }}
                      onBlur={handleBlur("note")}
                      style={{ height: 180 }}
                    />
                  </Col>
                  <span style={styles.inputError}>
                    {touched.note && errors.note}
                  </span>
                </Row>
                <Divider />
                <Row justify="space-around">
                  <Col span={24}>
                    <Button
                      block
                      size="large"
                      icon={<SendOutlined />}
                      type="primary"
                      onClick={handleSubmit}
                      loading={this.state.loading}
                      disabled={!(isValid && dirty) || isSubmitting}
                    >
                      {isSubmitting
                        ? "Attendi..."
                        : this.props.modify
                        ? "Aggiorna"
                        : "Invia"}
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Modal>
      </>
    );
  }
}

const styles = {
  inputError: {
    color: Settings.colors.red,
    fontSize: "14px",
  },
  radioStyle: {
    display: "block",
  },
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default withRouter(connect(mapStateToProps)(ButtonCreaPreventivo));
