import * as Yup from "yup";

//Validation schema per la registrazione del negozio
export const validationRegistraNegozio = Yup.object({
  email: Yup.string()
    .email("Inserisci un'email valida")
    .required("Inserisci un indirizzo e-mail"),
  password: Yup.string()
    .required("Inserisci una password")
    .test(
      "len",
      "La password deve contenere almeno 8 caratteri",
      (val) => val && val.toString().length >= 8
    ),
  conferma_password: Yup.string()
    .required("Le password non coincidono")
    .oneOf([Yup.ref("password"), null], "Le password non coincidono"),
  nome_referente: Yup.string()
    .strict(true)
    .trim("Il campo non può avere spazi vuoti iniziali e/o finali")
    .required("Inserisci un nome")
    .max(50, "Il campo può contenere massimo 50 caratteri"),
  cognome_referente: Yup.string()
    .strict(true)
    .trim("Il campo non può avere spazi vuoti iniziali e/o finali")
    .required("Inserisci un cognome")
    .max(50, "Il campo può contenere massimo 50 caratteri"),
  telefono_referente: Yup.string()
    .strict(true)
    .trim("Il campo non può avere spazi vuoti iniziali e/o finali")
    .matches(/[1-9]\d{4,14}$/i, "Inserisci un numero di telefono valido")
    .required("Inserisci il telefono del referente del negozio"),
  partita_iva: Yup.string()
    .matches(
      /(^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$)|(^[0-9]{11}$)/i,
      "Inserisci una partita IVA valida (senza prefisso IT) o un Codice Fiscale valido"
    )
    .required("Inserisci la partita IVA o il Codice Fiscale"),
  nome_negozio: Yup.string()
    .strict(true)
    .trim("Il campo non può avere spazi vuoti iniziali e/o finali")
    .required("Inserisci un nome per il negozio")
    .max(100, "Il campo può contenere massimo 100 caratteri"),
  citta: Yup.string()
    .nullable()
    .required("Seleziona la città in cui è ubicato il negozio"),
  cap: Yup.string()
    .nullable()
    .required("Seleziona il CAP in cui è ubicato il negozio"),
  indirizzo: Yup.string()
    .strict(true)
    .trim("Il campo non può avere spazi vuoti iniziali e/o finali")
    .required("Inserisci l'indirizzo in cui è ubicato il negozio")
    .max(255, "Il campo può contenere massimo 255 caratteri"),
  civico: Yup.string()
    .strict(true)
    .trim("Il campo non può avere spazi vuoti iniziali e/o finali")
    .required("Inserisci il numero civico in cui è ubicato il negozio")
    .max(15, "Il campo può contenere massimo 15 caratteri"),
  categoria: Yup.string().nullable().required("Seleziona una categoria"),
  privacy_condizioni: Yup.bool().oneOf(
    [true],
    "Devi accettare i termini di servizio e la privacy policy"
  ),
  accordo_comm: Yup.bool().oneOf(
    [true],
    "Devi accettare l'accordo commerciale"
  ),
});

//Validation schema per l'aggiornamento dei dati referente negozio
export const validationSchemaReferente = Yup.object({
  nome_referente: Yup.string()
    .strict(true)
    .trim("Il campo non può avere spazi vuoti iniziali e/o finali")
    .required("Inserisci un nome")
    .max(50, "Il campo può contenere massimo 50 caratteri"),
  cognome_referente: Yup.string()
    .strict(true)
    .trim("Il campo non può avere spazi vuoti iniziali e/o finali")
    .required("Inserisci un cognome")
    .max(50, "Il campo può contenere massimo 50 caratteri"),
  telefono_referente: Yup.string()
    .strict(true)
    .trim("Il campo non può avere spazi vuoti iniziali e/o finali")
    .matches(/[1-9]\d{4,14}$/i, "Inserisci un numero di telefono valido")
    .required("Inserisci il telefono del referente del negozio"),

  marketing: Yup.bool(),
  profilazione: Yup.bool(),
});

//Validation schema per l'aggiornamento del negozio
export const validationAggiornaNegozio = Yup.object({
  nome_negozio: Yup.string()
    .strict(true)
    .trim("Il campo non può avere spazi vuoti iniziali e/o finali")
    .required("Inserisci un nome per il negozio")
    .max(100, "Il campo può contenere massimo 100 caratteri"),
  partita_iva: Yup.string()
    .matches(
      /(^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$)|(^[0-9]{11}$)/i,
      "Inserisci una partita IVA valida (senza prefisso IT) o un Codice Fiscale valido"
    )
    .required("Inserisci la partita IVA o il Codice Fiscale"),
  categoria: Yup.string().nullable().required("Seleziona una categoria"),
  descrizione: Yup.string().max(
    1500,
    "Il campo può contenere massimo 1500 caratteri"
  ),
  orari_apertura: Yup.string().max(
    1500,
    "Il campo può contenere massimo 1500 caratteri"
  ),
  bancomat: Yup.bool(),
  carta: Yup.bool(),
  contante: Yup.bool(),
  altri_metodi: Yup.string()
    .strict(true)
    .trim("Il campo non può avere spazi vuoti iniziali e/o finali")
    .max(100, "Il campo può contenere massimo 100 caratteri"),
  telefono: Yup.string()
    .strict(true)
    .trim("Il campo non può avere spazi vuoti iniziali e/o finali")
    .matches(/[1-9]\d{1,14}$/i, "Inserisci un numero di telefono valido"),
  whatsapp: Yup.string()
    .strict(true)
    .trim("Il campo non può avere spazi vuoti iniziali e/o finali")
    .matches(/[1-9]\d{1,14}$/i, "Inserisci un numero di telefono valido"),
  email: Yup.string().email("Inserisci un'email valida"),
  facebook: Yup.string()
    .matches(
      /^(https:\/\/)(?:www\.)?(mbasic.facebook|m\.facebook|facebook|fb)\.(com|me)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-\.]*\/)*([\w\-\.]*)$/,
      "Inserisci un link corretto"
    )
    .max(100, "Il campo può contenere massimo 100 caratteri"),

  instagram: Yup.string().matches(
    /^(https:\/\/)(?:www\.)?instagram\.com\/.+$/,
    "Inserisci un link corretto"
  ),
  sito_web: Yup.string().matches(
    /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)$/,
    "Inserisci un link corretto"
  ),
  citta: Yup.string()
    .nullable()
    .required("Seleziona la città in cui è ubicato il negozio"),
  cap: Yup.string()
    .nullable()
    .required("Seleziona il CAP in cui è ubicato il negozio"),
  indirizzo: Yup.string()
    .strict(true)
    .trim("Il campo non può avere spazi vuoti iniziali e/o finali")
    .required("Inserisci l'indirizzo in cui è ubicato il negozio")
    .max(255, "Il campo può contenere massimo 255 caratteri"),
  civico: Yup.string()
    .strict(true)
    .trim("Il campo non può avere spazi vuoti iniziali e/o finali")
    .required("Inserisci il numero civico in cui è ubicato il negozio")
    .max(15, "Il campo può contenere massimo 15 caratteri"),
  tags: Yup.array().max(20, "Puoi aggiungere fino a 20 tags"),
  marketing: Yup.boolean(),
  profilazione: Yup.boolean(),
});

//Validation schema per l'aggiornamento/creazione di un prodotto
export const validationSchemaProdotto = Yup.object({
  nome: Yup.string()
    .strict(true)
    .trim("Nome con spazi vuoti iniziali/finali")
    .required("Nome mancante")
    .max(100, "Nome > 100 caratteri"),
  descrizione: Yup.string().max(1500, "Descrizione > 1500 caratteri"),
  categoria: Yup.string()
    .nullable()
    .strict(true)
    .trim("Categoria con spazi vuoti iniziali/finali")
    .max(50, "Categoria > 50 caratteri"),
  immagine: Yup.string().nullable(),
  prezzo: Yup.number()
    .min(
      Yup.ref("prezzo_scontato"),
      "Il prezzo deve essere maggiore o uguale a 0"
    )
    .max(99999, "Il prezzo deve essere minore o uguale a 99999")

    .nullable()
    .required("Prezzo mancante"),
  prezzo_scontato: Yup.number()
    .nullable()
    .min(0, "Il Prezzo scontato deve essere maggiore o uguale a 0")
    .lessThan(
      Yup.ref("prezzo"),
      "Il Prezzo scontato deve essere minore del Prezzo"
    ),
});
//Validation schema per aggiunta appuntamento
export const validationSchemaAppuntamento = Yup.object({
  titolo: Yup.string()
    .strict(true)
    .trim("Il campo non può avere spazi vuoti iniziali e/o finali")
    .required("Inserisci un titolo per l'appuntamento")
    .max(100, "Il campo può contenere massimo 100 caratteri"),
  descrizione: Yup.string().max(1500, "Descrizione > 1500 caratteri"),
  categoria: Yup.string().nullable().required("Inserisci una categoria."),
  start: Yup.string().required("Inserisci una data di inizio appuntamento."),
  end: Yup.string().required("Inserisci una data di fine appuntamento."),
});
//Validation schema per l'aggiornamento/creazione di un servizio
export const validationSchemaServizio = Yup.object({
  nome: Yup.string()
    .strict(true)
    .trim("Nome con spazi vuoti iniziali/finali")
    .required("Nome mancante")
    .max(100, "Nome > 100 caratteri"),
  descrizione: Yup.string().max(1500, "Descrizione > 1500 caratteri"),
  categoria: Yup.string()
    .nullable()
    .strict(true)
    .trim("Categoria con spazi vuoti iniziali/finali")
    .max(50, "Categoria > 50 caratteri"),
  immagine: Yup.string().nullable(),
  prezzo: Yup.number()
    .min(0, "Il prezzo deve essere maggiore o uguale a 0")
    .max(99999, "Il prezzo deve essere minore o uguale a 99999")
    .nullable()
    .required("Prezzo mancante"),
  prezzo_scontato: Yup.number()
    .nullable()
    .min(0, "Il Prezzo scontato deve essere maggiore o uguale a 0")
    .lessThan(
      Yup.ref("prezzo"),
      "Il Prezzo scontato deve essere minore del Prezzo"
    ),
});

//Validation schema per la modifica della password
export const validationSchemaAggiornaPassword = Yup.object().shape({
  password: Yup.string()
    .required("Inserisci una password")
    .test(
      "len",
      "La password deve contenere almeno 8 caratteri",
      (val) => val && val.toString().length >= 8
    ),
  conferma_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "Le password non coincidono")
    .required("Ripeti la password"),
});

export const validationSchemaPromozione = Yup.object().shape({
  titolo: Yup.string()
    .strict(true)
    .trim("Titolo con spazi vuoti iniziali/finali")
    .required("Titolo mancante")
    .max(100, "Titolo > 100 caratteri"),
  descrizione: Yup.string().max(1500, "Descrizione > 1500 caratteri"),
  immagine: Yup.string().nullable().required("Immagine mancante"),
  data_scadenza: Yup.string().required("Data scadenza mancante"),
});

export const validationModuloOrdini = Yup.object().shape({
  modulo_ordini: Yup.boolean(),
  ritiro: Yup.boolean(),
  consegna: Yup.boolean(),
  dettagli_consegna: Yup.string()
    .nullable()
    .strict(true)
    .trim("Dettagli consegna con spazi vuoti iniziali/finali")
    .max(1500, "Dettagli consegna > 1500 caratteri"),
});

export const validationModuloChat = Yup.object().shape({
  chat: Yup.boolean(),
});

export const validationModuloAgenda = Yup.object().shape({
  modulo_appuntamenti: Yup.boolean(),
  prenotazioni: Yup.boolean(),
});
