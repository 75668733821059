import React from "react";
import firebase from "firebase";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

//Screens
import Homepage from "./Screens/Homepage";
import ScegliComune from "./Screens/ScegliComune";
import Dashboard from "./Screens/Dashboard";
import Login from "./Screens/Login";
import RecuperaPassword from "./Screens/RecuperaPassword";
import Account from "./Screens/Account";
import ConfermaMail from "./Screens/ConfermaMail";
import RegistraNegozio from "./Screens/RegistraNegozio";
import IlMioNegozio from "./Screens/IlMioNegozio";
import Prodotti from "./Screens/Prodotti";
import Servizi from "./Screens/Servizi";
import Chat from "./Screens/Chat";
import Promozioni from "./Screens/Promozioni";
import TerminiCondizioni from "./Screens/TerminiCondizioni";
import NonTrovata from "./Screens/NonTrovata";
import FunzioneNonAbilitata from "./Screens/FunzioneNonAbilitata";

import "./App.less";
import "./App.css";
import { Row, Col, Button, Alert, DatePicker } from "antd";

// Components
import PrivateRoute from "./Components/PrivateRoute";

// import Auth controller functions
import { checkAuthState, getClaims } from "./Controllers/Auth";

// import redux components
import { connect } from "react-redux";

// import actions
import { successLogin, successLogout } from "./Actions/AuthActions";
import { setChats } from "./Actions/ChatActions";
import { setOrders } from "./Actions/OrdiniActions";

import Settings from "./Config/Settings";
import NonAbilitato from "./Screens/NonAbilitato";
import {
  getDatiNegozio,
  getUserChatsRef,
  getUserOrdersRef,
} from "./Controllers/Negozio";
import { CustomerServiceOutlined } from "@ant-design/icons";
import Chats from "./Screens/Chats";
import Orders from "./Screens/Orders";
import Order from "./Screens/Order";
import Agenda from "./Screens/Agenda";
import { getNomeComuneById } from "./Controllers/Comune";
import Moduli from "./Screens/Moduli";
import Resi from "./Screens/Resi";
import Loading from "./Components/Loading";
import Condizioni from "./Screens/Condizioni";
import Accordo from "./Screens/Accordo";
import CookiePolicy from "./Screens/CookiePolicy";

const OneSignal = window.OneSignal || [];
OneSignal.push(function () {
  OneSignal.init({
    appId: process.env.REACT_APP_ONESIGNAL_NEGOZIO_APP_ID,
    safari_web_id: process.env.REACT_APP_ONESIGNAL_NEGOZIO_SAFARI_ID,
  });
});
if (OneSignal.installServiceWorker) {
  OneSignal.installServiceWorker();
} else {
  if (navigator.serviceWorker) {
    navigator.serviceWorker.register(
      `/OneSignalSDKWorker.js?appId=${process.env.REACT_APP_ONESIGNAL_NEGOZIO_APP_ID}`
    );
  }
}
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authed: false,
      loading: true,
      not_verified: false,
      abilitato: false,
      privacy: false,
      accordo_comm: false,
      chats: [],
      chatNonAbilitata: true,
    };
    if (process.env.REACT_APP_STAGE == "development") {
      var noIndex = document.createElement("meta");
      noIndex.setAttribute("name", "robots");
      noIndex.content = "noindex";
      document.getElementsByTagName("head")[0].appendChild(noIndex);
    }
  }

  _getToReadNumber = (collection) => {
    var num = 0;
    for (const key in collection) {
      const item = collection[key];
      if (item.to_read === true) {
        num++;
      }
    }
    return num;
  };

  _setChats = (chats) => {
    let chatsArray = [];
    for (const key in chats) {
      if (Object.hasOwnProperty.call(chats, key)) {
        chatsArray.push({ chat_uid: key, ...chats[key] });
      }
    }
    chatsArray.sort((a, b) =>
      a.last_update < b.last_update ? 1 : b.last_update < a.last_update ? -1 : 0
    );
    return chatsArray;
  };

  _setOrders = (orders) => {
    let ordersArray = [];
    for (const key in orders) {
      if (Object.hasOwnProperty.call(orders, key)) {
        ordersArray.push({ order_uid: key, ...orders[key] });
      }
    }
    ordersArray.sort((a, b) =>
      a.last_update < b.last_update ? 1 : b.last_update < a.last_update ? -1 : 0
    );
    return ordersArray;
  };

  _getClaims = async () => {
    try {
      let { claims } = await getClaims();
      console.log(claims)
      if (claims.negozio) {
        let { data } = await getDatiNegozio(claims.id_negozio);
        this.setState({
          abilitato: data.abilitato,
          chatNonAbilitata: !data.chat,
          ordiniNonAbilitata: !data.modulo_ordini,
          privacy: data.privacy_condizioni,
          accordo_comm: data.accordo_comm,
        });

        const logged_user = {
          is_logged_in: true,
          email: claims.email,
          email_verified: claims.email_verified,
          id_negozio: claims.id_negozio,
          ordini_abilitati: data.modulo_ordini,
          dettagli_consegna: data.dettagli_consegna,
          carta: data.carta,
          contante: data.contante,
          ritiro: data.ritiro,
          consegna: data.consegna,
        };

        //Check if user's email is verified
        if (!logged_user.email_verified) {
          this.setState({ authed: true, loading: false, not_verified: true });
        } else {
          this.setState({ authed: true, loading: false, not_verified: false });
        }

        //Save user in global state
        this.props.successLogin(logged_user);
      } else {
        // change local state
        this.setState({ authed: false, loading: false });
        // change global state
        this.props.successLogout();
      }
    } catch (error) {
      console.log("errore", error);
    }
  };

  componentDidMount() {
    //Listener native prompt
    const self = this;
    OneSignal.push(function () {
      OneSignal.on("notificationPermissionChange", function (permissionChange) {
        var currentPermission = permissionChange.to;
        if (self.state.authed && self.props.auth && self.props.auth.user) {
          try {
            const user = firebase.auth().currentUser;
            OneSignal.sendTags(
              {
                uid: user.uid,
                id_negozio: self.props.auth.user.id_negozio
                  ? self.props.auth.user.id_negozio
                  : "",
                nome_comune: self.props.auth.user.nome_comune,
              },
              function (tagsSent) {
                console.log("Tag spediti", tagsSent);
              }
            );
          } catch (error) {
            console.log("ERROR", error);
          }
        }
        window.location.reload();
      });
    });

    //Se loggato da persistor
    if (this.state.authed && this.props.auth && this.props.auth.user) {
      try {
        const user = firebase.auth().currentUser;
        OneSignal.push(function () {
          OneSignal.sendTags(
            {
              uid: user.uid,
              id_negozio: self.props.auth.user.id_negozio
                ? self.props.auth.user.id_negozio
                : "",
              nome_comune: self.props.auth.user.nome_comune,
            },
            function (tagsSent) {
              console.log("Tag spediti", tagsSent);
            }
          );
        });
      } catch (error) {
        console.log("ERROR", error);
      }
    }

    this.authListener = checkAuthState((user) => {
      if (user) {
        this._getClaims();
        const chatsRealtimeRef = getUserChatsRef(
          firebase.auth().currentUser.uid
        );
        try {
          OneSignal.push(function () {
            OneSignal.sendTags(
              {
                uid: firebase.auth().currentUser.uid,
                id_negozio: self.props.auth.user
                  ? self.props.auth.user.id_negozio
                  : "",
                nome_comune: self.props.auth.user
                  ? self.props.auth.user.nome_comune
                  : "",
              },
              function (tagsSent) {
                console.log("Tag spediti", tagsSent);
              }
            );
          });
        } catch (error) {
          console.log(error);
        }

        chatsRealtimeRef.on("value", (snapshot) => {
          const chats = snapshot.val();
          const toReadNumber = this._getToReadNumber(chats);
          this.props.setChats({
            chats: this._setChats(chats),
            toReadNumber: toReadNumber,
          });
        });
        const ordersRealtimeRef = getUserOrdersRef(
          firebase.auth().currentUser.uid
        );
        ordersRealtimeRef.on("value", (snapshot) => {
          const orders = snapshot.val();
          const toReadNumber = this._getToReadNumber(orders);
          this.props.setOrders({
            orders: this._setOrders(orders),
            toReadNumber: toReadNumber,
          });
        });
      } else {
        console.log("Non loggato");
        // change local state
        this.setState({ authed: false, loading: false });
        // change global state
        this.props.successLogout();
      }
    });
  }

  componentWillUnmount() {
    this.authListener();
  }

  render() {
    return this.state.loading === true ? (
      <Loading loading opaque />
    ) : (
      <Router>
        <div>
          {process.env.REACT_APP_STAGE == "development" ? (
            <div
              style={{
                width: "150px",
                display: "inline-block",
                transform: "rotate(45deg)",
                background: "yellow",
                top: 30,
                right: -30,
                zIndex: 5000000,
                position: "fixed",
                textAlign: "center",
                fontSize: "10pt",
              }}
            >
              {process.env.REACT_APP_STAGE.toUpperCase().substring(0, 3)}
            </div>
          ) : null}

          <Switch>
            <Route exact path="/" component={Homepage} />
            <Route path="/accedi" component={Login} />
            <Route path="/recupera-password" component={RecuperaPassword} />
            <Route exact path="/scegli-comune" component={ScegliComune} />

            <Route
              path="/registra-negozio/:comune"
              component={RegistraNegozio}
            />
            {/*<Route path="/cookie-policy" component={CookiePolicy} />*/}
            {/*<Route path="/termini-e-condizioni" />*/}
            {/* <Route path="/accordo-commerciale" component={Accordo} /> */}

            <Route path="/non-abilitato" component={NonAbilitato} />
            <PrivateRoute
              path="/conferma-mail"
              authed={this.state.authed}
              component={ConfermaMail}
            />
            <PrivateRoute
              path="/dashboard"
              abilitato={this.state.abilitato}
              privacy={this.state.privacy}
              accordo_comm={this.state.accordo_comm}
              authed={this.state.authed}
              notVerified={this.state.not_verified}
              component={Dashboard}
            />
            <PrivateRoute
              path="/account"
              abilitato={this.state.abilitato}
              privacy={this.state.privacy}
              accordo_comm={this.state.accordo_comm}
              authed={this.state.authed}
              notVerified={this.state.not_verified}
              component={Account}
            />
            <PrivateRoute
              path="/il-mio-negozio"
              authed={this.state.authed}
              privacy={this.state.privacy}
              accordo_comm={this.state.accordo_comm}
              notVerified={this.state.not_verified}
              abilitato={this.state.abilitato}
              component={IlMioNegozio}
            />
            <PrivateRoute
              path="/moduli"
              authed={this.state.authed}
              privacy={this.state.privacy}
              accordo_comm={this.state.accordo_comm}
              notVerified={this.state.not_verified}
              abilitato={this.state.abilitato}
              component={Moduli}
            />
            <PrivateRoute
              path="/resi"
              authed={this.state.authed}
              privacy={this.state.privacy}
              accordo_comm={this.state.accordo_comm}
              notVerified={this.state.not_verified}
              abilitato={this.state.abilitato}
              component={Resi}
            />
            <PrivateRoute
              path="/prodotti"
              abilitato={this.state.abilitato}
              authed={this.state.authed}
              privacy={this.state.privacy}
              accordo_comm={this.state.accordo_comm}
              notVerified={this.state.not_verified}
              component={Prodotti}
            />
            <PrivateRoute
              path="/servizi"
              abilitato={this.state.abilitato}
              authed={this.state.authed}
              privacy={this.state.privacy}
              accordo_comm={this.state.accordo_comm}
              notVerified={this.state.not_verified}
              component={Servizi}
            />
            <PrivateRoute
              path="/promozioni"
              abilitato={this.state.abilitato}
              authed={this.state.authed}
              privacy={this.state.privacy}
              accordo_comm={this.state.accordo_comm}
              notVerified={this.state.not_verified}
              component={Promozioni}
            />

            <PrivateRoute
              exact
              path="/chats"
              abilitato={this.state.abilitato}
              authed={this.state.authed}
              privacy={this.state.privacy}
              accordo_comm={this.state.accordo_comm}
              notVerified={this.state.not_verified}
              funzioneNonAbilitata={this.state.chatNonAbilitata}
              component={Chats}
            />

            <PrivateRoute
              exact
              path="/agenda"
              abilitato={this.state.abilitato}
              authed={this.state.authed}
              privacy={this.state.privacy}
              accordo_comm={this.state.accordo_comm}
              notVerified={this.state.not_verified}
              // funzioneNonAbilitata={this.state.agendaNonAbilitata}
              component={Agenda}
            />

            <PrivateRoute
              exact
              path="/chats/:chat_uid"
              abilitato={this.state.abilitato}
              authed={this.state.authed}
              privacy={this.state.privacy}
              accordo_comm={this.state.accordo_comm}
              notVerified={this.state.not_verified}
              funzioneNonAbilitata={this.state.chatNonAbilitata}
              component={Chat}
            />

            <PrivateRoute
              exact
              path="/ordini"
              abilitato={this.state.abilitato}
              authed={this.state.authed}
              privacy={this.state.privacy}
              accordo_comm={this.state.accordo_comm}
              notVerified={this.state.not_verified}
              funzioneNonAbilitata={this.state.ordiniNonAbilitata}
              component={Orders}
            />

            <PrivateRoute
              exact
              path="/ordini/:order_uid"
              abilitato={this.state.abilitato}
              authed={this.state.authed}
              privacy={this.state.privacy}
              accordo_comm={this.state.accordo_comm}
              notVerified={this.state.not_verified}
              funzioneNonAbilitata={this.state.ordiniNonAbilitata}
              component={Order}
            />

            <Route
              path="/funzione-non-abilitata"
              component={FunzioneNonAbilitata}
            />
            <Route path="/condizioni"
              component={Condizioni}
              privacy={this.state.privacy}
              accordo_comm={this.state.accordo_comm}
            />
            {/* 404 */}
            <Route component={NonTrovata} />
            <Route path="/404" component={NonTrovata} />
          </Switch>
        </div>
      </Router>
    );
  }
}

// This function provides a means of sending actions so that data in the Redux store
function mapDispatchToProps(dispatch) {
  return {
    successLogin: (user) => dispatch(successLogin(user)),
    successLogout: () => dispatch(successLogout()),
    setChats: (chats) => dispatch(setChats(chats)),
    setOrders: (orders) => dispatch(setOrders(orders)),
  };
}

function mapStateToProps(state) {
  return { auth: state.auth };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
