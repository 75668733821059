import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  ConfigProvider,
  Divider,
  Empty,
  Input,
  InputNumber,
  List,
  Row,
  Typography,
} from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import Form from "antd/lib/form/Form";
import { Formik } from "formik";
import Settings from "../Config/Settings";
import * as Yup from "yup";

const ListaProdottiPreventivo = ({
  updateProdotti,
  updateCostoConsegna,
  initialProdotti,
  initialCostoConsegna,
  consegnaVisible
}) => {

  const [prodotti, setProdotti] = useState(
    initialProdotti
      ? initialProdotti
      : []
  );

  const [costoConsegna, setCostoConsegna] = useState(
    initialCostoConsegna
      ? initialCostoConsegna
      : 0
  );

  useEffect(() => { updateProdotti(prodotti) }, [prodotti]);

  useEffect(() => { updateCostoConsegna(costoConsegna) }, [costoConsegna])

  const initialValues = {
    quantita: 1,
    nome: "",
    totale: 0.01,
  };

  const _addProdotto = async (values, { setSubmitting, resetForm, setFieldValue }) => {
    const prodottoRecord = {
      nome: values.nome,
      tipo: "prodotto",
      totale: values.totale,
      quantita: values.quantita,
    };
    setProdotti([...prodotti, prodottoRecord]);
    resetForm();
  };
  return (
    <>
      <ConfigProvider
        renderEmpty={() => (
          <Empty
            description={
              <span>
                Nessun prodotto, aggiungine uno inserendolo nella sezione
                apposita
              </span>
            }
          />
        )}
      >
        <List
          style={{ marginBottom: 10 }}
          footer={
            prodotti.length > 0 && (
              <>
                {consegnaVisible ? (
                  <Row style={{ marginBottom: 15 }}>
                    <Col span={24}>
                      <Row justify="space-between" align="middle">
                        <span>Costo Consegna</span>
                        <Row align="middle">
                          <InputNumber
                            style={{ marginRight: "5px" }}
                            min={0}
                            max={99999}
                            step={0.5}
                            defaultValue={0.0}
                            decimalSeparator=","
                            precision={2}
                            value={costoConsegna}
                            onChange={(value) => setCostoConsegna(value)}
                          />{" "}
                          €
                        </Row>
                      </Row>
                    </Col>
                  </Row>
                ) : null}

                <Row justify="space-between">
                  <Col>Totale</Col>
                  <Col>
                    <Row>
                      {((prodotti
                        .map((p) => p.totale)
                        .reduce((a, b) => a + b))
                        + costoConsegna
                      ).toFixed(2)
                        .toString()
                        .replace(".", ",")
                      } €
                  </Row>
                  </Col>
                </Row>
              </>
            )
          }
          bordered
          dataSource={prodotti}
          renderItem={(item, index) => (
            <List.Item
              actions={[
                <DeleteOutlined
                  onClick={() =>
                    setProdotti([...prodotti.filter((item, i) => i !== index)])
                  }
                />,
              ]}
            >
              <Row gutter={10} style={{ width: "100%" }}>
                <Col span={4}>{item.quantita}x</Col>
                <Col span={12}>
                  <Typography.Text
                    style={{ width: "100%" }}
                    ellipsis={{ tooltip: item.nome }}
                  >
                    {item.nome}
                  </Typography.Text>
                </Col>
                <Col span={8}>
                  <Row justify="end">{item.totale.toFixed(2).toString().replace(".", ",")} €</Row>
                </Col>
              </Row>
            </List.Item>
          )}
        />
      </ConfigProvider>

      <Row>
        <Col span={24}>
          <span style={{
            color: Settings.colors.darkGrey,
            marginBottom: 10,
            display: "inline-block"
          }}>
            Aggiungi Prodotto
            </span>
        </Col>
        <Col span={24}>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              quantita: Yup.number()
                .min(1, "La quantità deve essere maggiore di 0")
                .max(999, "La quantità deve essere inferiore di 999")
                .nullable()
                .required("Quantità mancante"),
              nome: Yup.string()
                .strict(true)
                .trim("Nome con spazi vuoti iniziali/finali")
                .required("Nome mancante")
                .max(100, "Nome > 100 caratteri"),
              totale: Yup.number()
                .min(0, "Il prezzo deve essere maggiore o uguale a 0")
                .max(99999, "Il prezzo deve essere minore o uguale a 99999")
                .nullable()
                .required("Prezzo mancante"),
            })}
            onSubmit={_addProdotto}
            validateOnMount={false}
            validateOnChange={true}
          >
            {({
              touched,
              errors,
              values,
              handleChange,
              handleSubmit,
              handleBlur,
              setFieldValue,
              isValid,
              dirty,
              isSubmitting,
            }) => (
              <Row justify="space-between" gutter={15}>
                <Col flex={1}>
                  <Row gutter={5}>
                    <Col xs={24} md={4}>
                      <Row>
                        <span style={styles.span}>Quantità</span>
                      </Row>
                      <Row>
                        <InputNumber
                          style={{ marginRight: "5px" }}
                          min={1}
                          step={1}
                          defaultValue={1}
                          value={values.quantita}
                          onChange={(value) => setFieldValue("quantita", value)}
                        />
                      </Row>
                      <span style={styles.inputError}>
                        {touched.quantita && errors.quantita}
                      </span>
                    </Col>
                    <Col flex={1} xs={24} md={16}>
                      <Row>
                        <span style={styles.span}>Nome</span>
                      </Row>
                      <Row>
                        <Input
                          value={values.nome}
                          onChange={handleChange("nome")}
                          placeholder="Prodotto"
                          onBlur={handleBlur("nome")}
                        />
                      </Row>
                      <span style={styles.inputError}>
                        {touched.nome && errors.nome}
                      </span>
                    </Col>
                    <Col xs={24} md={6}>
                      <Row>
                        <span style={styles.span}>Totale</span>
                      </Row>
                      <Row align="middle">
                        <InputNumber
                          min={0}
                          max={99999}
                          step={0.01}
                          defaultValue={0.01}
                          decimalSeparator=","
                          precision={2}
                          value={values.totale}
                          onChange={(value) => setFieldValue("totale", value)}
                          onBlur={handleBlur("totale")}
                          style={{ marginRight: "2px" }}
                        />
                        €
                      </Row>
                      <span style={styles.inputError}>
                        {touched.totale && errors.totale}
                      </span>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} md={2}>
                  <Row>
                    <span style={styles.span}>&nbsp;</span>
                  </Row>
                  <Button
                    disabled={!(isValid && dirty) || isSubmitting}
                    onClick={handleSubmit}
                    type="primary"
                    icon={<PlusOutlined />}
                  />
                </Col>
              </Row>
            )}
          </Formik>
        </Col>
      </Row>
    </>
  );
};

const styles = {
  span: {
    fontSize: 14,
    color: Settings.colors.darkGrey,
  },
  inputError: {
    color: Settings.colors.red,
    fontSize: "14px",
  },
};

export default ListaProdottiPreventivo;
