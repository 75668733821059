import { Alert } from 'antd';
import React from 'react';
import Settings from '../Config/Settings';

const getStatusText = (status) => {
  switch (status.toLowerCase()) {
    case 'preventivo':
      return "Il cliente deve accettare o rifiutare il preventivo che gli è stato proposto."
    case 'in attesa di conferma':
      return "Per proseguire con l'evasione dell'ordine è necessario confermarlo mediante l'apposito pulsante in fondo alla pagina"
    case 'in attesa di pagamento':
      return "Per procedere con l'evasione dell'ordine devi aspettare che l'utente paghi.\nDopo che l'utente avrà pagato, potrai procedere con l'evasione dell'ordine."
    case 'in lavorazione':
      return "Puoi gestire la consegna/pronto per il ritiro dal menù a tendina nella barra superiore dell'ordine."
    case 'in consegna':
      return "L'ordine risulta in consegna, procedi con la consegna e dal menù a tendina seleziona \"consegnato\" per terminare l'evasione."
    case 'consegnato':
      return "L'ordine risulta stato consegnato. Non è necessario proseguire con ulteriori azioni. Il pagamento dell'ordine è visibile della tua dashboard Stripe, ricorda che i bonifici verso il tuo conto vengono effettuati una volta al mese."
    default:
      return ""
  }
}

const InfoOrderStatus = ({ status }) => {
  return (
    <Alert
      message={<span style={{ color: Settings.colors.darkGrey }}><b>{`${status.charAt(0).toUpperCase()}${status.substr(1)}`}</b></span>}
      description={
        <span
          style={{ marginBottom: 0, whiteSpace: 'pre-wrap' }}
        >
          {getStatusText(status)}
        </span>
      }
      type="info"
      showIcon={true}
      style={{
        zIndex: 1,
        width: "100%",
        borderRadius: 5,
      }}
    />
  );
}

export default InfoOrderStatus;