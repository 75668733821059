import React from 'react';

// Third-party components
import { Button, Col, notification, Row } from 'antd';

// Custom components
import CustomResult from '../Components/CustomResult'
import Timer from '../Components/Timer'

// Libraries
import { resendEmailVerification, logout } from '../Controllers/Auth';
import Settings from '../Config/Settings';
import { MailOutlined } from '@ant-design/icons';

class ConfermaMail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sent: false,
      run_timer_resend_email: true
    };
  }

  render() {

    return (
      <div className='login-background'>
        <Row justify="center" align="middle">
          <Col>
            <img src={require("../Assets/Immagini/logo.png")} style={styles.logoStyle} alt="logo" />
          </Col>
        </Row>
        <Row justify='center' align='middle' gutter={[15, 15]}>
          <Col span={24}>
            <CustomResult
              icon='info'
              title='Conferma il tuo indirizzo'
              subtitle="Devi confermare il tuo indirizzo E-Mail per accedere a quest'area. Controlla la tua casella di posta."
            />
          </Col>
        </Row>

        <Row justify='center' align='middle' gutter={[15, 15]}>
          {
            this.state.run_timer_resend_email === true
              ?
              <Col span={24} style={{ textAlign: "center" }}>
                <span style={{ color: Settings.colors.white }}>
                  Attendi
							    <Timer
                    seconds={60}
                    isRunning={this.state.run_timer_resend_email}
                    onTimerEnds={() => { this.setState({ run_timer_resend_email: false }) }}
                  /> 	secondi prima di richiedere una nuova email
						    </span>
              </Col>
              :
              <Col xs={24} sm={18} md={12}>
                <Button
                  type="primary"
                  size='large'
                  block
                  icon={<MailOutlined />}
                  onClick={async () => {
                    this.setState({ run_timer_resend_email: true })
                    try {
                      let email = await resendEmailVerification();
                      let user = await logout();
                      // mostro il risultato
                      notification.destroy();
                      notification.success({
                        message: "PERFETTO!",
                        description: "Email inviata al tuo indirizzo di posta. Controlla anche la casella spam e conferma il tuo account cliccando sul link che trovi all'interno della email",
                      });
                      this.setState({ sent: true });
                    } catch (error) {
                      notification.error({
                        message: 'Errore',
                        description: "Si è verificato un errore durante l'invio dell'email, prova di nuovo"
                      });
                    }
                  }}>
                  Invia email di conferma
						    </Button>
                <Button
                  type="link"
                  size='large'
                  block
                  style={{ marginTop: '30px' }}
                  onClick={() => this.props.history.push("/accedi")}>
                  Accedi
					    	</Button>
              </Col>
          }
        </Row>
      </div>
    );
  }
}

const styles = {
  loginBackground: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: `url(${require('../Assets/Immagini/background.png')})`,
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    padding: '15px'
  },
  logoStyle: {
    height: 100,
    marginTop: 35,
    marginBottom: 35
  },
  title: {
    color: Settings.colors.white,
    textAlign: 'center'
  },
  forgotPassword: {
    textAlign: 'center',
    color: "var(--white)"
  },
  alert: {
    marginBottom: "15px",
    alignSelf: "center"
  }
}

export default ConfermaMail;
