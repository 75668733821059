import { Auth } from "../Config/Fire";
import { Functions } from "../Config/Fire";

export async function login(email, password) {
  return await Auth().signInWithEmailAndPassword(email, password);
}

export function checkAuthState(callback) {
  return Auth().onAuthStateChanged(callback);
}

export async function getClaims() {
  return await Auth().currentUser.getIdTokenResult();
}

export async function requestResetPassword(email) {
  return await Auth().sendPasswordResetEmail(email);
}

export async function updateUserEmail(email) {
  return await Auth().currentUser.updateEmail(email);
}

export async function updateUserPassword(password) {
  return await Auth().currentUser.updatePassword(password);
}

export async function updateUserMailchimp(nuova_mail) {
  const updateEmailNegozio = Functions.httpsCallable(
    "negozio-updateUserMailchimp"
  );
  return await updateEmailNegozio({
    telefono: Auth().currentUser.phoneNumber,
    nuova_mail: nuova_mail,
  });
}

export async function resendEmailVerification() {
  return await Auth().currentUser.sendEmailVerification();
}

export async function logout() {
  return await Auth().signOut();
}

export async function applyActionCode(code) {
  return await Auth().applyActionCode(code);
}

export async function checkCode(code) {
  let result = null;
  await Auth()
    .verifyPasswordResetCode(code)
    .then((data) => {
      result = data;
    })
    .catch((error) => {
      result = error;
    });
  return result;
}

export async function resetPassword(code, password) {
  let result = null;
  await Auth()
    .confirmPasswordReset(code, password)
    .then(() => {
      result = "success";
    })
    .catch((error) => {
      result = error;
    });
  return result;
}
