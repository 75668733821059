import React from "react";

// import Auth controller functions
import { login, getClaims, logout } from "../Controllers/Auth";

// import ant design
import { Row, Col, Input, Button, Alert, Typography, Form } from "antd";
import CustomResult from "../Components/CustomResult";
import Settings from "../Config/Settings";
import { HomeOutlined, LoginOutlined, RightOutlined } from "@ant-design/icons";
import { getDatiNegozio } from "../Controllers/Negozio";
import Loading from "../Components/Loading";
import firebase from "firebase";
import { connect } from "react-redux";
import { getNomeComuneById } from "../Controllers/Comune";
import { successLogin, successLogout } from "../Actions/AuthActions";
import { setChats } from "../Actions/ChatActions";
import { setOrders } from "../Actions/OrdiniActions";
const { Title } = Typography;
const OneSignal = window.OneSignal || [];
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      error_message: "",
      successful_login: false,
      wrong_permissions: false,
      not_abilitato: false,
      loading: false,
    };
  }

  _initOnesignal = async () => {
    try {
      const user = firebase.auth().currentUser;
      const self = this;
      if (self.props.auth && self.props.auth.user)
        OneSignal.push(function () {
          OneSignal.sendTags(
            {
              uid: user.uid,
              id_negozio: self.props.auth.user.id_negozio,
              nome_comune: self.props.auth.user.nome_comune,
            },
            function (tagsSent) {
              console.log("Tag spediti", tagsSent);
            }
          );
        });
    } catch (error) {
      console.error("OneSignal Error:", error);
    }
  };

  // Updates form values into the component's state
  _handleChange = (e) => {
    this.setState({ error_message: "" });
    this.setState({ [e.target.id]: e.target.value });
  };

  _getClaims = async () => {
    try {
      let { claims } = await getClaims();
      if (claims.negozio) {
        //Se è un negoziante
        let { data } = await getDatiNegozio(claims.id_negozio);
        if (data.abilitato) {
          const logged_user = {
            is_logged_in: true,
            email: claims.email,
            email_verified: claims.email_verified,
            id_negozio: claims.id_negozio,
            ordini_abilitati: data.modulo_ordini,
            dettagli_consegna: data.dettagli_consegna,
            carta: data.carta,
            contante: data.contante,
            ritiro: data.ritiro,
            consegna: data.consegna
          };

          this.setState({
            error_message: "",
            successful_login: true,
          });
          this.props.successLogin(logged_user);
        } else {
          this.setState({
            error_message: "",
            not_abilitato: true,
          });
        }
      } else {
        //Se non è un negoziante
        this.setState({
          error_message: "",
          wrong_permissions: true,
        });
      }
    } catch (error) {
      console.log("errore", error);
    }
  };

  // Fires the login action when form is submitted
  _doLogin = async (e) => {
    this.setState({ loading: true });
    e.preventDefault();
    try {
      await login(this.state.email, this.state.password);
      await this._getClaims();
    } catch (error) {
      this.setState({ error_message: "Credenziali errate! Riprova." });
    }
    this._initOnesignal();
    this.setState({ loading: false });
  };

  _destinationAfterSuccessLogin = () => {
    // check if redirect to
    let destination = "/dashboard";
    // if (this.props.location.state) {
    //   destination = this.props.location.state.from.pathname;
    // }
    this.props.history.push(destination);
  };

  render() {
    const {
      successful_login,
      wrong_permissions,
      error_message,
      not_abilitato,
      email,
      password,
    } = this.state;

    return (
      <div style={styles.loginBackground}>
        <Row justify="center" align="middle">
          <Col>
            <img
              src={require("../Assets/Immagini/logo.png")}
              style={styles.logoStyle}
              alt="logo"
            />
          </Col>
        </Row>
        <Row justify="center" align="middle" gutter={[15, 15]}>
          {successful_login ? (
            <CustomResult
              icon="success"
              title="Bentornato!"
              subtitle="Ti sei autenticato con successo"
              button={{
                title: "Prosegui",
                route: () => this._destinationAfterSuccessLogin(),
                icon: <RightOutlined />,
              }}
            />
          ) : wrong_permissions ? (
            <CustomResult
              icon="info"
              title="Accesso negato"
              subtitle="Sembra che tu non sia un negoziante."
              button={{
                title: "Torna alla home",
                route: () => this.props.history.push("/"),
                icon: <HomeOutlined />,
              }}
            />
          ) : not_abilitato ? (
            <CustomResult
              icon="info"
              title="Accesso negato"
              subtitle="Il tuo negozio non è abilitato, contattare il proprio comune per l'abilitazione"
              button={{
                title: "Torna alla home",
                route: () => this.props.history.push("/"),
                icon: <HomeOutlined />,
              }}
            />
          ) : (
            <>
              <Col span={24}>
                <Title level={4} style={styles.title}>
                  Accedi al pannello di controllo del tuo negozio
                </Title>
              </Col>
              {error_message !== "" && (
                <Col span={24}>
                  <Row justify="center" align="middle" gutter={[15, 15]}>
                    <Col span={12}>
                      <Alert
                        message={error_message}
                        type="error"
                        style={styles.alert}
                      />
                    </Col>
                  </Row>
                </Col>
              )}
              <Col xs={24} sm={18} md={12}>
                <Form onSubmitCapture={async (e) => await this._doLogin(e)}>
                  <Row justify="center" align="middle" gutter={[15, 15]}>
                    <Col span={24}>
                      <Input
                        placeholder="Email"
                        id="email"
                        value={email}
                        onChange={(e) => this._handleChange(e)}
                      />
                    </Col>
                    <Col span={24}>
                      <Input.Password
                        placeholder="Password"
                        id="password"
                        value={password}
                        onChange={(e) => this._handleChange(e)}
                      />
                    </Col>
                    <Col span={24}>
                      <Button
                        block
                        size="large"
                        type="primary"
                        htmlType="submit"
                        icon={<LoginOutlined />}
                      >
                        Accedi
                      </Button>
                    </Col>
                  </Row>
                  <Row
                    justify="center"
                    align="middle"
                    gutter={[15, 15]}
                    style={styles.forgotPassword}
                  >
                    <Col span={24}>
                      <span style={{ color: Settings.colors.white }}>
                        Non ti ricordi la password? <br /> Nessun problema, ti
                        aiutiamo noi.{" "}
                      </span>
                      <a
                        onClick={() =>
                          this.props.history.push("/recupera-password")
                        }
                      >
                        Clicca qui
                      </a>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </>
          )}
        </Row>
        <Loading loading={this.state.loading} opaque />
      </div>
    );
  }
}

const styles = {
  loginBackground: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: `url(${require("../Assets/Immagini/background.png")})`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    padding: "15px",
  },
  logoStyle: {
    height: 100,
    marginTop: 35,
    marginBottom: 35,
  },
  title: {
    color: Settings.colors.white,
    textAlign: "center",
  },
  forgotPassword: {
    textAlign: "center",
    color: "var(--white)",
  },
  alert: {
    marginBottom: "15px",
    alignSelf: "center",
  },
};

// This function provides a means of sending actions so that data in the Redux store
function mapDispatchToProps(dispatch) {
  return {
    successLogin: (user) => dispatch(successLogin(user)),
    successLogout: () => dispatch(successLogout()),
    setChats: (chats) => dispatch(setChats(chats)),
    setOrders: (orders) => dispatch(setOrders(orders)),
  };
}

function mapStateToProps(state) {
  return { auth: state.auth };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
