import React from "react";

// import ant design
import { Spin } from "antd";

class Timer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      timer: this.props.seconds,
      isOn: false,
    };
  }

  componentDidMount() {
    if (this.props.isRunning === true) {
      this._startTimer();
    } else {
      this._stopTimer();
    }
  }

  _startTimer = () => {
    this.timer = setInterval(() => {
      if (this.state.timer > 0) {
        this.setState({
          isOn: true,
          timer: this.state.timer - 1,
        });
      } else {
        this._stopTimer();
        this.props.onTimerEnds();
      }
    }, 1000);
  };

  _stopTimer = () => {
    this.setState({
      isOn: false,
      timer: this.props.seconds,
    });
    clearInterval(this.timer);
  };

  componentWillUnmount() {
    this._stopTimer();
  }

  render() {
    return <b style={{ margin: "0 10px" }}>{this.state.timer}</b>;
  }
}

export default Timer;
