import { Alert } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Settings from '../Config/Settings';
import { getStripeDetails } from '../Controllers/Negozio';

const StripeResAlert = ({ res }) => {
  const { id_negozio } = useSelector((state) => state.auth.user);

  const [details, setDetails] = useState(null)
  useEffect(() => getAlertDetails(res), [])

  const getAlertDetails = async () => {
    const success_return = {
      type: "success",
      description: "Collegamento account andato a buon fine. Ora puoi abilitare i pagamenti online mediante l'apposito pulsante in fondo alla pagina."
    }
    const error_return = {
      type: "error",
      description: "Collegamento account non andato a buon fine"
    }
    const fill_return = {
      type: "warning",
      description: "Per completare l'abilitazione ai pagamenti online, inserisci i dati mancanti dalla dashboard di Stripe"
    }
    switch (res) {
      case 'return':
        try {
          const { data } = await getStripeDetails(id_negozio);
          if (data.onboarded)
            setDetails(success_return)
          else
            setDetails(fill_return);
        } catch (error) {
          setDetails(error_return);
        }
        return;
      case 'refresh':
        setDetails(error_return);
        return;
    }
  }

  return details ? (
    <Alert
      message={<span style={{ color: Settings.colors.darkGrey }}><b>Esito collegamento Stripe</b></span>}
      description={
        <span
          style={{ marginBottom: 0, whiteSpace: 'pre-wrap' }}
        >
          {details.description}
        </span>
      }
      type={details.type}
      showIcon={true}
      style={{
        zIndex: 1,
        width: "100%",
        borderRadius: 5,
      }}
    />
  ) : <></>;
}

export default StripeResAlert;