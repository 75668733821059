import { Functions } from "../Config/Fire";
import firebase from "firebase";

//Registra un nuovo negozio
export async function registraNegozio(data) {
  //Chiamo cloud function per registrare utenza e negozio
  const registra = Functions.httpsCallable("negozio-registraNegozio");
  return await registra(data);
}

// Ritorna il tipo di utente
export async function userType(email) {
  // let type = 'negozio'
  const type = Functions.httpsCallable("auth-getTipoUtente");
  return await type(email);
}

//Aggiorna i dati del referente del negozio
export async function getDatiNegozio(id) {
  //Chiamo cloud function per registrare utenza e negozio
  const negozio = Functions.httpsCallable("negozio-getDatiNegozio");
  return await negozio(id);
}

//Aggiorna i dati del referente del negozio
export async function aggiornaDatiNegozio(id_negozio, dati_negozio) {
  //Chiamo cloud function per registrare utenza e negozio
  const negozio = Functions.httpsCallable("negozio-aggiornaDatiNegozio");
  return await negozio({
    id: id_negozio,
    ...dati_negozio,
    img_to_delete: dati_negozio.img_to_delete,
  });
}

//Aggiorna i flag del negozio
export async function aggiornaFlagNegozio(id, flags) {
  //Chiamo cloud function per registrare utenza e negozio
  const flag = Functions.httpsCallable("negozio-aggiornaFlagNegozio");
  return await flag({ id: id, ...flags });
}

//Aggiorna i dati del referente del negozio
export async function aggiornaDatiReferente(id, dati_referente) {
  //Chiamo cloud function per registrare utenza e negozio
  const referente = Functions.httpsCallable("negozio-aggiornaDatiReferente");
  return await referente({ id: id, ...dati_referente });
}

export async function getDatiReferente(id_negozio) {
  const referente = Functions.httpsCallable("negozio-getDatiReferente");
  return await referente(id_negozio);
}

/*
 * 	PRODOTTI
 */

//Ottieni il prodotto dato l'id
export async function getProdotto(id) {
  //Chiamo cloud function per fare la get
  const prodotto = Functions.httpsCallable("negozio-getProdotto");
  return await prodotto(id);
}

export async function getProdottiNegozio(id) {
  const prodotti = Functions.httpsCallable("negozio-getProdotti");
  return await prodotti(id);
}

export async function getCategorieProdotti(id) {
  const categorie = Functions.httpsCallable("negozio-getCategorieProdotti");
  return await categorie(id);
}

export async function setCategorieProdotti(id_negozio, categorie) {
  const setCategorie = Functions.httpsCallable("negozio-setCategorieProdotti");
  return await setCategorie({ id_negozio, categorie });
}

export async function setCategoriaProdotti(id, old_categoria, new_categoria) {
  const setCategoria = Functions.httpsCallable("negozio-setCategoriaProdotti");
  return await setCategoria({
    id_negozio: id,
    old_categoria: old_categoria,
    new_categoria: new_categoria,
  });
}

export async function eliminaProdotto(id, id_negozio) {
  const prodotto_eliminato = Functions.httpsCallable("negozio-eliminaProdotto");
  return await prodotto_eliminato({ id: id, id_negozio: id_negozio });
}

export async function creaProdotto(values) {
  const prodotto = Functions.httpsCallable("negozio-creaProdotto");
  return await prodotto(values);
}

export async function aggiornaProdotto(id_prodotto, values) {
  const prodotto = Functions.httpsCallable("negozio-aggiornaProdotto");
  return await prodotto({ id: id_prodotto, ...values });
}

/*
 * SERVIZI
 */

//Ottieni il servizio dato l'id
export async function getServizio(id) {
  //Chiamo cloud function per fare la get
  const servizio = Functions.httpsCallable("negozio-getServizio");
  return await servizio(id);
}

//Ottieni tutti i servizi di un negozio
export async function getServiziNegozio(id_negozio) {
  //Chiamo cloud function per fare la get
  const servizi = Functions.httpsCallable("negozio-getServizi");
  return await servizi(id_negozio);
}

//Ottiene le categorie del negozio
export async function getCategorieServizi(id) {
  //Chiamo cloud function per registrare utenza e negozio
  const categorie = Functions.httpsCallable("negozio-getCategorieServizi");
  return await categorie(id);
}

export async function setCategorieServizi(id, categorie) {
  //Chiamo cloud function per registrare utenza e negozio
  const setCategorie = Functions.httpsCallable("negozio-setCategorieServizi");
  return await setCategorie({ id_negozio: id, categorie: categorie });
}

export async function setCategoriaServizi(id, old_categoria, new_categoria) {
  //Chiamo cloud function per registrare utenza e negozio
  const setCategoria = Functions.httpsCallable("negozio-setCategoriaServizi");
  return await setCategoria({
    id_negozio: id,
    old_categoria: old_categoria,
    new_categoria: new_categoria,
  });
}

//Aggiorna i dati del referente del negozio
export async function eliminaServizio(id_servizio, id_negozio) {
  //Chiamo cloud function per registrare utenza e negozio
  const servizio_eliminato = Functions.httpsCallable("negozio-eliminaServizio");
  return await servizio_eliminato({ id: id_servizio, id_negozio: id_negozio });
}

//Aggiorna i dati del referente del negozio
export async function creaServizio(values) {
  //Chiamo cloud function per registrare utenza e negozio
  const servizio = Functions.httpsCallable("negozio-creaServizio");
  return await servizio(values);
}

//Aggiorna i dati del referente del negozio
export async function aggiornaServizio(id_servizio, values) {
  //Chiamo cloud function per registrare utenza e negozio
  const servizio = Functions.httpsCallable("negozio-aggiornaServizio");
  return await servizio({ id: id_servizio, ...values });
}

export function getUserChatsRef(uid_utente) {
  return firebase.database().ref("users/" + uid_utente + "/chats");
}

export function getUserOrdersRef(uid_utente) {
  return firebase.database().ref("users/" + uid_utente + "/orders");
}

export async function getMessages(chat_uid) {
  const getMessages = Functions.httpsCallable("negozio-getMessages");
  return await getMessages(chat_uid);
}

export async function getUtenteFirebase(uid) {
  const getUtenteFirebase = Functions.httpsCallable(
    "negozio-getUtenteFirebase"
  );
  return await getUtenteFirebase(uid);
}

export async function getUtenteSql(uid) {
  const getUtenteSql = Functions.httpsCallable("negozio-getUtenteSql");
  return await getUtenteSql(uid);
}

export async function postMessage({
  chat_uid,
  id_utente,
  messaggio,
  id_negozio,
}) {
  const postMessage = Functions.httpsCallable("negozio-postMessage");
  return await postMessage({ chat_uid, id_utente, messaggio, id_negozio });
}

export async function setChatEnabled(enabled, id_negozio) {
  const setChatEnabled = Functions.httpsCallable("negozio-setChatEnabled");
  return await setChatEnabled({ chat: enabled, id: id_negozio });
}

export async function setOrdiniEnabled(enabled, id_negozio) {
  const setOrdiniEnabled = Functions.httpsCallable("negozio-setOrdiniEnabled");
  return await setOrdiniEnabled({ modulo_ordini: enabled, id: id_negozio });
}
export async function setAgendaEnabled(enabled, id_negozio) {
  const setAgendaEnabled = Functions.httpsCallable("negozio-setAgendaEnabled");
  return await setAgendaEnabled({
    modulo_prenotazioni: enabled,
    id: id_negozio,
  });
}
export async function getOrder({ firebaseOrderId, uid }) {
  const getOrder = Functions.httpsCallable("negozio-getOrder");
  return await getOrder({ firebaseOrderId, uid });
}
export async function creaPreventivo(data) {
  const creaPreventivo = Functions.httpsCallable("negozio-creaPreventivo");
  return await creaPreventivo(data);
}
export async function aggiornaPreventivo(data) {
  const aggiornaPreventivo = Functions.httpsCallable(
    "negozio-aggiornaPreventivo"
  );
  return await aggiornaPreventivo(data);
}
export async function changeOrderStatus({
  id,
  status,
  from,
  to,
  motivo_annullamento,
  costo_consegna,
}) {
  const changeOrderStatus = Functions.httpsCallable(
    "negozio-changeOrderStatus"
  );
  return await changeOrderStatus({
    id,
    status,
    from,
    to,
    motivo_annullamento,
    costo_consegna,
  });
}

export async function aggiornaModuloOrdini(id_negozio, values) {
  const modulo_ordini = Functions.httpsCallable("negozio-aggiornaModuloOrdini");
  return await modulo_ordini({
    id: id_negozio,
    ...values,
  });
}

export async function aggiornaModuloChat(id_negozio, values) {
  const modulo_chat = Functions.httpsCallable("negozio-aggiornaModuloChat");
  return await modulo_chat({
    id: id_negozio,
    ...values,
  });
}

export async function aggiornaModuloAgenda(id_negozio, values) {
  const modulo_agenda = Functions.httpsCallable("negozio-aggiornaModuloAgenda");
  return await modulo_agenda({
    id: id_negozio,
    ...values,
  });
}

export async function getStripeDetails(id_negozio) {
  const details = Functions.httpsCallable("negozio-getStripeDetails");
  return await details({ id: id_negozio });
}

export async function getStripeOnboardLink(id_negozio, values) {
  const link = Functions.httpsCallable("negozio-getStripeOnboardLink");
  return await link({
    id: id_negozio,
    ...values,
  });
}

export async function getStripeLoginLink(id_negozio) {
  const link = Functions.httpsCallable("negozio-getStripeLoginLink");
  return await link({ id: id_negozio });
}

export async function updatePagamentiOffline(id_negozio, pagamenti_offline) {
  const pagamenti = Functions.httpsCallable("negozio-updatePagamentiOffline");
  return await pagamenti({
    id: id_negozio,
    pagamenti_offline: pagamenti_offline,
  });
}

export async function updatePagamentiOnline(id_negozio, pagamenti_online) {
  const pagamenti = Functions.httpsCallable("negozio-updatePagamentiOnline");
  return await pagamenti({
    id: id_negozio,
    pagamenti_online: pagamenti_online,
  });
}
export async function getResiNegozio(id_negozio) {
  const getResiNegozio = Functions.httpsCallable("negozio-getResiNegozio");
  return await getResiNegozio({
    id_negozio: id_negozio,
  });
}
