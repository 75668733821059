import React, { Component } from "react";
import { connect } from "react-redux";
import ChatList from "../Components/ChatList";
import LoadingBlock from "../Components/LoadingBlock";
import Settings from "../Config/Settings";
import firebase from "firebase";
import Container from "../Components/Container";
import placeholder from "../Assets/Immagini/placeholder.jpg";

import {
  getCategorieAppuntamenti,
  postAppuntamento,
  eliminaCategoriaAppuntamenti,
} from "../Controllers/Appuntamenti";

import { Link } from "react-router-dom";
//import drawer appuntamento
import locale from "antd/es/date-picker/locale/it_IT";
//commento
import PageHeader from "../Components/PageHeader";

//color picker
import { TwitterPicker } from "react-color";
import moment from "moment";
import "moment/locale/it";
//-----------------------
import {
  Col,
  Divider,
  Image,
  DatePicker,
  Input,
  List,
  notification,
  Row,
  Typography,
  Tooltip,
  Modal,
  Button,
  Drawer,
  Select,
} from "antd";
import TextArea from "antd/lib/input/TextArea";

import {
  InfoCircleFilled,
  LoadingOutlined,
  PlusSquareFilled,
  SendOutlined,
  LeftOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  PlusOutlined,
  FormOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import { Formik } from "formik";
import Form from "antd/lib/form/Form";

import { getListaComuni } from "../Controllers/Comune";
import { getDatiNegozio } from "../Controllers/Negozio";
import {
  getMessages,
  getUtenteFirebase,
  getUtenteSql,
  postMessage,
} from "../Controllers/Negozio";
import Label from "../Components/Label";
import { validationSchemaAppuntamento } from "../Config/Validation";
import ButtonCreaPreventivo from "../Components/ButtonCreaPreventivo";

const { Option } = Select;
const { confirm } = Modal;
class Chats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingChat: true,
      loadingMessages: true,
      visible_menu: false,
      chats: this.props.chats,
      chatInfo: null,
      messages: [],
      categorie_negozio: [],
      matches: window.matchMedia("(min-width: 600px)").matches,
      pickerVisible: false,
      message: "",
      first: true,

      sending: false,
      infoPressed: window.innerWidth < 992 ? false : true,
      nuova_categoria_color: "#c9c9c9",
    };
  }
  onNameChange = (args) => {
    const { value } = args.target;
    this.setState({ nuova_categoria: value });
  };
  _closeMenu = () => {
    this.setState({ visible_menu: false });
  };

  _openMenu = () => {
    this.setState({ visible_menu: true });
  };

  _sendMessage = async () => {
    if (this.state.message) {
      this.setState({ sending: true });
      const msg = this.state.message;
      this.setState({ message: "" });
      let {
        match: { params },
      } = this.props;
      const { chat_uid } = params;
      const { chats } = this.props.chats;
      const chatInfo = chats.find((i) => i.chat_uid == chat_uid);
      try {
        if (!chatInfo) {
          window.location.href = "/404";
          return;
        }
        let messages = this.state.messages;
        messages.push({
          firebase_db_chat_id: chat_uid,
          message: msg,
          type: "text",
          from: this.state.currentUid,
        });
        this.setState({ messages: messages }, () =>
          this._scrollToBottomMessagesBox()
        );
        const message = postMessage({
          chat_uid: chat_uid,
          id_utente: chatInfo.user_sql_id,
          messaggio: msg,
          id_negozio: this.props.auth.user.id_negozio,
        });
      } catch (error) {
        notification.destroy();
        notification.error({
          message: "Errore!",
          description:
            "Non è stato possibile inviare il messaggio. Contattare l'assistenza.",
        });
      } finally {
        this.setState({ sending: false });
      }
    } else {
      notification.destroy();
      notification.error({
        message: "Errore!",
        description: "Inserisci il messaggio",
      });
    }
  };
  _submitForm = async (values, { setSubmitting }) => {
    setSubmitting(true);

    const { titolo, descrizione, start, end, categoria } = values;
    let categoriaObject = null;
    for (let i = 0; i < this.state.categorie_negozio.length; i++) {
      if (categoria === this.state.categorie_negozio[i].titolo) {
        categoriaObject = this.state.categorie_negozio[i];
        break;
      }
    }
    try {
      var newInizio = moment(start, "DD/MM/YYYY HH:mm").format(
        "YYYY-MM-DD HH:mm:ss"
      );
      var newEnd = moment(end, "DD/MM/YYYY HH:mm").format(
        "YYYY-MM-DD HH:mm:ss"
      );

      await postAppuntamento({
        titolo: titolo,
        descrizione: descrizione,
        inizio: newInizio,
        fine: newEnd,
        categoria: JSON.stringify(categoriaObject),
        uidCliente: this.state.uid_cliente,
      });

      notification.destroy();
      notification.success({
        title: "Perfetto!",
        message: "L'appuntamento è stato aggiunto correttamente",
      });
      this.setState({
        nuova_categoria: "",
        nuova_categoria_color: "#c9c9c9",
        drawerAppuntamentoVisible: false,
      });
    } catch (error) {
      console.log(error);
      notification.destroy();
      notification.error({
        title: "Errore.",
        message:
          "C'è stato un problema con l'inserimento, contattare l'assistenza.",
      });
    }
  };
  _getMessages = async (chat_uid) => {
    let { data } = await getMessages(chat_uid);
    data = data.map((v) => {
      v.extra = JSON.parse(v.extra);
      return v;
    });
    console.log(data);
    this.setState(
      { messages: data, loadingMessages: false, first: false },
      () => {
        this._scrollToBottomMessagesBox();
      }
    );
  };

  _getChat = async (chat_uid) => {
    const { chats } = this.props.chats;
    const chatInfo = chats.find((i) => i.chat_uid == chat_uid);
    if (!chatInfo) {
      window.location.href = "/404";
      return;
    }
    const sqlUserTo = await getUtenteSql(chatInfo.user_to_fb_uid);
    const fbUserTo = await getUtenteFirebase(chatInfo.user_to_fb_uid);
    this.setState({
      chatInfo: {
        ...sqlUserTo.data,
        ...fbUserTo.data,
        ...chatInfo,
        chatSubtitle: "Cliente",
      },
      loadingChat: false,
    });
  };

  componentDidMount() {
    let {
      match: { params },
    } = this.props;
    const { chat_uid } = params;
    this.setState({ currentUid: firebase.auth().currentUser.uid });
    this.setState({
      loadingChat: this.state.first ? true : false,
      loadingMessages: this.state.first ? true : false,
    });
    if (this.state.messages.length === 0) this._getMessages(chat_uid);
    if (this.state.chatInfo === null) this._getChat(chat_uid);

    const handler = (e) => this.setState({ matches: e.matches });
    window.matchMedia("(min-width: 600px)").addListener(handler);

    let objDiv = document.getElementById("chat");
    objDiv.scrollTop = objDiv.scrollHeight;
  }
  _getCategorie = async () => {
    const res = await getCategorieAppuntamenti();
    let cat = [];
    res.data.forEach((element) => {
      if (element.categoria) {
        cat.push({
          colore: JSON.parse(element.categoria).colore,
          titolo: JSON.parse(element.categoria).titolo,
        });
      }
    });
    this.setState({ categorie_negozio: cat });
  };
  componentDidUpdate(prevProps) {
    let {
      match: { params },
    } = this.props;
    const { chat_uid } = params;
    if (prevProps.match.params.chat_uid !== chat_uid) {
      this.setState({
        loadingChat: true,
        loadingMessages: true,
      });
    }
    if (
      prevProps.chats.toReadNumber !== this.props.chats.toReadNumber ||
      prevProps.match.params.chat_uid !== chat_uid
    ) {
      this._getMessages(chat_uid);
      this._getChat(chat_uid);
    }
  }

  _scrollToBottomMessagesBox = () => {
    let objDiv = document.getElementById("chat");
    objDiv.scrollTop = objDiv.scrollHeight;
  };

  render() {
    const { loadingChat, loadingMessages, visible_menu, messages, chatInfo } =
      this.state;
    const initialValues = {
      categoria: null,
      titolo: "",
      descrizione: "",
      start: moment().format("DD/MM/YYYY HH:mm"),
      end: moment().format("DD/MM/YYYY HH:mm"),
    };
    return (
      <>
        <Container>
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          >
            <div
              style={{
                position: "absolute",
                width: "400px",
                height: "100%",
                overflowY: "scroll",
                top: 0,
                left: 0,
                padding: "15px",
                paddingTop: "79px",
                zIndex: 9999,
                backgroundColor: Settings.colors.white,
                borderRight: "1px solid",
                borderColor: Settings.colors.lightGrey,
              }}
              className="chatPanel"
            >
              <ChatList />
            </div>
            <div
              style={{
                position: "absolute",
                left: "400px",
                top: 0,
                width: "calc(100% - 400px)",
                height: "100%",
                zIndex: 9999,
              }}
              className="chatList"
            >
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "70px",
                  top: "64px",
                  left: 0,
                  borderBottom: "1px solid",
                  zIndex: 9999,
                  backgroundColor: Settings.colors.white,
                  borderColor: Settings.colors.lightGrey,
                }}
              >
                {loadingChat ? null : (
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{
                      height: "100%",
                      paddingLeft: "15px",
                      paddingRight: "15px",
                    }}
                  >
                    <Col>
                      <Row justify="start" align="middle" gutter={15}>
                        <Col className="backToChats">
                          <Link to="/chats">
                            <LeftOutlined /> Chat
                          </Link>
                        </Col>
                        <Col>
                          {chatInfo && (
                            <img
                              height="40px"
                              width="53px"
                              style={styles.chat_image}
                              src={placeholder}
                            />
                          )}
                        </Col>
                        <Col>
                          <Row>
                            <Typography.Title
                              level={3}
                              style={styles.chat_title}
                            >
                              {chatInfo && chatInfo.chat_title}
                            </Typography.Title>
                          </Row>
                          <Row>
                            <Typography.Title
                              level={4}
                              style={styles.chat_subtitle}
                            >
                              {chatInfo && chatInfo.chatSubtitle}
                            </Typography.Title>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row gutter={[15, 0]}>
                        <Col>
                          <ButtonCreaPreventivo circular info={chatInfo} />
                        </Col>
                        <Col>
                          <Tooltip
                            title="Informazioni cliente"
                            placement="bottomRight"
                            color={Settings.colors.darkGrey}
                          >
                            <InfoCircleFilled
                              style={{
                                fontSize: 32,
                                color: this.state.infoPressed
                                  ? Settings.colors.cyan
                                  : Settings.colors.darkGrey,
                              }}
                              onClick={() =>
                                this.setState({
                                  infoPressed: !this.state.infoPressed,
                                })
                              }
                            />
                          </Tooltip>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
              </div>
              <div
                style={{
                  position: "absolute",
                  width: this.state.infoPressed ? "calc(100% - 300px)" : "100%",
                  height: "calc(100% - 203px)",
                  padding: "15px",
                  top: "133px",
                  bottom: "70px",
                  left: 0,
                  borderBottom: "1px solid",
                  overflowY: "scroll",
                  borderColor: Settings.colors.lightGrey,
                }}
                id="chat"
                className="chatList"
              >
                {loadingMessages ? (
                  <LoadingBlock loading={true} opaque />
                ) : (
                  <List
                    locale={{ emptyText: "Nessun Messaggio" }}
                    dataSource={this.state.messages}
                    renderItem={({
                      firebase_db_chat_id,
                      from,
                      type,
                      message,
                      timestamp,
                      extra,
                    }) => (
                      <List.Item
                        style={{
                          textAlign:
                            this.state.currentUid === from ? "right" : "left",
                          border: "none",
                          padding: "4px 0px",
                        }}
                      >
                        <List.Item.Meta
                          title={
                            <div
                              style={{
                                color:
                                  this.state.currentUid === from
                                    ? Settings.colors.white
                                    : Settings.colors.darkGrey,
                                padding: "5px 10px",
                                background:
                                  this.state.currentUid === from
                                    ? Settings.colors.cyan
                                    : Settings.colors.lightGrey,
                                borderRadius: "4px",
                                maxWidth: "80%",
                                display: "inline-block",
                                textAlign: "left",
                                whiteSpace: "pre-wrap",
                              }}
                            >
                              {type === "text" ? (
                                message
                              ) : type === "preventivo" ? (
                                <>
                                  <Row style={{ marginBottom: 15 }}>
                                    <Col span={24}>
                                      <Row
                                        style={{
                                          marginBottom: 15,
                                          fontSize: 18,
                                        }}
                                      >
                                        <b>RICHIESTA PREVENTIVO</b>
                                      </Row>
                                      <Row style={{ marginBottom: 30 }}>
                                        {message}
                                      </Row>
                                      <Row style={{ marginBottom: 15 }}>
                                        <b>DETTAGLI</b>
                                      </Row>
                                      {extra && extra.consegna && (
                                        <Row>
                                          <b>Modalità Ordine: </b>
                                          {extra.consegna === "ritiro"
                                            ? "Ritira in negozio"
                                            : "Consegna a domicilio"}
                                        </Row>
                                      )}
                                      {extra && extra.indirizzo && (
                                        <Row>
                                          <b>Indirizzo di consegna: </b>
                                          {extra.indirizzo}
                                        </Row>
                                      )}
                                      {extra && extra.tipo_pagamento && (
                                        <Row>
                                          <b>Tipo Pagamento: </b>
                                          {extra.tipo_pagamento === "carta"
                                            ? "Carta di credito/Bancomat"
                                            : extra.consegna === "ritiro"
                                              ? "Al ritiro"
                                              : "Alla Consegna"}
                                        </Row>
                                      )}
                                    </Col>
                                  </Row>

                                  <Row>
                                    <ButtonCreaPreventivo
                                      precompiled
                                      info={chatInfo}
                                      messaggio={message}
                                      extra={extra}
                                    />
                                  </Row>
                                </>
                              ) : (
                                message
                              )}
                            </div>
                          }
                        />
                      </List.Item>
                    )}
                  />
                )}
              </div>
              <div
                style={{
                  display: this.state.infoPressed ? "initial" : "none",
                  padding: "15px",
                  position: "absolute",
                  width: "300px",
                  height: "calc(100% - 133px)",
                  right: 0,
                  top: "133px",
                  borderLeft: "1px solid",
                  borderColor: Settings.colors.lightGrey,
                  zIndex: 9999,
                  overflowY: "scroll",
                  paddingTop: "30px",
                  textAlign: "center",
                }}
                className="chatDetails"
              >
                {loadingChat ? null : (
                  <>
                    <Row justify="center" align="middle" style={styles.section}>
                      {chatInfo && (
                        <img
                          height="100px"
                          width="133px"
                          style={{ overflow: "hidden", borderRadius: "4px" }}
                          src={placeholder}
                          className="radius"
                        />
                      )}
                    </Row>
                    <Row justify="center" align="middle" style={styles.section}>
                      <Typography.Title level={3} style={styles.rightTitle}>
                        {chatInfo && chatInfo.chat_title}
                      </Typography.Title>
                    </Row>

                    <Row style={{ marginBottom: 15 }}>
                      <Button
                        type="secondary"
                        block
                        icon={<CalendarOutlined />}
                        onClick={() => {
                          this._getCategorie();
                          this.setState({
                            drawerAppuntamentoVisible: true,
                            uid_cliente: chatInfo.id_utenza_firebase,
                          });
                        }}
                      >
                        Crea appuntamento
                      </Button>
                    </Row>

                    <Row style={{ marginBottom: 15 }}></Row>
                  </>
                )}
              </div>
              <div
                style={{
                  background: Settings.colors.white,
                  position: "absolute",
                  padding: "15px",
                  width: "100%",
                  height: "70px",
                  textAlign: "center",
                  bottom: 0,
                  paddingRight: this.state.infoPressed ? "315px" : "15px",
                }}
                className="chatList"
              >
                <Row justify="space-between" align="middle" gutter={[15, 15]}>
                  <Col flex={1}>
                    <Input
                      disabled={loadingMessages}
                      type="text"
                      placeholder="Digita un messaggio..."
                      style={{ height: "40px" }}
                      onChange={(e) =>
                        this.setState({ message: e.target.value })
                      }
                      value={this.state.message}
                      onPressEnter={
                        this.state.message === "" ||
                          this.state.message.length === 0
                          ? null
                          : this._sendMessage
                      }
                      onFocus={(e) => this._scrollToBottomMessagesBox()}
                    />
                  </Col>
                  <Col>
                    {this.state.sending ? (
                      <LoadingOutlined style={{ fontSize: "40px" }} />
                    ) : (
                      <SendOutlined
                        style={{
                          fontSize: "40px",
                          color: Settings.colors.darkGrey,
                        }}
                        onClick={
                          this.state.message === "" ||
                            this.state.message.length === 0
                            ? null
                            : this._sendMessage
                        }
                      />
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Container>
        <Drawer
          destroyOnClose
          placement="right"
          closable={true}
          onClose={async () => {
            this.setState({
              drawerAppuntamentoVisible: false,
            });
          }}
          visible={this.state.drawerAppuntamentoVisible}
          width={this.state.matches ? "500px" : "100%"}
          closeIcon={<CloseOutlined style={{ fontSSize: 24 }} />}
        >
          {this.state.drawerAppuntamentoVisible ? (
            <>
              <Row justify="start" align="middle" gutter={[15, 15]}>
                <Col xs={24}>
                  <PageHeader
                    title={"Aggiungi appuntamento"}
                    description={`Aggiungi un nuovo appuntamento con ${chatInfo ? chatInfo.chat_title : "il cliente"
                      }.`}
                  />
                </Col>
              </Row>
              <Divider />
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchemaAppuntamento}
                onSubmit={this._submitForm}
                validateOnMount={false}
                validateOnChange={true}
              >
                {({
                  touched,
                  errors,
                  values,
                  handleChange,
                  handleSubmit,
                  handleBlur,
                  setFieldValue,
                  isValid,
                  dirty,
                  isSubmitting,
                }) => (
                  <Form>
                    <Row justify="start" align="top" gutter={[15, 15]}>
                      <Col xs={24}>
                        <Label titolo="Dati appuntamento" />
                      </Col>

                      <Col span={24}>
                        <span
                          style={{
                            marginBottom: 10,
                            color: Settings.colors.darkGrey,
                          }}
                        >
                          Categoria Appuntamento
                        </span>
                        <Select
                          onChange={(value) => {
                            setFieldValue("categoria", value);
                          }}
                          style={{ width: "100%" }}
                          value={values.categoria}
                          optionLabelProp="label"
                          onBlur={handleBlur("categoria")}
                          placeholder="Inserisci una categoria"
                          dropdownRender={(menu) => (
                            <div>
                              {menu}
                              <Divider style={{ margin: "10px 0" }} />
                              <Row
                                justify="space-between"
                                align="middle"
                                style={{ padding: "0 5px" }}
                              >
                                <Col span={16} style={{ marginBottom: "5px" }}>
                                  <Input
                                    placeholder="Inserisci nome nuova categoria..."
                                    style={{ flex: "auto" }}
                                    value={this.state.nuova_categoria}
                                    onChange={this.onNameChange}
                                  />
                                </Col>
                                <Col span={7}>
                                  <Input
                                    style={{
                                      marginBottom: "5px",
                                      backgroundColor:
                                        this.state.nuova_categoria_color,
                                    }}
                                    value={this.state.nuova_categoria_color}
                                    onClick={() => {
                                      this.setState({ pickerVisible: true });
                                    }}
                                  ></Input>
                                </Col>
                                <Row align="middle" justify="end">
                                  <Col span={12}>
                                    {this.state.pickerVisible ? (
                                      <TwitterPicker
                                        onChangeComplete={(color) => {
                                          this.setState({
                                            nuova_categoria_color: color.hex,
                                            pickerVisible: false,
                                          });
                                        }}
                                        triangle="top-right"
                                      />
                                    ) : null}
                                  </Col>
                                </Row>
                                <Col span={24}>
                                  <Button
                                    style={{ width: "100%" }}
                                    type="primary"
                                    disabled={
                                      this.state.isAdding ||
                                      this.state.nuova_categoria === ""
                                    }
                                    icon={<PlusOutlined />}
                                    onClick={
                                      this.state.nuova_categoria
                                        ? () => {
                                          let categorie =
                                            this.state.categorie_negozio;
                                          let nuovaCat = {
                                            titolo:
                                              this.state.nuova_categoria,
                                            colore:
                                              this.state
                                                .nuova_categoria_color,
                                          };
                                          categorie.push(nuovaCat);
                                          this.setState({
                                            categorie_negozio: categorie,
                                            categoriaSelezionata: nuovaCat,
                                            nuova_categoria: "",
                                          });
                                        }
                                        : null
                                    }
                                  >
                                    Aggiungi nuova
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          )}
                        >
                          <Option value={null} label="Nessuna Categoria">
                            -Nessuna Categoria-
                          </Option>
                          {this.state.categorie_negozio
                            ? this.state.categorie_negozio.map((item) => (
                              <Option
                                key={item.titolo}
                                value={item.titolo}
                                label={item.titolo}
                              >
                                <Row justify="space-between" align="middle">
                                  <Col span={16}>{item.titolo}</Col>
                                  <Col>
                                    <div
                                      style={{
                                        width: "50px",
                                        height: "20px",
                                        backgroundColor: item.colore,
                                        borderRadius: "14px",
                                      }}
                                    >
                                      {" "}
                                    </div>
                                  </Col>

                                  <Col>
                                    <DeleteOutlined
                                      style={{ color: Settings.colors.red }}
                                      onClick={() =>
                                        Modal.confirm({
                                          title: `Stai eliminando la categoria "${item.titolo}"`,
                                          icon: <ExclamationCircleOutlined />,
                                          content: `Sei sicuro di voler eliminare la categoria ${item.titolo}? Facendolo verranno eliminate le categorie degli appuntamenti che prima ne erano provvisti.`,
                                          okText: "Conferma",
                                          cancelText: "Annulla",
                                          onOk: async () => {
                                            const {
                                              categorie_negozio,
                                              appuntamenti,
                                            } = this.state;
                                            let categorie =
                                              categorie_negozio.filter(
                                                (i) => i !== item
                                              );
                                            let appuntamenti_edit = [
                                              ...appuntamenti,
                                            ];
                                            try {
                                              await eliminaCategoriaAppuntamenti(
                                                JSON.stringify(item)
                                              );
                                            } catch (err) {
                                              notification.destroy();
                                              notification.error({
                                                message: "Errore!",
                                                description:
                                                  "Non è stato possibile completare la richiesta",
                                              });
                                            }
                                            appuntamenti_edit
                                              .filter(
                                                (i) => i.categoria === item
                                              )
                                              .map((i) => (i.categoria = ""));
                                            setFieldValue("categoria", null);
                                            this.setState({
                                              categorie_negozio: categorie,
                                              appuntamenti: appuntamenti_edit,
                                            });
                                          },
                                        })
                                      }
                                    />
                                  </Col>
                                </Row>
                              </Option>
                            ))
                            : ""}
                        </Select>
                        <div className="input-error">
                          {touched.categoria && errors.categoria}
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={24}>
                        <span
                          style={{
                            marginBottom: 10,
                            color: Settings.colors.darkGrey,
                          }}
                        >
                          Titolo
                        </span>
                        <Input
                          value={values.titolo}
                          onChange={handleChange("titolo")}
                          placeholder="Inserisci titolo appuntamento..."
                          onBlur={handleBlur("titolo")}
                        />
                        <div className="input-error">
                          {touched.titolo && errors.titolo}
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={24}>
                        <span style={{ color: Settings.colors.darkGrey }}>
                          Descrizione (opzionale)
                        </span>
                        <TextArea
                          value={values.descrizione}
                          onChange={handleChange("descrizione")}
                          placeholder="Inserisci una descrizione per l'appuntamento"
                          autoSize={{ minRows: 7, maxRows: 7 }}
                          onBlur={handleBlur("descrizione")}
                          style={{ height: 180 }}
                        />
                        <div className="input-error">
                          {touched.descrizione && errors.descrizione}
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={12}>
                        <span style={{ color: Settings.colors.darkGrey }}>
                          Inizio
                        </span>
                        <DatePicker
                          showTime
                          allowClear={false}
                          disabledDate={(date) =>
                            moment() && date < moment().endOf("day")
                          }
                          locale={locale}
                          style={{ width: "100%" }}
                          format={"DD/MM/YYYY HH:mm"}
                          value={moment(values.start, "DD/MM/YYYY HH:mm")}
                          onChange={(_, dateString) => {
                            setFieldValue("start", dateString);
                            if (
                              moment(
                                values.start,
                                "DD/MM/YYYY HH:mm"
                              ).isSameOrAfter(
                                moment(values.end, "DD/MM/YYYY HH:mm")
                              )
                            ) {
                              setFieldValue("end", dateString);
                            }
                          }}
                        />
                        <div className="input-error">
                          {touched.start && errors.start}
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={12}>
                        <span style={{ color: Settings.colors.darkGrey }}>
                          Fine
                        </span>
                        <DatePicker
                          showTime
                          allowClear={false}
                          disabledDate={(date) =>
                            date < moment(values.start, "DD/MM/YYYY HH:mm")
                          }
                          locale={locale}
                          style={{ width: "100%" }}
                          format={"DD/MM/YYYY HH:mm"}
                          value={moment(values.end, "DD/MM/YYYY HH:mm")}
                          onChange={(_, dateString) =>
                            setFieldValue("end", dateString)
                          }
                        />
                        <div className="input-error">
                          {touched.end && errors.end}
                        </div>
                      </Col>
                    </Row>
                    <Col span={24}>
                      <span style={{ color: Settings.colors.darkGrey }}>
                        Collega un utente.
                      </span>
                      <Select
                        disabled={true}
                        style={{ width: "100%" }}
                        value={chatInfo.chat_title}
                        optionLabelProp="label"
                        placeholder="Seleziona un utente"
                      >
                        <Option value={null} label={chatInfo.chat_title}>
                          {chatInfo.chat_title}
                        </Option>
                      </Select>
                    </Col>
                    <Divider />
                    <Row justify="center" align="middle">
                      <Col span={24}>
                        <Button
                          loading={isSubmitting}
                          size="large"
                          disabled={!(isValid && dirty) || isSubmitting}
                          icon={<PlusOutlined />}
                          block={true}
                          size="large"
                          onClick={() => {
                            if (!isValid)
                              notification.error({
                                message: "Errore",
                                description: "Ricontrolla i dati inseriti",
                              });
                            handleSubmit();
                          }}
                          type="primary"
                        >
                          {this.state.selected_appuntamento ? (
                            <span>Aggiorna</span>
                          ) : (
                            <span>Aggiungi</span>
                          )}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </>
          ) : null}
        </Drawer>
      </>
    );
  }
}

const styles = {
  chat_image: {
    height: "40px",
    borderRadius: "4px",
    overflow: "hidden",
    marginTop: "7.5px",
  },
  chat_title: {
    fontSize: 17,
    fontWeight: 700,
    color: Settings.colors.darkGrey,
    margin: 0,
  },
  chat_subtitle: {
    fontSize: 14,
    fontWeight: 500,
    color: Settings.colors.grey,
    margin: 0,
  },
  rightTitle: {
    fontSize: 22,
    fontWeight: 700,
    color: Settings.colors.darkGrey,
    margin: 0,
  },
  rightSubtitle: {
    fontSize: 14,
    fontWeight: 500,
    color: Settings.colors.grey,
  },
  section: {
    marginBottom: "10px",
  },
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    chats: state.chat,
  };
}

export default connect(mapStateToProps, null)(Chats);
