import React from "react";
import { Badge, Col, Divider, Menu, Row, Tag, Typography } from "antd";
import {
  CalendarOutlined,
  CustomerServiceOutlined,
  DashboardOutlined,
  FlagOutlined,
  LogoutOutlined,
  MessageOutlined,
  ShopOutlined,
  ShoppingCartOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Link, withRouter } from "react-router-dom";
import Settings from "../Config/Settings";
import { connect } from "react-redux";
import { logout } from "../Controllers/Auth";

const { SubMenu } = Menu;

class SidebarMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      voices: this.props.voices,
      navigation: this.props.navigation,
      selectedKey: ["1"],
    };
  }

  _getSelectedKey = () => {
    let path = this.props.location.pathname.toLowerCase();
    let dict = {
      "/dashboard": "dashboard",
      "/il-mio-negozio": "scheda",
      "/prodotti": "prodotti",
      "/servizi": "servizi",
      "/account": "account",
      "/promozioni": "promozioni",
      "/chats": "chats",
      "/ordini": "orders",
      "/agenda": "agenda",
      "/moduli": "moduli",
      "/resi": "resi",
    };
    return dict[path];
  };

  handleClick = (e) => {
    this.props.history.push(e.item.props.route);
  };

  render() {
    let selectedKeys = [];
    selectedKeys.push(this._getSelectedKey());

    return (
      <div style={styles.mainCointainer}>
        <Row style={styles.fixedHeader} align="middle" justify="start">
          <img
            style={{ height: "35px" }}
            src={require("../Assets/Immagini/logo-inline.png")}
          />
        </Row>
        <Row align="middle" justify="start" style={styles.menuTitle}>
          MENU'
        </Row>
        <Menu
          onClick={this.handleClick}
          style={styles.menuContent}
          defaultSelectedKeys={selectedKeys}
          defaultOpenKeys={["sub1", "sub2"]}
          mode="inline"
        >
          <Menu.Item
            key="dashboard"
            icon={<DashboardOutlined />}
            route="/dashboard"
          >
            Dashboard
          </Menu.Item>
          <SubMenu key="sub1" icon={<ShopOutlined />} title="Gestisci negozio">
            <Menu.Item key="scheda" route="/il-mio-negozio">
              Scheda negozio
            </Menu.Item>
            <Menu.Item key="moduli" route="/moduli">
              Funzionalità
            </Menu.Item>
            <Menu.Item key="prodotti" route="/prodotti">
              Catalogo prodotti
            </Menu.Item>
            <Menu.Item key="servizi" route="/servizi">
              Catalogo servizi
            </Menu.Item>
            <Menu.Item key="promozioni" route="/promozioni">
              Promozioni
            </Menu.Item>
          </SubMenu>
          <Menu.Item key="account" icon={<UserOutlined />} route="/account">
            Account
          </Menu.Item>
          <Menu.Item
            key="chats"
            icon={
              this.props.chats.toReadNumber === 0 ? (
                <MessageOutlined />
              ) : (
                <Badge count={this.props.chats.toReadNumber}>
                  <MessageOutlined />
                </Badge>
              )
            }
            route="/chats"
          >
            Chat
          </Menu.Item>
          <SubMenu
            key="sub2"
            icon={
              this.props.ordini.toReadNumber === 0 ? (
                <ShoppingCartOutlined />
              ) : (
                <Badge count={this.props.ordini.toReadNumber}>
                  <ShoppingCartOutlined />
                </Badge>
              )
            }
            title="Gestisci ordini"
          >
            <Menu.Item
              icon={
                this.props.ordini.toReadNumber === 0 ? (
                  <ShoppingCartOutlined />
                ) : (
                  <Badge count={this.props.ordini.toReadNumber}>
                    <ShoppingCartOutlined />
                  </Badge>
                )
              }
              key="orders"
              route="/ordini"
            >
              Ordini
            </Menu.Item>
            <Menu.Item key="resi" icon={<FlagOutlined />} route="/resi">
              Resi
            </Menu.Item>
          </SubMenu>

          <Menu.Item key="agenda" icon={<CalendarOutlined />} route="/agenda">
            Agenda <Tag color={Settings.colors.cyan}>Novità!</Tag>
          </Menu.Item>
          <hr />
          <Menu.Item
            icon={<CustomerServiceOutlined />}
            onClick={() => (window.location = "mailto:info@garzone.store")}
          >
            Assistenza
          </Menu.Item>
          <Menu.Item icon={<LogoutOutlined />} onClick={() => logout()}>
            Esci
          </Menu.Item>
        </Menu>
        <Row align="middle" justify="start" style={styles.footer}>
          <br />
          <br />
          <br />
          <span>
            dglen srl <br />
            Via Turati 26, 20121 Milano
            <br /> P.IVA 09686140964 <br />
            REA MI-2106812 <br />
            Capitale sociale 10.000,00 € <br />
            <br />
            <Link
              target="_blank"
              to={require("../Assets/Materiali/privacy.pdf")}
            >
              Privacy policy
            </Link>{" "}
            <br />
            <Link
              target="_blank"
              to={require("../Assets/Materiali/cond_gen.pdf")}
            >
              Condizioni generali di vendita
            </Link>{" "}
            <br />
            <Link
              target="_blank"
              to={require("../Assets/Materiali/off_comm.pdf")}
            >
              Accordo commerciale
            </Link>{" "}
            <br />
            <Link
              target="_blank"
              to={require("../Assets/Materiali/cookie.pdf")}
            >
              Cookie Policy
            </Link>
          </span>
        </Row>
      </div>
    );
  }
}

const HEADER_HEIGHT = "64px";

const styles = {
  mainCointainer: {
    backgroundColor: "#F2F3F4",
    height: "100%",
  },
  columnText: {
    backgroundColor: Settings.colors.grey,
    marginTop: "15px",
    fontSize: "10px",
  },
  fixedHeader: {
    position: "sticky",
    height: HEADER_HEIGHT,
    width: "100%",
    backgroundColor: "#516375",
    padding: "0 20px",
    zIndex: 100,
  },
  menuContent: {
    width: 256,
    color: "#516375",
    backgroundColor: "#F2F3F4",
    boxShadow: "0",
    outlineColor: "transparent",
  },
  menuTitle: {
    width: 256,
    padding: "15px",
    fontWeight: "bold",
    color: "#516375",
    backgroundColor: "#F2F3F4",
    boxShadow: "0",
    outlineColor: "transparent",
  },
  footer: {
    paddingTop: "30px",
    width: 256,
    padding: "15px",
    color: "#516375",
    backgroundColor: "#F2F3F4",
    fontSize: "10px",
    paddingRight: "15px",
    boxShadow: "0",
    outlineColor: "transparent",
  },
  contentHeaderMenu: {
    textDecoration: "none",
    color: "white",
    padding: 8,
    fontSize: "18px",
  },
};
function mapStateToProps(state) {
  return {
    auth: state.auth,
    chats: state.chat,
    ordini: state.ordini,
  };
}
export default withRouter(connect(mapStateToProps, null)(SidebarMenu));
