//icone ant
import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
//calendar
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";

import { connect } from "react-redux";
import React, { Component } from "react";

//moment js
import moment from "moment";
import "moment/locale/it";
//ant components
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Drawer,
  Input,
  notification,
  Row,
  Select,
  Modal,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
//custom components
import Container from "../Components/Container";
import Loading from "../Components/Loading";
import Label from "../Components/Label";
import PageHeader from "../Components/PageHeader";
//settings
import Settings from "../Config/Settings";
//validation
import { validationSchemaAppuntamento } from "../Config/Validation";
//controllers functions
import {
  getAppuntamenti,
  getAppuntamento,
  getCategorieAppuntamenti,
  postAppuntamento,
  eliminaCategoriaAppuntamenti,
  updateAppuntamento,
  eliminaAppuntamento,
  getAppuntamentiAbilitati,
} from "../Controllers/Appuntamenti";

//formik
import { Formik } from "formik";
import Form from "antd/lib/form/Form";

import locale from "antd/es/date-picker/locale/it_IT";
//commento
//color picker
import { TwitterPicker } from "react-color";

const { Option } = Select;
const { confirm } = Modal;

class Agenda extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      drawer_visible: false,
      selected_appuntamento: null,
      appuntamenti: [],
      categorie_negozio: [],
      nuova_categoria_color: "#c9c9c9",
      appuntamentiAbilitati: true,
      matches: window.matchMedia("(min-width: 600px)").matches,
      utenti: [],
    };
  }

  _localizer = momentLocalizer(moment);
  eventStyleGetter = (event, start, end, isSelected) => {
    let style = {};
    let categoria;
    if (event.categoria) {
      categoria = JSON.parse(event.categoria);
      style = {
        backgroundColor: categoria.colore,
      };
    } else {
      style = {
        backgroundColor: Settings.colors.darkGrey,
      };
    }
    return { style: style };
  };

  _loader = async () => {
    this.setState({ loading: true });

    try {
      const { data } = await getAppuntamenti();
      const res = await getCategorieAppuntamenti();

      const appuntamentiAbilitati = await getAppuntamentiAbilitati();

      let cat = [];
      res.data.forEach((element) => {
        if (element.categoria) {
          cat.push({
            colore: JSON.parse(element.categoria).colore,
            titolo: JSON.parse(element.categoria).titolo,
          });
        }
      });
      let utenti = [];
      for (let i = 0; i < this.props.chats.chats.length; i++) {
        const utenteRecord = {
          nome: this.props.chats.chats[i].chat_title,
          uid: this.props.chats.chats[i].user_to_fb_uid,
        };
        utenti.push(utenteRecord);
      }
      this.setState({
        appuntamenti: data.map(({ id, inizio, fine, titolo, categoria }) => ({
          id: id,
          start: moment(inizio, "DD/MM/YYYY HH:mm").toDate(),
          end: moment(fine, "DD/MM/YYYY HH:mm").toDate(),
          title: titolo,
          categoria: categoria,
        })),
        categorie_negozio: cat,
        appuntamentiAbilitati: appuntamentiAbilitati.data,
        loading: false,
        utenti: utenti,
      });
    } catch (error) {
      console.log("errore", error);
      notification.destroy();
      notification.error({
        title: "Errore",
        description:
          "Si è verificato un errore durante l'ottenimento degli appuntamenti, contatta l'assistenza.",
      });
    } finally {
      this.setState({ loaded: true });
    }
  };
  _submitForm = async (values, { setSubmitting }) => {
    setSubmitting(true);

    const { titolo, descrizione, start, end, categoria, utente } = values;
    let categoriaObject = null;
    for (let i = 0; i < this.state.categorie_negozio.length; i++) {
      if (categoria === this.state.categorie_negozio[i].titolo) {
        categoriaObject = this.state.categorie_negozio[i];
        break;
      }
    }
    try {
      var newInizio = moment(start, "DD/MM/YYYY HH:mm").format(
        "YYYY-MM-DD HH:mm:ss"
      );
      var newEnd = moment(end, "DD/MM/YYYY HH:mm").format(
        "YYYY-MM-DD HH:mm:ss"
      );
      if (this.state.selected_appuntamento) {
        await updateAppuntamento({
          id: this.state.selected_appuntamento.id,
          titolo: titolo,
          descrizione: descrizione,
          inizio: newInizio,
          fine: newEnd,
          categoria: categoriaObject ? JSON.stringify(categoriaObject) : null,
          uidCliente: utente ? utente : "",
        });
      } else {
        await postAppuntamento({
          titolo: titolo,
          descrizione: descrizione,
          inizio: newInizio,
          fine: newEnd,
          categoria: categoriaObject ? JSON.stringify(categoriaObject) : null,
          uidCliente: utente ? utente : "",
        });
      }

      notification.destroy();
      notification.success({
        title: "Perfetto!",
        message: "L'appuntamento è stato aggiunto correttamente",
      });
      this.setState({
        nuova_categoria: "",
        nuova_categoria_color: "#c9c9c9",
        drawer_visible: false,
        selected_appuntamento: null,
      });
      this._loader();
    } catch (error) {
      console.log(error);
      notification.destroy();
      notification.error({
        title: "Errore.",
        message:
          "C'è stato un problema con l'inserimento, contattare l'assistenza.",
      });
    }
  };
  componentDidMount() {
    this._loader();
    const handler = (e) => this.setState({ matches: e.matches });
    window.matchMedia("(min-width: 600px)").addListener(handler);
  }
  onNameChange = (args) => {
    const { value } = args.target;
    this.setState({ nuova_categoria: value });
  };
  render() {
    const { loading, selected_appuntamento } = this.state;
    const {
      titolo,
      descrizione,
      inizio,
      fine,
      categoria,
      uid_utente_firebase,
    } = selected_appuntamento || {};
    const initialValues = {
      categoria: categoria ? JSON.parse(categoria).titolo : null,
      titolo: titolo ?? "",
      descrizione: descrizione ?? "",
      start: inizio ? moment(inizio, "DD/MM/YYYY HH:mm") : moment(),
      end: fine ? moment(fine, "DD/MM/YYYY HH:mm") : moment(),
      utente: uid_utente_firebase,
    };
    return (
      <>
        <Container>
          {this.state.appuntamentiAbilitati ? (
            <>
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: 9999,
                  }}
                  className="chatList"
                >
                  {/*PANNELLO CENTRALE*/}
                  <div
                    style={{
                      position: "absolute",
                      width: this.state.infoPressed
                        ? "calc(100% - 300px)"
                        : "100%",
                      height: "calc(100% - 63px)",
                      padding: "15px",
                      top: "63px",
                      left: 0,
                      borderBottom: "1px solid",
                      overflowY: "scroll",
                      borderColor: Settings.colors.lightGrey,
                    }}
                    id="chat"
                    className="chatList"
                  >
                    <Row>
                      <Col span={24}>
                        <PageHeader
                          title="I tuoi appuntamenti"
                          description="Gestisci appuntamenti del tuo calendario."
                        />
                      </Col>
                    </Row>
                    <Divider />
                    <Row justify="end" style={{ marginBottom: "25px" }}>
                      <Col>
                        <Button
                          size="large"
                          icon={<PlusOutlined />}
                          onClick={() =>
                            this.setState({ drawer_visible: true })
                          }
                          type="primary"
                        >
                          Aggiungi appuntamento
                        </Button>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={24}>
                        <Calendar
                          localizer={this._localizer}
                          events={this.state.appuntamenti}
                          startAccessor="start"
                          endAccessor="end"
                          eventPropGetter={this.eventStyleGetter}
                          onSelectEvent={async (event) => {
                            this.setState({ loading: true });
                            const appuntamento = await (
                              await getAppuntamento(event.id)
                            ).data;
                            this.setState({
                              selected_appuntamento: appuntamento,
                              drawer_visible: true,
                              loading: false,
                            });
                          }}
                          style={{ height: 700 }}
                          messages={{
                            next: "Avanti",
                            previous: "Indietro",
                            today: "Oggi",
                            month: "Mese",
                            week: "Settimana",
                            day: "Giorno",
                            allDay: "Tutto il giorno",
                            agenda: "Agenda",
                            date: "Data",
                            time: "Orario",
                            event: "Evento",
                            showMore: (target) => (
                              <span className="ml-2" role="presentation">
                                {" "}
                                +{target} altri...
                              </span>
                            ),
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
              {/*Drawer creazione/modifica appuntamento*/}
              <Drawer
                destroyOnClose
                placement="right"
                closable={true}
                onClose={async () => {
                  this.setState({
                    drawer_visible: false,
                    selected_appuntamento: null,
                  });
                }}
                visible={this.state.drawer_visible}
                width={this.state.matches ? "500px" : "100%"}
                closeIcon={<CloseOutlined style={{ fontSize: 24 }} />}
              >
                <Row justify="start" align="middle" gutter={[15, 15]}>
                  <Col xs={24}>
                    <PageHeader
                      title={
                        selected_appuntamento
                          ? "Modifica appuntamento"
                          : "Aggiungi appuntamento"
                      }
                      description={
                        selected_appuntamento
                          ? `Aggiorna i dettagli dell'appuntamento: ${selected_appuntamento.titolo}`
                          : "Aggiungi un nuovo appuntamento."
                      }
                    />
                  </Col>
                </Row>
                <Divider />
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchemaAppuntamento}
                  onSubmit={this._submitForm}
                  validateOnMount={false}
                  validateOnChange={true}
                >
                  {({
                    touched,
                    errors,
                    values,
                    handleChange,
                    handleSubmit,
                    handleBlur,
                    setFieldValue,
                    isValid,
                    dirty,
                    isSubmitting,
                  }) => (
                    <Form>
                      <Row justify="start" align="top" gutter={[15, 15]}>
                        <Col xs={24}>
                          <Label titolo="Dati appuntamento" />
                        </Col>

                        <Col span={24}>
                          <span
                            style={{
                              marginBottom: 10,
                              color: Settings.colors.darkGrey,
                            }}
                          >
                            Categoria Appuntamento
                          </span>
                          <Select
                            onChange={(value) => {
                              setFieldValue("categoria", value);
                            }}
                            style={{ width: "100%" }}
                            value={values.categoria}
                            optionLabelProp="label"
                            onBlur={handleBlur("categoria")}
                            placeholder="Inserisci una categoria"
                            dropdownRender={(menu) => (
                              <div>
                                {menu}
                                <Divider style={{ margin: "10px 0" }} />
                                <Row
                                  justify="space-between"
                                  align="middle"
                                  style={{ padding: "0 5px" }}
                                >
                                  <Col
                                    span={16}
                                    style={{ marginBottom: "5px" }}
                                  >
                                    <Input
                                      placeholder="Inserisci nome nuova categoria..."
                                      style={{ flex: "auto" }}
                                      value={this.state.nuova_categoria}
                                      onChange={this.onNameChange}
                                    />
                                  </Col>
                                  <Col span={7}>
                                    <Input
                                      style={{
                                        marginBottom: "5px",
                                        backgroundColor:
                                          this.state.nuova_categoria_color,
                                      }}
                                      value={this.state.nuova_categoria_color}
                                      onClick={() => {
                                        this.setState({ pickerVisible: true });
                                      }}
                                    ></Input>
                                  </Col>
                                  <Row align="middle" justify="end">
                                    <Col span={12}>
                                      {this.state.pickerVisible ? (
                                        <TwitterPicker
                                          onChangeComplete={(color) => {
                                            this.setState({
                                              nuova_categoria_color: color.hex,
                                              pickerVisible: false,
                                            });
                                          }}
                                          triangle="top-right"
                                        />
                                      ) : null}
                                    </Col>
                                  </Row>

                                  <Col span={24}>
                                    <Button
                                      style={{ width: "100%" }}
                                      type="primary"
                                      disabled={
                                        this.state.isAdding ||
                                        this.state.nuova_categoria === ""
                                      }
                                      icon={<PlusOutlined />}
                                      onClick={
                                        this.state.nuova_categoria
                                          ? () => {
                                              let categorie =
                                                this.state.categorie_negozio;
                                              let nuovaCat = {
                                                titolo:
                                                  this.state.nuova_categoria,
                                                colore:
                                                  this.state
                                                    .nuova_categoria_color,
                                              };
                                              categorie.push(nuovaCat);
                                              this.setState({
                                                categorie_negozio: categorie,
                                                categoriaSelezionata: nuovaCat,
                                                nuova_categoria: "",
                                              });
                                            }
                                          : null
                                      }
                                    >
                                      Aggiungi nuova
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                            )}
                          >
                            <Option value={null} label="Nessuna Categoria">
                              -Nessuna Categoria-
                            </Option>
                            {this.state.categorie_negozio
                              ? this.state.categorie_negozio.map((item) => (
                                  <Option
                                    key={item.titolo}
                                    value={item.titolo}
                                    label={item.titolo}
                                  >
                                    <Row justify="space-between" align="middle">
                                      <Col span={16}>{item.titolo}</Col>
                                      <Col>
                                        <div
                                          style={{
                                            width: "50px",
                                            height: "20px",
                                            backgroundColor: item.colore,
                                            borderRadius: "14px",
                                          }}
                                        >
                                          {" "}
                                        </div>
                                      </Col>

                                      <Col>
                                        <DeleteOutlined
                                          style={{ color: Settings.colors.red }}
                                          onClick={() =>
                                            Modal.confirm({
                                              title: `Stai eliminando la categoria "${item.titolo}"`,
                                              icon: (
                                                <ExclamationCircleOutlined />
                                              ),
                                              content: `Sei sicuro di voler eliminare la categoria ${item.titolo}? Facendolo verranno eliminate le categorie degli appuntamenti che prima ne erano provvisti.`,
                                              okText: "Conferma",
                                              cancelText: "Annulla",
                                              onOk: async () => {
                                                const {
                                                  categorie_negozio,
                                                  appuntamenti,
                                                } = this.state;
                                                let categorie =
                                                  categorie_negozio.filter(
                                                    (i) => i !== item
                                                  );
                                                let appuntamenti_edit = [
                                                  ...appuntamenti,
                                                ];
                                                try {
                                                  await eliminaCategoriaAppuntamenti(
                                                    JSON.stringify(item)
                                                  );
                                                } catch (err) {
                                                  notification.destroy();
                                                  notification.error({
                                                    message: "Errore!",
                                                    description:
                                                      "Non è stato possibile completare la richiesta",
                                                  });
                                                }
                                                appuntamenti_edit
                                                  .filter(
                                                    (i) => i.categoria === item
                                                  )
                                                  .map(
                                                    (i) => (i.categoria = "")
                                                  );
                                                setFieldValue(
                                                  "categoria",
                                                  null
                                                );
                                                this.setState({
                                                  categorie_negozio: categorie,
                                                  appuntamenti:
                                                    appuntamenti_edit,
                                                });
                                              },
                                            })
                                          }
                                        />
                                      </Col>
                                    </Row>
                                  </Option>
                                ))
                              : ""}
                          </Select>
                          <div className="input-error">
                            {touched.categoria && errors.categoria}
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={24}>
                          <span
                            style={{
                              marginBottom: 10,
                              color: Settings.colors.darkGrey,
                            }}
                          >
                            Titolo
                          </span>
                          <Input
                            value={values.titolo}
                            onChange={handleChange("titolo")}
                            placeholder="Inserisci titolo appuntamento..."
                            onBlur={handleBlur("titolo")}
                          />
                          <div className="input-error">
                            {touched.titolo && errors.titolo}
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={24}>
                          <span style={{ color: Settings.colors.darkGrey }}>
                            Descrizione (opzionale)
                          </span>
                          <TextArea
                            value={values.descrizione}
                            onChange={handleChange("descrizione")}
                            placeholder="Inserisci una descrizione per l'appuntamento"
                            autoSize={{ minRows: 7, maxRows: 7 }}
                            onBlur={handleBlur("descrizione")}
                            style={{ height: 180 }}
                          />
                          <div className="input-error">
                            {touched.descrizione && errors.descrizione}
                          </div>
                        </Col>
                        <Col span={24}>
                          <span style={{ color: Settings.colors.darkGrey }}>
                            Inizio
                          </span>
                          {/* <DatePicker
                            showTime
                            allowClear={false}
                            disabledDate={(date) =>
                              moment() && date < moment().endOf("day")
                            }
                            locale={locale}
                            style={{ width: "100%" }}
                            format={"DD/MM/YYYY HH:mm"}
                            value={moment(values.start, "DD/MM/YYYY HH:mm")}
                            onChange={(_, dateString) => {
                              setFieldValue("start", dateString);
                              if (
                                moment(
                                  values.start,
                                  "DD/MM/YYYY HH:mm"
                                ).isSameOrAfter(
                                  moment(values.end, "DD/MM/YYYY HH:mm")
                                )
                              ) {
                                setFieldValue("end", dateString);
                              }
                            }}
                          />
                          <div className="input-error">
                            {touched.start && errors.start}
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <span style={{ color: Settings.colors.darkGrey }}>
                            Fine
                          </span>
                          <DatePicker
                            showTime
                            allowClear={false}
                            disabledDate={(date) =>
                              date < moment(values.start, "DD/MM/YYYY HH:mm")
                            }
                            locale={locale}
                            style={{ width: "100%" }}
                            format={"DD/MM/YYYY HH:mm"}
                            value={moment(values.end, "DD/MM/YYYY HH:mm")}
                            onChange={(_, dateString) =>
                              setFieldValue("end", dateString)
                            }
                          />
                          <div className="input-error">
                            {touched.end && errors.end}
                          </div> */}
                          <DatePicker.RangePicker
                            allowClear={false}
                            disabledDate={(date) => date < moment()}
                            locale={locale}
                            style={{ width: "100%" }}
                            showTime={{ format: "HH:mm" }}
                            format="DD/MM/YYYY HH:mm"
                            defaultValue={[
                              moment(values.start, "DD/MM/YYYY HH:mm"),
                              moment(values.end, "DD/MM/YYYY HH:mm"),
                            ]}
                            onChange={(value, dateString) => {
                              setFieldValue("start", dateString[0]);
                              setFieldValue("end", dateString[1]);
                            }}
                          />
                        </Col>
                        <Col span={24}>
                          <span style={{ color: Settings.colors.darkGrey }}>
                            Collega un cliente
                          </span>
                          <Select
                            onChange={(value) => {
                              setFieldValue("utente", value);
                            }}
                            style={{ width: "100%" }}
                            value={values.utente}
                            optionLabelProp="label"
                            onBlur={handleBlur("utente")}
                            placeholder="Seleziona un cliente"
                          >
                            <Option value={null} label="Nessun cliente">
                              -Nessun Cliente-
                            </Option>
                            {this.state.utenti !== []
                              ? this.state.utenti.map((item) => (
                                  <Option
                                    key={item.uid}
                                    value={item.uid}
                                    label={item.nome}
                                  >
                                    <span>{item.nome}</span>
                                  </Option>
                                ))
                              : null}
                          </Select>
                        </Col>
                      </Row>

                      <Divider />
                      <Row justify="center" align="middle">
                        <Col span={24}>
                          <Button
                            loading={isSubmitting}
                            size="large"
                            disabled={!(isValid && dirty) || isSubmitting}
                            icon={
                              selected_appuntamento ? (
                                <EditOutlined />
                              ) : (
                                <PlusOutlined />
                              )
                            }
                            block={true}
                            onClick={() => {
                              if (!isValid)
                                notification.error({
                                  message: "Errore",
                                  description: "Ricontrolla i dati inseriti",
                                });
                              handleSubmit();
                            }}
                            type="primary"
                          >
                            {this.state.selected_appuntamento ? (
                              <span>Aggiorna</span>
                            ) : (
                              <span>Aggiungi</span>
                            )}
                          </Button>
                        </Col>
                        {this.state.selected_appuntamento ? (
                          <Col span={24} style={{ marginTop: "25px" }}>
                            <Button
                              style={{
                                backgroundColor: Settings.colors.white,
                                color: Settings.colors.red,
                              }}
                              size="large"
                              block={true}
                              onClick={() => {
                                Modal.confirm({
                                  title: `Stai eliminando l'appuntamento' "${this.state.selected_appuntamento.titolo}"`,
                                  icon: <ExclamationCircleOutlined />,
                                  content: `Sei sicuro di voler eliminare l'appuntamento ${this.state.selected_appuntamento.titolo}?`,
                                  okText: "Conferma",
                                  cancelText: "Annulla",
                                  onOk: async () => {
                                    try {
                                      await eliminaAppuntamento(
                                        this.state.selected_appuntamento.id
                                      );
                                    } catch (err) {
                                      notification.destroy();
                                      notification.error({
                                        message: "Errore!",
                                        description:
                                          "Non è stato possibile completare la richiesta",
                                      });
                                    }

                                    this.setState({
                                      selected_appuntamento: null,
                                      drawer_visible: false,
                                    });
                                    this._loader();
                                  },
                                });
                              }}
                            >
                              Elimina appuntamento
                            </Button>
                          </Col>
                        ) : null}
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Drawer>
              {/*End Drawer creazione/modifica promozione*/}
              <Loading loading={loading} opaque />{" "}
            </>
          ) : (
            <>
              <Loading loading={loading} opaque />{" "}
              <Row>
                <Col span={24}>
                  <PageHeader
                    title="I tuoi appuntamenti"
                    description="Non hai abilitato la funzione Agenda dalla tua scheda negozio."
                  />
                </Col>
              </Row>
            </>
          )}
        </Container>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    chats: state.chat,
  };
}
export default connect(mapStateToProps, null)(Agenda);
