import React from "react";

// import router
import { Route, Redirect } from "react-router-dom";

class PrivateRoute extends React.Component {
  is_mounted = false;

  constructor(props) {
    super(props);
    this.state = {
      not_found: false,
    };
  }

  componentDidMount() {
    this.is_mounted = true;
    console.log("PRIVATE PROPS", this.props);
  }

  componentWillUnmount() {
    this.is_mounted = false;
  }

  render() {
    return this.props.notVerified ? (
      <Redirect to={"/conferma-mail"} />
    ) : this.props.abilitato === false && this.props.authed === true ? (
      <Redirect to={"/non-abilitato"} />
    ) : this.props.funzioneNonAbilitata === true &&
      this.props.authed === true ? (
      <Redirect to={"/funzione-non-abilitata"} />
    ) : (this.props.privacy === false || this.props.accordo_comm === 0) &&
      this.props.authed === true ? (
      <Redirect
        to={{
          pathname: "/condizioni",
          state: {
            privacy: this.props.privacy,
            accordo_comm: this.props.accordo_comm,
          },
        }}
      />
    ) : this.props.authed == true ? (
      <Route {...this.props} />
    ) : this.props.location.pathname != "/conferma-mail" ? (
      <Redirect
        to={{ pathname: "/accedi", state: { from: this.props.location } }}
      />
    ) : (
      <Route {...this.props} />
    );
  }
}

export default PrivateRoute;
