import { Alert, Col, Divider, notification, Row, Switch, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import Settings from "../Config/Settings";
import {
  getStripeDetails,
  getStripeLoginLink,
  getStripeOnboardLink,
  updatePagamentiOnline,
  updatePagamentiOffline,
} from "../Controllers/Negozio";
import LoadingBlock from "./LoadingBlock";

const ModuloStripe = ({ negozio, getNegozio }) => {
  console.log(negozio);
  const [stripe_details, setStripeDetails] = useState(null);
  const [link, setLink] = useState(null);
  const [pagamenti_online, setPagamentiOnline] = useState(
    negozio.pagamenti_online
  );
  const [pagamenti_offline, setPagamentiOffline] = useState(
    negozio.pagamenti_offline
  );

  useEffect(() => {
    _getStripeDetails();
  }, []);

  const _getStripeDetails = async () => {
    try {
      const { data } = await getStripeDetails(negozio.id);
      if (data && data.onboarded !== null) {
        setStripeDetails(data);
        const res = await getStripeLoginLink(negozio.id);
        setLink(res.data.url);
      } else {
        const res = await getStripeOnboardLink(negozio.id, {});
        setLink(res.data.url);
      }
    } catch (error) {
      notification.destroy();
      notification.error({
        message: "Errore",
        description: "Errore nella richiesta",
      });
      console.error(error);
    }
  };

  const _setPagamentiOnline = async (e) => {
    try {
      setPagamentiOnline(e);
      const { data } = await updatePagamentiOnline(negozio.id, e);
      notification.destroy();
      notification.success({
        message: "Congratulazioni!",
        description: `Hai ${data ? "abilitato" : "disabilitato"
          } i pagamenti online con successo!`,
      });
      if (data !== e) setPagamentiOnline(data);
    } catch (error) {
      setPagamentiOnline(!e);
      notification.destroy();
      notification.error({
        message: "Errore",
        description: "Errore nella richiesta",
      });
      console.error(error);
    }
  };
  const _setPagamentiOffline = async (e) => {
    try {
      setPagamentiOffline(e);
      const { data } = await updatePagamentiOffline(negozio.id, e);
      notification.destroy();
      notification.success({
        message: "Congratulazioni!",
        description: `Hai ${data ? "abilitato" : "disabilitato"
          } i pagamenti di persona con successo!`,
      });
      if (data !== e) setPagamentiOffline(data);
    } catch (error) {
      notification.destroy();
      notification.error({
        message: "Errore",
        description: "Errore nella richiesta",
      });
      console.error(error);
    }
  };

  const _getTooltipOnline = () => {
    if (stripe_details) {
      if (stripe_details.onboarded) {
        if (!pagamenti_offline)
          return "Per disabilitare i pagamenti online devi prima abilitare i pagamenti di persona";
        else if (pagamenti_online) return "Disabilita pagamenti online";
        else return "Abilita pagamenti online";
      } else
        return "Per abilitare i pagamenti online, devi prima connettere il tuo account a Stripe mediante l'apposito pulsante";
    } else
      return "Per abilitare i pagamenti online, devi prima connettere il tuo account a Stripe mediante l'apposito pulsante";
  };

  const _getTooltipOffline = () => {
    if (!pagamenti_online)
      return "Per disabilitare i pagamenti offline devi prima abilitare i pagamenti online";
    else if (pagamenti_offline) return "Disabilita pagamenti offline";
    else return "Abilita pagamenti offline";
  };

  return (
    <Row>
      <Col
        style={{
          backgroundColor: Settings.colors.darkerGrey,
          color: Settings.colors.white,
          borderRadius: "4px",
          padding: "10px",
        }}
        span={24}
      >
        <Row style={{ marginBottom: 15 }}>
          <span style={{ fontSize: "24px" }}>Pagamenti</span>
        </Row>
        <Row style={{ marginBottom: 15 }}>
          <span>
            Permetti ai clienti di pagare in contanti i loro ordini di consegna
            a domicilio e di ritiro in negozio.
          </span>
        </Row>
        <Row style={{ marginBottom: 15 }}>
          <Col>
            <Tooltip title={_getTooltipOffline()} placement="right">
              <Switch
                checked={pagamenti_offline}
                onChange={_setPagamentiOffline}
                disabled={!pagamenti_online}
              />
            </Tooltip>
          </Col>
          <Col>
            <span style={{ marginLeft: "15px" }}>Pagamenti di persona</span>
          </Col>
        </Row>
        <Divider />
        <Row style={{ marginBottom: 15 }}>
          <span>
            Permetti ai clienti di pagare online i loro ordini di consegna a
            domicilio e di ritiro in negozio.
          </span>
        </Row>
        <Row style={{ marginBottom: 15 }}>
          <Col>
            <Tooltip title={_getTooltipOnline()} placement="right">
              <Switch
                checked={pagamenti_online}
                onChange={_setPagamentiOnline}
                disabled={
                  !pagamenti_offline ||
                  !stripe_details ||
                  !stripe_details.onboarded
                }
              />
            </Tooltip>
          </Col>
          <Col>
            <span style={{ marginLeft: "15px" }}>Pagamenti online</span>
          </Col>
        </Row>
        <Row gutter={[15, 15]} style={{ marginBottom: 15 }}>
          {link ? (
            <>
              {stripe_details && !stripe_details.onboarded ? (
                <Col span={24}>
                  <Alert
                    message={
                      <span style={{ color: Settings.colors.darkGrey }}>
                        <b>Dati mancanti</b>
                      </span>
                    }
                    description={
                      <span
                        style={{
                          marginBottom: 0,
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Inserisci i dati mancanti dalla dashboard Stripe
                      </span>
                    }
                    type="warning"
                    showIcon={true}
                    style={{
                      zIndex: 1,
                      width: "100%",
                      borderRadius: 5,
                    }}
                  />
                </Col>
              ) : null}
              {stripe_details ? (
                <Col span={24}>
                  <a
                    href={link}
                    target="_blank"
                    className="stripe-connect white"
                  >
                    <span>{stripe_details ? "Dashboard" : "Connetti a"}</span>
                  </a>
                </Col>
              ) : (
                <Col span={24}>
                  <a
                    href={link}
                    target="_self"
                    className="stripe-connect white"
                  >
                    <span>{stripe_details ? "Dashboard" : "Connetti a"}</span>
                  </a>
                </Col>
              )}
            </>
          ) : (
            <Col span={24}>
              <LoadingBlock loading={true} height={"12px"} />
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default ModuloStripe;
