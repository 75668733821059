import React from 'react';

// import Auth controller functions
import { requestResetPassword } from '../Controllers/Auth';

// import ant design
import { Input, Button, Alert, notification, Col, Row } from 'antd';

import CustomResult from '../Components/CustomResult';
import Timer from '../Components/Timer';
class RecuperaPassword extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			error_message: '',
			run_timer: false
		};
	}

	// Updates form values into the component's state
	_handleChange = (e) => {
		this.setState({ error_message: '' });
		this.setState({ [e.target.id]: e.target.value });
	};

	// Fires the reset password request when form is submitted
	_doRequestResetPassword = (e) => {
		e.preventDefault();
		this._callReset();
	};

	_callReset = () => {
		requestResetPassword(this.state.email)
			.then(() => {
				// email sent
				// clean error_message
				this.setState({ error_message: '' });
				// svuoto il campo email
				this.setState({ email: '', run_timer: true });
				// notifica di successo
				notification.success({
					message: 'Perfetto!',
					description:
						'Abbiamo appena inviato una email con le istruzioni per impostare una nuova password. Controlla anche la casella “spam”.'
				});
			})
			.catch((e) => {
				this.setState({ error_message: "L'email inserita non è valida" });
			});
	};

	render() {
		return (
			<div className="login-background">
				<Row justify="center" align="middle" style={{ display: 'flex', margin: '15px 0' }}>
					<img src={require('../Assets/Immagini/logo.png')} style={{ height: 100, marginTop: 35, marginBottom: 39 }} alt="logo" />
				</Row>
				<Row className="login-div" justify="center" align="middle">
					<Col>
						<h1 className="login-benvenuto">Hai dimenticato la password?</h1>
						{this.state.error_message !== '' ? (
							<Row justify="center" align="middle">
								<Col xs={{ span: 16 }} sm={{ span: 12 }} md={{ span: 16 }} style={{ textAlign: 'center' }}>
									<Alert message={this.state.error_message} type="error" style={{ marginBottom: '15px' }} />
								</Col>
							</Row>
						) : null}
						<p style={{ textAlign: 'center', color: 'var(--white)', fontSize: 15 }}>
							Inserisci qui sotto la email che hai usato per registrarti,<br />ti invieremo le informazioni per reimpostare la
							password
						</p>
						<form
							onSubmit={(e) => this._doRequestResetPassword(e)}
							style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', alignSelf: 'center' }}
						>
							<Input
								className="input-text"
								placeholder="Email"
								id="email"
								value={this.state.email}
								onChange={(e) => this._handleChange(e)}
								style={{ marginBottom: 20 }}
							/>
							{this.state.run_timer ? (
								<p style={{ fontSize: '14px' }}>
									Attendi
									<Timer
										seconds={60}
										isRunning={this.state.run_timer}
										onTimerEnds={() => {
											this.setState({ run_timer: false });
										}}
									/>
									secondi prima di richiedere una nuova email
								</p>
							) : (
								<Button block type="primary" htmlType="submit" style={{ marginBottom: '15px' }}>
									Invia richiesta
								</Button>
							)}
						</form>
						<div style={{ marginTop: 40, textAlign: 'center' }}>
							<span style={{ color: 'var(--white)' }}>
								Hai le credenziali?<br />Accedi subito al tuo account.{' '}
							</span>
							<a onClick={() => this.props.history.push('/accedi')}>Clicca qui</a>
						</div>
					</Col>
				</Row>
			</div>
		);
	}
}

export default RecuperaPassword;
