import { Functions } from "../Config/Fire";

//Ottieni tutte le categorie dei negozi
export async function getAppuntamenti() {
  const appuntamenti = Functions.httpsCallable("negozio-getAppuntamenti");
  return await appuntamenti();
}
export async function getAppuntamento(data) {
  const getAppuntamento = Functions.httpsCallable("negozio-getAppuntamento");
  return await getAppuntamento(data);
}
export async function postAppuntamento(data) {
  const postAppuntamento = Functions.httpsCallable("negozio-postAppuntamento");
  return await postAppuntamento(data);
}
export async function getCategorieAppuntamenti() {
  const getCategorieAppuntamenti = Functions.httpsCallable(
    "negozio-getCategorieAppuntamenti"
  );
  return await getCategorieAppuntamenti();
}
export async function eliminaCategoriaAppuntamenti(categoria) {
  const eliminaCategoriaAppuntamenti = Functions.httpsCallable(
    "negozio-eliminaCategoriaAppuntamenti"
  );
  return await eliminaCategoriaAppuntamenti(categoria);
}
export async function updateAppuntamento(data) {
  const updateAppuntamento = Functions.httpsCallable(
    "negozio-updateAppuntamento"
  );
  return await updateAppuntamento(data);
}
export async function eliminaAppuntamento(data) {
  const eliminaAppuntamento = Functions.httpsCallable(
    "negozio-eliminaAppuntamento"
  );
  return await eliminaAppuntamento(data);
}
export async function getAppuntamentiAbilitati() {
  const getAppuntamentiAbilitati = Functions.httpsCallable(
    "negozio-getAppuntamentiAbilitati"
  );
  return await getAppuntamentiAbilitati();
}
