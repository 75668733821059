import React from "react";

// import redux
import { connect } from "react-redux";

//import antd
import {
  Row,
  Col,
  Button,
  Input,
  Modal,
  Drawer,
  Select,
  Divider,
  Table,
  Space,
  InputNumber,
  Empty,
  ConfigProvider,
  notification,
  Tag,
  Statistic,
  Popover,
  Checkbox,
  Switch,
} from "antd";
import {
  SearchOutlined,
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  CloseOutlined,
  PlusOutlined,
  FileImageOutlined,
  QuestionCircleFilled,
  InfoCircleOutlined,
} from "@ant-design/icons";

//import components and styles
import Container from "../Components/Container";
import { Formik, Form } from "formik";
import Highlighter from "react-highlight-words";
import ImageCropper from "../Components/ImageCropper";
import Loading from "../Components/Loading";
import PageHeader from "../Components/PageHeader";

//import settings from config
import Settings from "../Config/Settings";
import { validationSchemaProdotto } from "../Config/Validation";

// import Registrazione controller functions
import {
  getProdottiNegozio,
  getCategorieProdotti,
  setCategorieProdotti,
  eliminaProdotto,
  aggiornaProdotto,
  creaProdotto,
  getProdotto,
  setCategoriaProdotti,
} from "../Controllers/Negozio";
import Label from "../Components/Label";
import Chip from "../Components/Chip";

//Costanti
const { confirm } = Modal;
const { Option } = Select;
const { TextArea } = Input;

class Prodotti extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      loading_logo: false,
      loading_prodotto: false,
      loading_categoria: false,
      prodotti: [],
      drawer_visible: false,
      modal_visible: false,
      searchText: "",
      searchedColumn: "",
      filteredInfo: null,
      sortedInfo: null,
      selected_product: null,
      selected_index: null,
      categorie_negozio: [],
      nuova_categoria: "",
      isAdding: false,
      scontato_visible: false,
      img_to_delete: null,
      categoria_edit: "",
      prezzo_zero: false,
    };
  }

  /*Funzioni gestione prodotti*/
  _eliminaProdotto = (prodotto) => {
    confirm({
      title: "Elimina prodotto",
      icon: <ExclamationCircleOutlined />,
      content: `Sei sicuro di voler eliminare il prodotto: ${prodotto.nome} ?`,
      okText: "Elimina",
      okType: "danger",
      cancelText: "Chiudi",
      onOk: async () => {
        try {
          //Rimuovo prodotto dal db
          const prodotto_eliminato = await eliminaProdotto(
            prodotto.id,
            this.props.auth.user.id_negozio
          );

          //Rimuovo prodotto nello stato
          let prodotti = [...this.state.prodotti];
          prodotti = prodotti.filter(function (p) {
            return p.id != prodotto.id;
          });
          this.setState({ prodotti: prodotti });

          //Notifico l'utente
          notification.destroy();
          notification.success({
            message: "Prodotto eliminato",
            description: `Il prodotto ${prodotto.nome} è stato eliminato.`,
          });
        } catch (error) {
          console.log("errore", error);
          notification.error({
            title: "Errore",
            description:
              "Si è verificato un errore durante l'eliminazione del prodotto",
          });
        }
      },
    });
  };

  _submitForm = async (values, { setSubmitting }) => {
    this.setState({ loading_prodotto: true });
    const { user } = this.props.auth;
    const { selected_product, img_to_delete } = this.state;
    const prodotti = [...this.state.prodotti];

    //Destrutturazione richiesta
    let message, description;
    const {
      nome,
      descrizione,
      categoria,
      immagine,
      prezzo,
      prezzo_scontato,
      ordinabile,
      non_rimborsabile,
    } = values;

    const prodottoRecord = {
      id_negozio: user.id_negozio,
      nome: nome,
      descrizione: descrizione ?? "",
      categoria: categoria,
      immagine: immagine ?? null,
      prezzo: prezzo,
      prezzo_scontato: prezzo_scontato ?? null,
      ordinabile: ordinabile,
      non_rimborsabile: non_rimborsabile,
    };

    try {
      let prodotto;
      if (selected_product) {
        const { id } = selected_product;
        prodotto = await aggiornaProdotto(id, {
          ...prodottoRecord,
          img_to_delete: img_to_delete,
        });

        message = "Prodotto aggiornato!";
        description = `Il prodotto ${nome} è stato correttamente aggiornato`;

        const foundIndex = prodotti.findIndex((x) => x.id == id);
        prodotti[foundIndex] = {
          id: id,
          ...prodottoRecord,
          immagine: prodotto.data.img_url ?? immagine,
        };

        this.setState({
          prodotti: prodotti,
        });
      } else {
        prodotto = await creaProdotto(prodottoRecord);

        message = "Prodotto creato";
        description = `Il prodotto ${nome} è stato correttamente creato`;

        prodotti.push({
          id: prodotto.data.id,
          ...prodottoRecord,
          immagine: prodotto.data.img_url,
        });
        this.setState({
          prodotti: prodotti,
        });
      }

      this.setState({
        drawer_visible: false,
        selected_product: null,
        selected_index: null,
        loading_prodotto: false,
      });
      notification.destroy();
      notification.success({
        message: message,
        description: description,
      });
    } catch (e) {
      console.log("Errore", e);
      setSubmitting(false);
      notification.destroy();
      if (e.code.includes("aborted")) {
        notification.warn({
          message: "Si è verificato un errore interno",
          description: e.details.dettaglio,
        });
        this.setState({ drawer_visible: false });
      } else {
        notification.warn({
          message: "Errore",
          description: "Si è verificato un errore",
        });
      }
    }
  };
  /*End funzioni gestione prodotto*/

  /*Funzioni tabelle*/
  _getColumns = () => {
    let { sortedInfo, filteredInfo } = this.state;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};
    return [
      {
        title: "Prodotto",
        dataIndex: "nome",
        key: "nome",
        width: 200,
        sorter: (a, b) => a.nome.localeCompare(b.nome),
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("nome", "nome"),
        filteredValue: filteredInfo.nome || null,
        sortOrder: sortedInfo.columnKey === "nome" && sortedInfo.order,
      },
      {
        title: "Categoria",
        width: 150,
        dataIndex: "categoria",
        key: "categoria",
        sorter: (a, b) => a.categoria.localeCompare(b.categoria),
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("categoria", "categoria"),
        filteredValue: filteredInfo.categoria || null,
        sortOrder: sortedInfo.columnKey === "categoria" && sortedInfo.order,
      },
      {
        title: "Prezzo",
        dataIndex: "prezzo",
        key: "prezzo",
        align: "center",
        width: 150,
        sorter: (a, b) => a.prezzo - b.prezzo,
        sortDirections: ["descend", "ascend"],
        render: (text, record) =>
          record.prezzo !== null ? (
            record.prezzo === 0 ? (
              <Tag color="orange">Nessuno</Tag>
            ) : (
              <Statistic
                prefix={<span style={{ color: "var(--darkGrey)" }}>€</span>}
                value={record.prezzo}
                precision={2}
                decimalSeparator=","
              />
            )
          ) : (
            <Tag color="orange">Nessuno</Tag>
          ),
        filteredValue: filteredInfo.prezzo || null,
        sortOrder: sortedInfo.columnKey === "prezzo" && sortedInfo.order,
      },
      {
        title: "Prezzo scontato",
        dataIndex: "prezzo_scontato",
        key: "prezzo_scontato",
        align: "center",
        width: 150,
        sorter: (a, b) => a.prezzo_scontato - b.prezzo_scontato,
        sortDirections: ["descend", "ascend"],
        render: (text, record) =>
          record.prezzo_scontato !== null ? (
            record.prezzo_scontato === 0 ? (
              <Tag color="orange">Nessuno</Tag>
            ) : (
              <Statistic
                prefix={<span style={{ color: "var(--darkGrey)" }}>€</span>}
                value={record.prezzo_scontato}
                precision={2}
                decimalSeparator=","
              />
            )
          ) : (
            <Tag color="orange">Nessuno</Tag>
          ),
        filteredValue: filteredInfo.prezzo_scontato || null,
        sortOrder:
          sortedInfo.columnKey === "prezzo_scontato" && sortedInfo.order,
      },
      {
        title: "Ordinabile",
        dataIndex: "ordinabile",
        key: "ordinabile",
        align: "center",
        width: 150,
        render: (text, record) =>
          record.ordinabile === 1 ? (
            <Tag color="green">Sì</Tag>
          ) : (
            <Tag color="red">No</Tag>
          ),
      },
      {
        title: "Rimborsabile",
        dataIndex: "non_rimborsabile",
        key: "non_rimborsabile",
        align: "center",
        width: 150,
        render: (text, record) =>
          record.non_rimborsabile === 0 ? (
            <Tag color="green">Sì</Tag>
          ) : (
            <Tag color="red">No</Tag>
          ),
      },
      {
        title: "",
        dataIndex: "settings",
        key: "settings",
        align: "right",
        width: 100,
        render: (text, record, index) => (
          <Space size="middle">
            <EyeOutlined
              style={{ color: Settings.colors.grey, fontSize: 18 }}
              onClick={async () => {
                this.setState({ loaded: false });
                const { data } = await getProdotto(record.id);
                this.setState({
                  modal_visible: true,
                  selected_product: data,
                  selected_index: index,
                  loaded: true,
                });
              }}
            />
            <EditOutlined
              style={{ color: Settings.colors.grey, fontSize: 18 }}
              onClick={async () => {
                this.setState({ loaded: false });
                const { data } = await getProdotto(record.id);
                this.setState({
                  drawer_visible: true,
                  selected_product: data,
                  selected_index: index,
                  scontato_visible:
                    record.prezzo_scontato !== null ? true : false,
                  img_to_delete: record.immagine,
                  loaded: true,
                });
              }}
            />
            <DeleteOutlined
              onClick={async () => {
                this._eliminaProdotto(record);
              }}
              style={{ color: Settings.colors.grey, fontSize: 18 }}
            />
          </Space>
        ),
      },
    ];
  };

  //Ricerca
  getColumnSearchProps = (title, dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Cerca ${title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Cerca
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.filteredInfo == null ? (
        text
      ) : this.state.searchedColumn === dataIndex &&
        this.state.filteredInfo[dataIndex] != null ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  //Ricerca
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  //Ricerca
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };

  _clearFiltri = () => {
    this.setState({
      filteredInfo: null,
      sortedInfo: null,
    });
  };

  _handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  /*End funzioni tabelle*/

  /*Funzioni componente select categorie*/
  onNameChange = (event) => {
    this.setState({
      nuova_categoria: event.target.value,
    });
  };

  /*Funzioni componente select categorie*/
  addItem = async (id_negozio) => {
    try {
      let { categorie_negozio, nuova_categoria } = this.state;
      nuova_categoria = nuova_categoria.toLowerCase();
      const test = /^[A-zÀ-ú0-9]+(\s+[-a-zA-Z0-9-()]+)*$/gi;
      if (!test.test(nuova_categoria)) {
        notification.destroy();
        notification.warn({
          message: "Attenzione!",
          description:
            "Puoi solo inserire lettere e numeri, senza spazi vuori iniziali/finali",
        });
        this.setState({
          isAdding: false,
        });
      } else if (categorie_negozio.includes(nuova_categoria)) {
        notification.destroy();
        notification.warn({
          message: "Attenzione!",
          description: "Hai inserito una categoria già esistente",
        });
        this.setState({
          nuova_categoria: "",
          isAdding: false,
        });
      } else {
        this.setState({ loaded: false });
        //Creo nuovo array categorie
        let new_categorie = [...categorie_negozio, nuova_categoria];
        //Aggiorno le categorie del negozio nel db
        new_categorie = new_categorie.join(",");
        let categorieDb = await setCategorieProdotti(id_negozio, new_categorie);

        this.setState({
          categorie_negozio: new_categorie.split(",") || `Nuova categoria`,
          nuova_categoria: "",
          isAdding: false,
        });
      }
    } catch (error) {
      notification.error("Errore durante l'aggiunta della categoria");
    } finally {
      this.setState({ loaded: true });
    }
  };
  /*End Funzioni componente select categorie*/

  _getCategorie = async () => {
    let user = this.props.auth.user;
    try {
      const { data } = await getCategorieProdotti(user.id_negozio);
      this.setState({
        categorie_negozio: data[0].categorie_prodotti
          ? data[0].categorie_prodotti.split(",")
          : [],
      });
    } catch (error) {
      notification.error({
        title: "Errore",
        description:
          "Si è verificato un errore durante il caricamento della pagina, si prega di riprovare.",
      });
    }
  };

  /*Funzioni lifecycle componente*/
  _loader = async () => {
    this.setState({ loaded: false });

    let user = this.props.auth.user;
    try {
      const prodotti = await getProdottiNegozio(user.id_negozio);
      this._getCategorie();
      this.setState({
        prodotti: prodotti.data,
        loaded: true,
      });
    } catch (error) {
      notification.error({
        title: "Errore",
        description:
          "Si è verificato un errore durante il caricamento della pagina, si prega di riprovare.",
      });
    }
  };

  componentDidMount() {
    this._loader();
  }

  render() {
    const { selected_product, loaded, scontato_visible } = this.state;
    const {
      nome,
      descrizione,
      categoria,
      immagine,
      prezzo,
      prezzo_scontato,
      non_rimborsabile,
      ordinabile,
    } = selected_product || {};

    const initialValues = {
      nome: nome ?? "",
      descrizione: descrizione ?? "",
      categoria: categoria ?? "",
      immagine: immagine ?? null,
      prezzo: prezzo ?? 0,
      prezzo_scontato: scontato_visible ? prezzo_scontato ?? 0 : null,
      non_rimborsabile: non_rimborsabile ?? 1,
      ordinabile: ordinabile ?? 0,
      categoria_edit: "",
    };

    return (
      <>
        <Container>
          <PageHeader
            title="Catalogo prodotti"
            description="In questa sezione metti in mostra i tuoi prodotti, i tuoi clienti potranno selezionarli e inviarti degli ordini. Presentali al
          meglio con delle belle foto e con delle descrizioni."
          />
          <Divider />
          <Row
            align="middle"
            justify="end"
            gutter={[15, 15]}
            style={{ marginBottom: 15 }}
          >
            <Col>
              <Button size="large" onClick={this._clearFiltri}>
                Reset Filtri
              </Button>
            </Col>
            <Col>
              <Button
                size="large"
                icon={<PlusOutlined />}
                onClick={() => this.setState({ drawer_visible: true })}
                type="primary"
              >
                Aggiungi prodotto
              </Button>
            </Col>
          </Row>
          <ConfigProvider
            renderEmpty={() => (
              <Empty
                description={
                  <span>
                    Nessun prodotto, creane uno premendo su "Aggiungi prodotto"
                  </span>
                }
              />
            )}
          >
            <Table
              dataSource={this.state.prodotti}
              columns={this._getColumns()}
              onChange={this._handleChange}
              size="small"
              scroll={{ x: 576, y: 400 }}
              pagination={{
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ["10", "20", "50", "100", "200"],
              }}
            />
          </ConfigProvider>
          <ConfigProvider
            renderEmpty={() => (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>Nessuna categoria presente</span>}
              />
            )}
          >
            {/*Drawer creazione/modifica prodotto*/}
            <Drawer
              destroyOnClose
              placement="right"
              closable={true}
              onClose={() =>
                this.setState({
                  drawer_visible: false,
                  selected_product: null,
                  selected_index: null,
                })
              }
              visible={this.state.drawer_visible}
              width="100%"
              closeIcon={<CloseOutlined style={{ fontSize: 24 }} />}
            >
              <Row justify="start" align="middle" gutter={[15, 15]}>
                <Col xs={24}>
                  <PageHeader
                    title={
                      selected_product
                        ? "Modifica prodotto"
                        : "Aggiungi prodotto"
                    }
                    description={
                      selected_product
                        ? `Aggiorna i dettagli del prodotto: ${selected_product.nome}`
                        : "Aggiungi un nuovo prodotto."
                    }
                  />
                </Col>
              </Row>
              <Divider />
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchemaProdotto}
                onSubmit={this._submitForm}
              >
                {({
                  touched,
                  errors,
                  values,
                  handleChange,
                  handleSubmit,
                  handleBlur,
                  setFieldValue,
                  isValid,
                  dirty,
                  isSubmitting,
                }) => (
                  <Form>
                    <Row align="middle" justify="start" gutter={[15, 15]}>
                      <Col span={24}>
                        <Label titolo="Dati principali" />
                      </Col>
                      <Col xs={24} md={12} lg={12}>
                        <span
                          style={{
                            marginBottom: 10,
                            color: Settings.colors.darkGrey,
                          }}
                        >
                          Nome prodotto
                        </span>
                        <Input
                          value={values.nome}
                          onChange={handleChange("nome")}
                          placeholder="Inserisci nome prodotto..."
                          onBlur={handleBlur("nome")}
                        />
                        <div className="input-error">
                          {touched.nome && errors.nome}
                        </div>
                      </Col>
                      <Col xs={24} md={12} lg={12}>
                        <span
                          style={{
                            marginBottom: 10,
                            color: Settings.colors.darkGrey,
                          }}
                        >
                          Categoria prodotto
                        </span>
                        <Select
                          onChange={(value) => {
                            setFieldValue("categoria", value);
                          }}
                          style={{ width: "100%" }}
                          value={values.categoria}
                          optionLabelProp="label"
                          onBlur={handleBlur("categoria")}
                          placeholder="Inserisci una categoria"
                          dropdownRender={(menu) => (
                            <div>
                              {menu}
                              <Divider style={{ margin: "10px 0" }} />
                              <Row
                                justify="center"
                                align="middle"
                                style={{ padding: "0 5px" }}
                              >
                                <Col span={24} style={{ marginBottom: "5px" }}>
                                  <Input
                                    placeholder="Inserisci nome nuova categoria..."
                                    style={{ flex: "auto" }}
                                    value={this.state.nuova_categoria}
                                    onChange={this.onNameChange}
                                  />
                                </Col>
                                <Col span={24}>
                                  <Button
                                    style={{ width: "100%" }}
                                    type="primary"
                                    disabled={
                                      this.state.isAdding ||
                                      this.state.nuova_categoria === "" ||
                                      this.state.loading_prodotto
                                    }
                                    icon={<PlusOutlined />}
                                    onClick={
                                      this.state.nuova_categoria
                                        ? () => {
                                            this.setState({ isAdding: true });
                                            this.addItem(
                                              this.props.auth.user.id_negozio
                                            );
                                          }
                                        : null
                                    }
                                  >
                                    Aggiungi nuova
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          )}
                        >
                          <Option key="" value="" label="Nessuna Categoria">
                            -Nessuna Categoria-
                          </Option>
                          {this.state.categorie_negozio
                            ? this.state.categorie_negozio.map((item) => (
                                <Option key={item} value={item} label={item}>
                                  <Row justify="space-between" align="middle">
                                    <Col span={20}>{item}</Col>
                                    <Col span={2}>
                                      <EditOutlined
                                        style={{ color: Settings.colors.grey }}
                                        onClick={() => {
                                          this.setState({
                                            categoria_edit: item,
                                          });
                                          Modal.confirm({
                                            title: `Stai modificando la categoria "${item}"`,
                                            icon: <InfoCircleOutlined />,
                                            content: (
                                              <Row
                                                justify="start"
                                                align="middle"
                                                gutter={[0, 15]}
                                              >
                                                <Col span={24}>
                                                  Sei sicuro di voler modificare
                                                  la categoria "{item}"?
                                                  Facendolo verranno reimpostate
                                                  le categorie dei prodotti che
                                                  prima ne erano provvisti.
                                                </Col>
                                                <Col span={24}>
                                                  <Input
                                                    onChange={(value) => {
                                                      handleChange(
                                                        "categoria_editing"
                                                      );
                                                      this.setState({
                                                        categoria_edit:
                                                          value.target.value,
                                                      });
                                                    }}
                                                    onBlur={handleBlur(
                                                      "categoria_editing"
                                                    )}
                                                    value={
                                                      values.categoria_editing
                                                    }
                                                  />
                                                </Col>
                                              </Row>
                                            ),
                                            onOk: async () => {
                                              const {
                                                categorie_negozio,
                                                categoria_edit,
                                                prodotti,
                                              } = this.state;
                                              let categorie = [
                                                ...categorie_negozio,
                                              ];
                                              let prodotti_edit = [...prodotti];
                                              const test =
                                                /^[A-zÀ-ú0-9]+(\s+[-a-zA-Z0-9-()]+)*$/gi;
                                              if (!test.test(categoria_edit)) {
                                                notification.destroy();
                                                notification.warn({
                                                  message: "Attenzione!",
                                                  description:
                                                    "Puoi solo inserire lettere e numeri, senza spazi vuoti iniziali/finali",
                                                });
                                              } else if (
                                                categorie_negozio.includes(
                                                  categoria_edit
                                                )
                                              ) {
                                                notification.destroy();
                                                notification.warn({
                                                  message: "Attenzione!",
                                                  description:
                                                    "Hai inserito una categoria già esistente, riprova",
                                                });
                                              } else {
                                                categorie[
                                                  categorie.findIndex(
                                                    (i) => i === item
                                                  )
                                                ] =
                                                  categoria_edit.toLowerCase();
                                                try {
                                                  await setCategorieProdotti(
                                                    this.props.auth.user
                                                      .id_negozio,
                                                    categorie.join(",")
                                                  );
                                                  await setCategoriaProdotti(
                                                    this.props.auth.user
                                                      .id_negozio,
                                                    item,
                                                    categoria_edit
                                                  );
                                                } catch (err) {
                                                  notification.destroy();
                                                  notification.error({
                                                    message: "Errore!",
                                                    description:
                                                      "Non è stato possibile completare la richiesta",
                                                  });
                                                }
                                                prodotti_edit
                                                  .filter(
                                                    (i) => i.categoria === item
                                                  )
                                                  .map(
                                                    (i) =>
                                                      (i.categoria =
                                                        categoria_edit)
                                                  );
                                                setFieldValue("categoria", "");
                                                this.setState({
                                                  categorie_negozio: categorie,
                                                  categoria_edit: "",
                                                  prodotti: prodotti_edit,
                                                });
                                              }
                                            },
                                            onCancel: () => {
                                              this.setState({
                                                categoria_edit: "",
                                              });
                                            },
                                            okText: "Conferma",
                                            cancelText: "Annulla",
                                          });
                                        }}
                                      />
                                    </Col>
                                    <Col span={2}>
                                      <DeleteOutlined
                                        style={{ color: Settings.colors.red }}
                                        onClick={() =>
                                          Modal.confirm({
                                            title: `Stai eliminando la categoria "${item}"`,
                                            icon: <ExclamationCircleOutlined />,
                                            content: `Sei sicuro di voler eliminare la categoria ${item}? Facendolo verranno eliminate le categorie dei prodotti che prima ne erano provvisti.`,
                                            okText: "Conferma",
                                            cancelText: "Annulla",
                                            onOk: async () => {
                                              const {
                                                categorie_negozio,
                                                prodotti,
                                              } = this.state;
                                              let categorie =
                                                categorie_negozio.filter(
                                                  (i) => i !== item
                                                );
                                              let prodotti_edit = [...prodotti];
                                              try {
                                                await setCategorieProdotti(
                                                  this.props.auth.user
                                                    .id_negozio,
                                                  categorie.join(",")
                                                );
                                                await setCategoriaProdotti(
                                                  this.props.auth.user
                                                    .id_negozio,
                                                  item,
                                                  ""
                                                );
                                              } catch (err) {
                                                notification.destroy();
                                                notification.error({
                                                  message: "Errore!",
                                                  description:
                                                    "Non è stato possibile completare la richiesta",
                                                });
                                              }
                                              prodotti_edit
                                                .filter(
                                                  (i) => i.categoria === item
                                                )
                                                .map((i) => (i.categoria = ""));
                                              setFieldValue("categoria", "");
                                              this.setState({
                                                categorie_negozio: categorie,
                                                prodotti: prodotti_edit,
                                              });
                                            },
                                          })
                                        }
                                      />
                                    </Col>
                                  </Row>
                                </Option>
                              ))
                            : ""}
                        </Select>
                        <div className="input-error">
                          {touched.categoria && errors.categoria}
                        </div>
                      </Col>
                    </Row>
                    <Row justify="start" align="top" gutter={[15, 15]}>
                      <Col xs={24} md={12} lg={6}>
                        <span
                          style={{
                            marginBottom: 10,
                            color: Settings.colors.darkGrey,
                          }}
                        >
                          Immagine (opzionale)
                        </span>
                        <ImageCropper
                          cropSize={{ width: 400, height: 300 }}
                          aspect={4 / 3}
                          image={values.immagine}
                          onCropSuccess={(blobUrl) =>
                            setFieldValue("immagine", blobUrl)
                          }
                          onBlur={handleBlur("immagine")}
                        />
                        <div className="input-error">
                          {touched.immagine && errors.immagine}
                        </div>
                      </Col>
                      <Col xs={24} md={12} lg={18}>
                        <span style={{ color: Settings.colors.darkGrey }}>
                          Descrizione (opzionale)
                        </span>
                        <span
                          style={{
                            color: Settings.colors.grey,
                            fontSize: 13,
                            marginLeft: 5,
                          }}
                        >
                          {" "}
                          ({1500 - values.descrizione.length} caratteri
                          rimanenti)
                        </span>
                        <TextArea
                          maxLength={1500}
                          value={values.descrizione}
                          onChange={handleChange("descrizione")}
                          placeholder="Inserisci una descrizione per il prodotto"
                          autoSize={{ minRows: 7, maxRows: 7 }}
                          onBlur={handleBlur("descrizione")}
                          style={{ height: 180 }}
                        />
                        <div className="input-error">
                          {touched.descrizione && errors.descrizione}
                        </div>
                      </Col>
                    </Row>
                    <Divider />
                    <Row align="top" justify="start" gutter={[15, 15]}>
                      <Col xs={24}>
                        <Label titolo="Prezzi" />
                      </Col>
                      <Col xs={24} sm={24} md={4}>
                        <span
                          style={{
                            marginBottom: 10,
                            color: Settings.colors.darkGrey,
                          }}
                        >
                          <Space>
                            Ordinabile
                            <Popover
                              placement="left"
                              content={
                                <div className="black-popover">
                                  Se attivi l'opzione "Ordinabile" i clienti
                                  potranno ordinare questo prodotto. Per rendere
                                  ordinabile un prodotto devi assegnare un
                                  prezzo.
                                </div>
                              }
                            >
                              <QuestionCircleFilled />
                            </Popover>
                          </Space>
                        </span>
                        <Row justify="space-between" align="top" gutter={10}>
                          <Col span={24}>
                            <Switch
                              defaultChecked={ordinabile ? true : false}
                              checked={values.ordinabile}
                              value={values.ordinabile}
                              disabled={
                                this.state.prezzo_zero || values.prezzo == 0
                              }
                              onChange={(value) => {
                                if (value) setFieldValue("ordinabile", 1);
                                else setFieldValue("ordinabile", 0);
                              }}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={24} sm={24} md={4}>
                        <span
                          style={{
                            marginBottom: 10,
                            color: Settings.colors.darkGrey,
                          }}
                        >
                          <Space>
                            Non rimborsabile/restituibile
                            <Popover
                              placement="left"
                              content={
                                <div className="black-popover">
                                  Se attivi l'opzione "Non
                                  rimborsabile/restituibile" i clienti saranno
                                  avvisati che per questo prodotto non è idoneo
                                  a resi/restituzioni.
                                  <br /> Per rendere rimborsabile un prodotto
                                  devi disattivare questa opzione dopo aver
                                  assegnato un prezzo.
                                </div>
                              }
                            >
                              <QuestionCircleFilled />
                            </Popover>
                          </Space>
                        </span>
                        <Row justify="space-between" align="top" gutter={10}>
                          <Col span={24}>
                            <Switch
                              defaultChecked={non_rimborsabile ? true : false}
                              checked={values.non_rimborsabile}
                              value={values.non_rimborsabile}
                              disabled={
                                this.state.prezzo_zero || values.prezzo == 0
                              }
                              onChange={(value) => {
                                if (value) setFieldValue("non_rimborsabile", 1);
                                else setFieldValue("non_rimborsabile", 0);
                              }}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <span
                          style={{
                            marginBottom: 10,
                            color: Settings.colors.darkGrey,
                          }}
                        >
                          <Space>
                            Prezzo
                            <Popover
                              content={
                                <div className="black-popover">
                                  Se inserisci '0' il prodotto non potrà essere
                                  ordinabile
                                </div>
                              }
                            >
                              <QuestionCircleFilled />
                            </Popover>
                          </Space>
                        </span>
                        <Row justify="space-between" align="top" gutter={10}>
                          <Col span={23}>
                            <InputNumber
                              style={{ flex: "auto", width: "100%" }}
                              min={0}
                              max={99999}
                              step={0.01}
                              defaultValue={initialValues.prezzo ?? 0}
                              decimalSeparator=","
                              precision={2}
                              onChange={(value) => {
                                if (value === 0) {
                                  setFieldValue("ordinabile", false);
                                  setFieldValue("non_rimborsabile", true);
                                  setFieldValue("prezzo", value);
                                  this.setState({ prezzo_zero: true });
                                } else {
                                  this.setState({ prezzo_zero: false });
                                  setFieldValue("prezzo", value);
                                }
                              }}
                              onBlur={handleBlur("prezzo")}
                            />
                          </Col>
                          <Col span={1}>
                            <span>€</span>
                          </Col>
                        </Row>
                        <div className="input-error">
                          {touched.prezzo && errors.prezzo}
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Checkbox
                          style={{ color: Settings.colors.darkGrey }}
                          defaultChecked={values.prezzo_scontato !== null}
                          onChange={(value) => {
                            const { checked } = value.target;
                            this.setState({
                              scontato_visible: checked,
                            });
                            if (checked) setFieldValue("prezzo_scontato", 0);
                            else setFieldValue("prezzo_scontato", null);
                          }}
                        >
                          <Space>
                            Prezzo Scontato (opzionale)
                            <Popover
                              content={
                                <div className="black-popover">
                                  Se inserisci '0' il prodotto non potrà essere
                                  ordinabile
                                </div>
                              }
                            >
                              <QuestionCircleFilled />
                            </Popover>
                          </Space>
                        </Checkbox>
                        {this.state.scontato_visible ||
                        values.prezzo_scontato !== null ? (
                          <>
                            <Row
                              justify="space-between"
                              align="top"
                              gutter={10}
                            >
                              <Col span={23}>
                                <InputNumber
                                  style={{ flex: "auto", width: "100%" }}
                                  min={0}
                                  max={99999}
                                  step={0.01}
                                  defaultValue={initialValues.prezzo_scontato}
                                  decimalSeparator=","
                                  precision={2}
                                  onChange={(value) => {
                                    setFieldValue("prezzo_scontato", value);
                                  }}
                                  onBlur={handleBlur("prezzo_scontato")}
                                />
                              </Col>
                              <Col span={1}>
                                <span>€</span>
                              </Col>
                            </Row>
                            <div className="input-error">
                              {touched.prezzo_scontato &&
                                errors.prezzo_scontato}
                            </div>
                          </>
                        ) : null}
                      </Col>
                    </Row>
                    <Divider />
                    <Row justify="center" align="middle">
                      <Col span={24}>
                        <Button
                          loading={isSubmitting}
                          disabled={!(isValid && dirty) || isSubmitting}
                          icon={
                            selected_product ? (
                              <EditOutlined />
                            ) : (
                              <PlusOutlined />
                            )
                          }
                          block={true}
                          size="large"
                          onClick={() => {
                            if (!isValid)
                              notification.error({
                                message: "Errore",
                                description: "Ricontrolla i dati inseriti",
                              });
                            handleSubmit();
                          }}
                          type="primary"
                        >
                          {this.state.selected_product ? (
                            <span>Aggiorna</span>
                          ) : (
                            <span>Aggiungi</span>
                          )}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Drawer>
          </ConfigProvider>
          {/*End Drawer creazione/modifica prodotto*/}

          {/*Modal anteprima prodotto*/}
          <Modal
            destroyOnClose
            visible={this.state.modal_visible}
            title={
              this.state.selected_product
                ? this.state.selected_product.nome
                : "Prodotto"
            }
            onCancel={() =>
              this.setState({
                modal_visible: false,
                selected_product: null,
                selected_index: null,
              })
            }
            footer={null}
          >
            <Row gutter={32}>
              <Col xs={24} md={{ span: 8 }}>
                {this.state.selected_product &&
                this.state.selected_product.immagine ? (
                  <img
                    src={this.state.selected_product.immagine}
                    alt="immagine"
                    style={{ width: "100%", height: "auto" }}
                    className="product-image"
                  />
                ) : (
                  <div
                    className="product-image"
                    style={{
                      backgroundColor: Settings.colors.lightGrey,
                      width: "100%",
                      height: 150,
                      borderRadius: 5,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <FileImageOutlined
                      style={{ fontSize: 24, color: Settings.colors.grey }}
                    />
                  </div>
                )}
              </Col>
              <Col xs={24} md={{ span: 16 }}>
                {this.state.selected_product ? (
                  <div>
                    <Chip
                      title={
                        this.state.selected_product.categoria
                          ? String(
                              this.state.selected_product.categoria
                            ).toUpperCase()
                          : "Nessuna Categoria"
                      }
                    />
                    <Divider />
                    <p style={{ color: Settings.colors.darkGrey }}>
                      {this.state.selected_product.descrizione}
                    </p>

                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div
                        className={
                          this.state.selected_product.prezzo_scontato !== null
                            ? "price-container-light"
                            : "price-container"
                        }
                      >
                        <span style={{ fontSize: 10, fontWeight: "semibold" }}>
                          Prezzo
                        </span>
                        <span style={{ fontSize: 20, fontWeight: "semibold" }}>
                          {this.state.selected_product.prezzo} €
                        </span>
                      </div>

                      {this.state.selected_product.prezzo_scontato !== null ? (
                        <div
                          className="price-container"
                          style={{ marginLeft: 20 }}
                        >
                          <span
                            style={{ fontSize: 10, fontWeight: "semibold" }}
                          >
                            Prezzo scontato
                          </span>
                          <span
                            style={{ fontSize: 20, fontWeight: "semibold" }}
                          >
                            {this.state.selected_product.prezzo_scontato} €
                          </span>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}
              </Col>
            </Row>
          </Modal>
          <Loading loading={!this.state.loaded} opaque />
        </Container>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(Prodotti);
