import React from "react";

// import redux
import { connect } from "react-redux";

// import ant design
import {
  Row,
  Col,
  Button,
  Input,
  Select,
  Checkbox,
  notification,
  Space,
  Popover,
  Divider,
  Switch,
  Typography,
} from "antd";
import {
  EditOutlined,
  LeftOutlined,
  LoadingOutlined,
  PlusOutlined,
  QuestionCircleFilled,
} from "@ant-design/icons";

//import components and styles
import Container from "../Components/Container";
import Label from "../Components/Label";
import { Formik, Form } from "formik";

import ImageCropper from "../Components/ImageCropper";
import Loading from "../Components/Loading";
import PageHeader from "../Components/PageHeader";

import PhoneInput from "react-phone-input-2";
import { Editor } from "react-draft-wysiwyg";
import "../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

//import settings from config
import Settings from "../Config/Settings";
import { validationAggiornaNegozio } from "../Config/Validation";

// import Registrazione controller functions
import {
  getDatiNegozio,
  aggiornaDatiNegozio,
  setChatEnabled,
  setOrdiniEnabled,
  setAgendaEnabled,
} from "../Controllers/Negozio";
import { getCategorie } from "../Controllers/Categorie";
import { isMobile, isSafari } from "react-device-detect";
import { Link } from "react-router-dom";

const { Option } = Select;
const { TextArea } = Input;

const comuni_json = require("../Assets/comuni.json");
const OneSignal = window.OneSignal || [];

const { Title } = Typography;
async function getSubscriptionState() {
  var isPushEnabled = await OneSignal.isPushNotificationsEnabled();
  var isOptedOut = await OneSignal.isOptedOut();
  return {
    isPushEnabled: isPushEnabled,
    isOptedOut: isOptedOut,
  };
}
class IlMioNegozio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_citta_caps: [],
      loaded: false,
      loading_update: false,
      negozio: {},
      categorie: [],
      img_to_delete: null,
      loading_update_chat: false,
      loading_update_agenda: false,
      loading_update_ordini: false,
      notificationEnabled: false,
    };
  }

  _subscribeOneSignal = () => {
    if (Notification.permission === "denied") {
      notification.destroy();
      notification.error({
        message: "Errore nelle autorizzazioni del browser",
        description:
          "Devi autorizzare la ricezione delle notifiche dal tuo browser per poter abilitare la funzionalità",
      });
    }
    OneSignal.push(function () {
      OneSignal.showNativePrompt();
    });
  };

  _abilitaChat = async (checked) => {
    try {
      this.setState({ loading_update_chat: true });
      await setChatEnabled(checked, this.props.auth.user.id_negozio);
      notification.destroy();
      notification.success({
        message: `Chat ${!checked ? "disabilitata" : "abilitata"}`,
        description: `Hai ${!checked ? "disabilitato" : "abilitato"
          } la chat correttamente!`,
      });
      setTimeout(() => window.location.reload(), 500);
    } catch (error) {
      console.log(error);
      notification.destroy();
      notification.error({
        message: `Errore!`,
        description: `Non è stato possibile ${!checked ? "disabilitare" : "abilitare"
          } la chat correttamente!`,
      });
    } finally {
      this.setState({ loading_update_chat: false });
    }
  };

  _abilitaOrdini = async (checked) => {
    try {
      this.setState({ loading_update_ordini: true });
      await setOrdiniEnabled(checked, this.props.auth.user.id_negozio);
      notification.destroy();
      notification.success({
        message: `Ordini ${!checked ? "disabilitati" : "abilitati"}`,
        description: `Hai ${!checked ? "disabilitato" : "abilitato"
          } gli ordini correttamente!`,
      });
      setTimeout(() => window.location.reload(), 500);
    } catch (error) {
      console.log(error);
      notification.destroy();
      notification.error({
        message: `Errore!`,
        description: `Non è stato possibile ${!checked ? "disabilitare" : "abilitare"
          } gli ordini correttamente!`,
      });
    } finally {
      this.setState({ loading_update_ordini: false });
    }
  };

  _abilitaAgenda = async (checked) => {
    try {
      this.setState({ loading_update_agenda: true });
      await setAgendaEnabled(checked, this.props.auth.user.id_negozio);
      notification.destroy();
      notification.success({
        message: `Agenda ${!checked ? "disabilitata" : "abilitata"}`,
        description: `Hai ${!checked ? "disabilitato" : "abilitato"
          } l'agenda correttamente!`,
      });
      setTimeout(() => window.location.reload(), 500);
    } catch (error) {
      console.log(error);
      notification.destroy();
      notification.error({
        message: `Errore!`,
        description: `Non è stato possibile ${!checked ? "disabilitare" : "abilitare"
          } l'agenda correttamente!`,
      });
    } finally {
      this.setState({ loading_update_agenda: false });
    }
  };

  _submitForm = async (values, { setSubmitting, resetForm }) => {
    let message, description;
    try {
      const {
        nome_negozio,
        partita_iva,
        categoria,
        indirizzo,
        civico,
        provincia,
        cap,
        citta,
        logo,
        descrizione,
        orari_apertura,
        bancomat,
        carta,
        contante,
        altri_metodi,
        telefono,
        whatsapp,
        facebook,
        instagram,
        sito_web,
        email,
        consegna,
        ritiro,
        dettagli_consegna,
        prenotazioni,
        modulo_ordini,
        chat,
        modulo_appuntamenti,
        tags,

        marketing,
        profilazione
      } = values;

      const { img_to_delete } = this.state;

      let negozioRecord = {
        nome_negozio: nome_negozio,
        partita_iva: partita_iva,
        categoria: categoria,
        indirizzo: indirizzo,
        civico: civico,
        provincia: provincia,
        cap: cap,
        citta: citta,
        logo: logo ?? null,
        descrizione: descrizione ?? "",
        orari_apertura: orari_apertura ?? "",
        bancomat: bancomat,
        carta: carta,
        contante: contante,
        altri_metodi: altri_metodi ?? "",
        telefono: telefono
          ? telefono.substring(0, 1) != "+"
            ? "+" + telefono
            : telefono
          : "+39",
        whatsapp: whatsapp
          ? whatsapp.substring(0, 1) != "+"
            ? "+" + whatsapp
            : whatsapp
          : "+39",
        facebook: facebook ?? "",
        instagram: instagram ?? "",
        sito_web: sito_web ?? "",
        email: email ?? "",
        consegna: !modulo_ordini ? false : consegna,
        ritiro: !modulo_ordini ? false : ritiro,
        dettagli_consegna: dettagli_consegna,
        modulo_prenotazioni: prenotazioni,
        modulo_ordini: modulo_ordini,
        chat: chat,
        modulo_appuntamenti: modulo_appuntamenti,
        tags: tags ?? [],

        marketing: marketing,
        profilazione: profilazione
      };

      if (modulo_ordini && !consegna && !ritiro) {
        setSubmitting(false);
        notification.destroy();
        notification.warn({
          message: "Abilita una modalità di consegna",
          description:
            "Devi abilitare una modalità di consegna per ricevere ordini, oppure disabilita la possibilità di riceverli",
        });
        return;
      }

      this.setState({ loading_update: true });

      let negozio = await aggiornaDatiNegozio(this.props.auth.user.id_negozio, {
        ...negozioRecord,
        img_to_delete: img_to_delete ?? null,
      });

      message = "Negozio aggiornato!";
      description = `Il negozio ${nome_negozio} è stato correttamente aggiornato`;

      //Setto il logo della form con l'url del logo restituito da backend
      values.logo = negozio.data.img_url;

      this.setState({
        loading_update: false,
        img_to_delete: negozio.data.img_url,
      });

      resetForm({
        values: values,
      });

      notification.destroy();
      notification.success({
        message: message,
        description: description,
      });
    } catch (e) {
      this.setState({ loading_update: false });
      console.log("Errore", e);
      description = "Si è verificato un errore durante l'invio della richiesta";

      // Intercetto errore sul telefono o sull'email
      if (e.toString().indexOf("phone") !== -1) {
        description =
          "Esiste già un account associato a questo numero di telefono";
      } else if (e.toString().indexOf("email") !== -1) {
        description =
          "Esiste già un account associato a questo indirizzo email";
      } else if (e.toString().match("TOO_SHORT")) {
        description = "Il numero di telefono è troppo corto";
      } else if (e.toString().match("Missing recipients")) {
        description = "L'indirizzo email inserito è inseistente";
      }
      setSubmitting(false);
      notification.destroy();
      notification.warn({
        message: "Errore",
        description: description,
      });
    }
  };

  _getCategorie = async () => {
    try {
      let { data } = await getCategorie();
      this.setState({ categorie: data });
    } catch (e) {
      this.setState({ step: "errore" });
      console.log("Errore", e);
    }
  }

  _loader = async () => {
    let user = this.props.auth.user;
    try {
      let { data } = await getDatiNegozio(user.id_negozio);
      console.log(data);
      this.setState({
        negozio: { ...data, tags: JSON.parse(data.tags) },
        img_to_delete: data.logo,
      });
    } catch (e) {
      this.setState({ step: "errore" });
      console.log("Errore", e);
    }

    this._getCategorie();

    const { negozio } = this.state;
    const comune = comuni_json.find((i) => i.nome == negozio.citta);
    this.setState({
      selected_citta_caps: comune
        ? Array.isArray(comune.cap)
          ? comune.cap
          : [comune.cap]
        : [],
      loaded: true,
      step: "registrazione",
    });
  };

  _initOnesignal = async () => {
    const self = this;
    var enabled = false;
    OneSignal.push(function () {
      getSubscriptionState().then(function (state) {
        if (state.isPushEnabled) {
          enabled = true;
          self.setState({ notificationEnabled: true });
        } else {
          self.setState({ notificationEnabled: false });
        }
      });
    });
    return enabled;
  };
  componentDidMount() {
    this._initOnesignal();
    this._loader();
  }

  render() {
    const { negozio, loaded, loading_update, notificationEnabled } = this.state;
    const {
      nome_negozio,
      partita_iva,
      categoria,
      indirizzo,
      civico,
      provincia,
      cap,
      citta,
      latitudine,
      longitudine,
      logo,
      descrizione,
      orari_apertura,
      bancomat,
      carta,
      contante,
      altri_metodi,
      telefono,
      whatsapp,
      facebook,
      instagram,
      sito_web,
      email,
      //modalita_consegna,
      chat,
      modulo_ordini,
      consegna,
      ritiro,
      dettagli_consegna,
      modulo_prenotazioni,
      modulo_appuntamenti,
      tags,

      marketing,
      profilazione
    } = negozio || {};

    const initialValues = {
      nome_negozio: nome_negozio,
      partita_iva: partita_iva,
      categoria: categoria,

      provincia: provincia,
      citta: citta,
      cap: cap,
      indirizzo: indirizzo,
      civico: civico,
      latitudine: latitudine,
      longitudine: longitudine,

      logo: logo ?? null,
      descrizione: descrizione,
      orari_apertura: orari_apertura,
      bancomat: bancomat,
      carta: carta,
      contante: contante,
      altri_metodi: altri_metodi,
      telefono: telefono ?? "+39",
      whatsapp: whatsapp ?? "+39",
      email: email,
      facebook: facebook,
      instagram: instagram,
      sito_web: sito_web,
      consegna: consegna ?? 0,
      ritiro: ritiro ?? 0,
      dettagli_consegna: dettagli_consegna,
      modulo_appuntamenti: modulo_appuntamenti,
      prenotazioni: modulo_prenotazioni,
      modulo_ordini: modulo_ordini,
      chat: chat,

      tags: tags ?? []
    };

    return loaded ? (
      <Container>
        <PageHeader
          title="Il mio negozio"
          description="Questa è la vetrina di presentazione del tuo negozio, inserisci il maggior numero di informazioni per presentarti nel
          migliore dei modi ai tuoi clienti."
        />
        <Divider />
        <Formik
          initialValues={initialValues}
          validationSchema={validationAggiornaNegozio}
          onSubmit={this._submitForm}
        >
          {({
            touched,
            errors,
            values,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
            setTouched,
            isValid,
            dirty,
            isSubmitting,
          }) => (
            <Form>
              {!isMobile && !isSafari ? (
                <>
                  <Row justify="start" align="top">
                    <Col span={24}>
                      <Label titolo="Notifiche" />
                    </Col>
                    <Col span={24}>
                      <Row justify="start" align="top">
                        {Notification.permission !== "default" ? (
                          <Col>
                            <span style={{ color: Settings.colors.darkGrey }}>
                              Abilita le notifiche push
                            </span>
                          </Col>
                        ) : null}
                        <Col>
                          {Notification.permission === "default" ? (
                            <Button onClick={this._subscribeOneSignal}>
                              Consenti l'invio di notifiche da browser
                            </Button>
                          ) : (
                            <Switch
                              defaultChecked={notificationEnabled}
                              disabled={Notification.permission === "denied"}
                              onChange={(checked) => {
                                OneSignal.push(function () {
                                  OneSignal.setSubscription(checked);
                                });
                                this.setState({ notificationEnabled: checked });
                              }}
                            />
                          )}
                        </Col>
                        {Notification.permission === "denied" ? (
                          <Col>
                            <Popover content="Per attivare le notifiche, abilita il permesso relativo nelle impostazioni del tuo browser">
                              <QuestionCircleFilled />
                            </Popover>
                          </Col>
                        ) : null}
                      </Row>
                    </Col>
                  </Row>
                  <Divider />
                </>
              ) : null}
              <Row justify="start" align="top" gutter={[15, 15]}>
                <Col span={24}>
                  <Label titolo="Dati principali" />
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <span style={styles.spanCol}>Nome negozio</span>
                  <Input
                    value={values.nome_negozio}
                    onChange={handleChange("nome_negozio")}
                    placeholder="Nome negozio"
                    onBlur={handleBlur("nome_negozio")}
                    maxLength={100}
                  />
                  <div className="input-error">
                    {touched.nome_negozio && errors.nome_negozio}
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <span style={styles.spanCol}>
                    <Space>
                      Partita IVA/Codice Fiscale
                      <Popover
                        content={
                          <div className="black-popover">
                            Se non hai la Partita IVA, inserisci il tuo Codice
                            Fiscale. La Partita IVA è composta da 11 cifre.
                          </div>
                        }
                      >
                        <QuestionCircleFilled />
                      </Popover>
                    </Space>
                  </span>
                  <Input
                    value={values.partita_iva}
                    onChange={handleChange("partita_iva")}
                    placeholder="Partita iva (senza prefisso 'IT') / Codice Fiscale"
                    onBlur={handleBlur("partita_iva")}
                    maxLength={25}
                  />
                  <div className="input-error">
                    {touched.partita_iva && errors.partita_iva}
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <span style={styles.spanCol}>Categoria</span>
                  <Select
                    onChange={(value) => setFieldValue("categoria", value)}
                    size="default"
                    placeholder="Scegli una categoria"
                    style={{ width: "100%" }}
                    onBlur={handleBlur("categoria")}
                    value={values.categoria}
                    disabled={!this.state.categorie}
                  >
                    {this.state.categorie.map((c) => (
                      <Option key={c.nome}>{c.nome}</Option>
                    ))}
                  </Select>
                  <div className="input-error">
                    {touched.categoria && errors.categoria}
                  </div>
                </Col>
              </Row>
              <Divider />
              <Row justify="start" align="top" gutter={[15, 15]}>
                <Col xs={24} sm={18} md={20}>
                  <span style={styles.spanCol}>Indirizzo negozio</span>
                  <Input
                    value={values.indirizzo}
                    onChange={handleChange("indirizzo")}
                    placeholder="Indirizzo negozio"
                    onBlur={handleBlur("indirizzo")}
                  />
                  <div className="input-error">
                    {touched.indirizzo && errors.indirizzo}
                  </div>
                </Col>
                <Col xs={24} sm={6} md={4}>
                  <span style={styles.spanCol}>Civico</span>
                  <Input
                    value={values.civico}
                    onChange={handleChange("civico")}
                    placeholder="N° civico"
                    onBlur={handleBlur("civico")}
                  />
                  <div className="input-error">
                    {touched.civico && errors.civico}
                  </div>
                </Col>
                <Col xs={24} md={16}>
                  <span style={styles.spanCol}>Città</span>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    onChange={(value) => {
                      setFieldValue("citta", value);
                      let comune = comuni_json.find((i) => i.nome == value);
                      setFieldValue(
                        "cap",
                        comune && Array.isArray(comune.cap)
                          ? comune.cap[0]
                          : comune.cap
                      );
                      this.setState({
                        selected_citta_caps:
                          comune && Array.isArray(comune.cap)
                            ? comune.cap
                            : [comune.cap],
                      });
                      setFieldValue("provincia", comune.provincia.nome);
                    }}
                    size="default"
                    placeholder="Scegli la città"
                    style={{ width: "100%" }}
                    onBlur={handleBlur("citta")}
                    value={values.citta}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {comuni_json.map((c) => (
                      <Option key={c.nome}>{c.nome}</Option>
                    ))}
                  </Select>
                  <div className="input-error">
                    {touched.citta && errors.citta}
                  </div>
                </Col>
                <Col xs={24} md={8}>
                  <span style={styles.spanCol}>CAP</span>
                  <Select
                    onChange={(value) => setFieldValue("cap", value)}
                    size="default"
                    placeholder="Seleziona o inserisci il cap"
                    style={{ width: "100%" }}
                    onBlur={handleBlur("cap")}
                    value={values.cap}
                  >
                    {values.citta
                      ? this.state.selected_citta_caps.map((c) => (
                        <Option key={c}>{c}</Option>
                      ))
                      : null}
                  </Select>
                  <div className="input-error">{touched.cap && errors.cap}</div>
                </Col>
              </Row>
              <Divider />
              <Row justify="start" align="top" gutter={[15, 15]}>
                <Col span={24}>
                  <Label
                    titolo="Componi la tua vetrina"
                    desc="Carica il logo o un’immagine evocativa della tua attività commerciale. Inserisci un testo descrittivo del tuo negozio raccontando le principali caratteristiche (Es: Da quanti anni siete aperti, quali sono i vostri prodotti o servizi principali, i vostri punti di forza…)"
                  />
                </Col>
                <Col xs={24} md={8}>
                  <span style={styles.spanCol}>Carica logo / Immagine</span>
                  <ImageCropper
                    cropSize={{ width: 400, height: 300 }}
                    aspect={4 / 3}
                    image={values.logo ? values.logo : null}
                    onCropSuccess={(blobUrl) => {
                      setFieldValue("logo", blobUrl);
                    }}
                    onChange={handleChange("logo")}
                  />
                  <div className="input-error">
                    {touched.logo && errors.logo}
                  </div>
                </Col>
                <Col xs={24} md={16}>
                  <span style={{ color: Settings.colors.darkGrey }}>
                    Descrizione
                  </span>{" "}
                  (
                  {values.descrizione ? 1500 - values.descrizione.length : 1500}{" "}
                  caratteri rimanenti)
                  <TextArea
                    value={values.descrizione}
                    onChange={handleChange("descrizione")}
                    placeholder="Inserisci informazioni rilevanti sulla tua attività come: descrizione prodotti, orari di apertura ecc... "
                    autoSize={{ minRows: 7, maxRows: 7 }}
                    maxLength={1500}
                    onBlur={handleBlur("descrizione")}
                    style={{ height: 180 }}
                  />
                  <div className="input-error">
                    {touched.descrizione && errors.descrizione}
                  </div>
                </Col>
                <Col span={24}>
                  <Space>
                    <span style={{ color: Settings.colors.darkGrey }}>
                      Tag di ricerca{" "}
                    </span>
                    <span>
                      ({values.tags ? 20 - values.tags.length : 20} tag rimanent
                      {20 - values.tags.length === 1 ? "e" : "i"})
                    </span>
                    <Popover
                      content={
                        <div className="black-popover">
                          Per permettere ai clienti di trovarti più facilmente
                          inserisci delle parole chiave riguardanti il tuo
                          negozio e i tuoi prodotti/servizi. Puoi farlo
                          inserendo la parola e premendo INVIO ad ogni
                          inserimento
                        </div>
                      }
                    >
                      <QuestionCircleFilled
                        style={{ color: Settings.colors.darkGrey }}
                      />
                    </Popover>
                  </Space>

                  <Select
                    mode="tags"
                    placeholder="Inserisci le parole chiave per far trovare il tuo negozio ai clienti più facilmente"
                    defaultValue={values.tags}
                    onChange={(value) => {
                      if (value.length <= 20) setFieldValue("tags", value);
                    }}
                    value={values.tags}
                    onBlur={handleBlur("tags")}
                    style={{ width: "100%" }}
                  >
                    {values.tags}
                  </Select>
                  <div className="input-error">
                    {touched.tags && errors.tags}
                  </div>
                </Col>
              </Row>
              <Divider />
              <Row justify="start" align="top" gutter={[15, 15]}>
                <Col span={24}>
                  <Label
                    titolo="Giorni e Orari di apertura"
                    desc="Inserisci nel seguente campo libero i giorni e gli orari di apertura della tua attività. Indica se siete aperti durante le festività, è un’informazione spesso richiesta dai tuoi clienti."
                  />
                </Col>
                <Col span={24}>
                  <span style={styles.spanCol}>
                    <Space>
                      Giorni e Orari di apertura
                      <Popover
                        content={
                          <div className="black-popover">
                            Per impaginare correttamente gli orari di apertura,
                            vai correttamente a capo quando scrivi come
                            nell'esempio sottoriportato.
                          </div>
                        }
                      >
                        <QuestionCircleFilled />
                      </Popover>
                    </Space>
                  </span>
                  <TextArea
                    value={values.orari_apertura}
                    onChange={handleChange("orari_apertura")}
                    placeholder={`Lunedì 8:00 - 12:00\nMartedì 8:00 - 12:00\nMercoledì APERTO SEMPRE\nGiovedì 8:00 - 18:00\nVenerdì 7:00 - 14:30\nSABATO CHIUSO\nDOMENICA CHIUSO\nFestività SEMPRE APERTO`}
                    autoSize={{ minRows: 10, maxRows: 7 }}
                    onBlur={handleBlur("orari_apertura")}
                  />
                  <div className="input-error">
                    {touched.orari_apertura && errors.orari_apertura}
                  </div>
                </Col>
              </Row>
              <Divider />
              <Row justify="space-between" align="top" gutter={[15, 15]}>
                <Col span={24}>
                  <Label
                    titolo="Metodi di pagamento accettati"
                    desc="Indica quali sono i principali sistemi di pagamento supportati dalla tua attività commerciale."
                  />
                </Col>
                <Col xs={24} md={8}>
                  <Checkbox
                    checked={values.contante}
                    onBlur={handleBlur("contante")}
                    onChange={handleChange("contante")}
                  >
                    Contante
                  </Checkbox>
                </Col>
                <Col xs={24} md={8}>
                  <Checkbox
                    checked={values.carta}
                    onBlur={handleBlur("carta")}
                    onChange={handleChange("carta")}
                  >
                    Carta di credito
                  </Checkbox>
                </Col>
                <Col xs={24} md={8}>
                  <Checkbox
                    checked={values.bancomat}
                    onBlur={handleBlur("bancomat")}
                    onChange={handleChange("bancomat")}
                  >
                    Bancomat
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <span style={styles.spanCol}>
                    Altri metodi accettati (opzionale)
                  </span>
                  <Input
                    value={values.altri_metodi}
                    onChange={handleChange("altri_metodi")}
                    placeholder="Ticket, buoni pasto ecc.."
                    onBlur={handleBlur("altri_metodi")}
                    maxLength={100}
                  />
                  <div className="input-error">
                    {touched.altri_metodi && errors.altri_metodi}
                  </div>
                </Col>
              </Row>
              <Divider />

              <Row justify="start" align="top" gutter={[15, 15]}>
                <Col span={24}>
                  <Label
                    titolo="Contatti"
                    desc="Indica quali sono i principali mezzi con cui il cliente può interagire con il tuo negozio."
                  />
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <span
                    style={{
                      marginBottom: 10,
                      color: Settings.colors.darkGrey,
                    }}
                  >
                    Telefono
                  </span>
                  <PhoneInput
                    value={values.telefono}
                    placeholder="Telefono"
                    country="it"
                    enableSearch
                    countryCodeEditable={false}
                    searchPlaceholder="Cerca"
                    onChange={handleChange("telefono")}
                    onBlur={handleBlur("telefono")}
                    inputClass="input-text"
                    inputStyle={{ width: "100%" }}
                  />
                  <div className="input-error">
                    {touched.telefono && errors.telefono}
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <span style={styles.spanCol}>Whatsapp</span>
                  <PhoneInput
                    value={values.whatsapp}
                    placeholder="Whatsapp"
                    country="it"
                    enableSearch
                    countryCodeEditable={false}
                    searchPlaceholder="Cerca"
                    onChange={handleChange("whatsapp")}
                    onBlur={handleBlur("whatsapp")}
                    inputClass="input-text"
                    inputStyle={{ width: "100%" }}
                  />
                  <div className="input-error">
                    {touched.whatsapp && errors.whatsapp}
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <span style={styles.spanCol}>
                    <Space>
                      E-Mail di Contatto
                      <Popover
                        content={
                          <div className="black-popover">
                            Questa sarà la mail di <strong>contatto</strong>{" "}
                            visibili ai clienti sulla piattaforma.
                          </div>
                        }
                      >
                        <QuestionCircleFilled />
                      </Popover>
                    </Space>
                  </span>
                  <Input
                    value={values.email}
                    onChange={handleChange("email")}
                    placeholder="Email"
                    onBlur={handleBlur("email")}
                  />
                  <div className="input-error">
                    {touched.email && errors.email}
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Space style={{ color: Settings.colors.darkGrey }}>
                    <span
                      style={{
                        marginBottom: 10,
                        color: Settings.colors.darkGrey,
                      }}
                    >
                      Facebook
                    </span>
                    <Popover
                      content={
                        <div className="black-popover">
                          Il link deve cominciare con "https://" (es:
                          https://www.facebook.com/garzone)
                        </div>
                      }
                    >
                      <QuestionCircleFilled />
                    </Popover>
                  </Space>
                  <Input
                    value={values.facebook}
                    onChange={handleChange("facebook")}
                    placeholder="Facebook"
                    onBlur={handleBlur("facebook")}
                  />
                  <div className="input-error">
                    {touched.facebook && errors.facebook}
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Space style={{ color: Settings.colors.darkGrey }}>
                    <span style={styles.spanCol}>Instagram</span>
                    <Popover
                      content={
                        <div className="black-popover">
                          Il link deve cominciare con "https://" (es:
                          https://www.instagram.com/garzone)
                        </div>
                      }
                    >
                      <QuestionCircleFilled />
                    </Popover>
                  </Space>
                  <Input
                    value={values.instagram}
                    onChange={handleChange("instagram")}
                    placeholder="Instagram"
                    onBlur={handleBlur("instagram")}
                  />
                  <div className="input-error">
                    {touched.instagram && errors.instagram}
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Space style={{ color: Settings.colors.darkGrey }}>
                    <span style={styles.spanCol}>Sito internet</span>
                    <Popover
                      content={
                        <div className="black-popover">
                          Il link deve cominciare con "https://" o "http://"
                          (es: https://garzone.store)
                        </div>
                      }
                    >
                      <QuestionCircleFilled />
                    </Popover>
                  </Space>
                  <Input
                    value={values.sito_web}
                    onChange={handleChange("sito_web")}
                    placeholder="Sito internet"
                    onBlur={handleBlur("sito_web")}
                  />
                  <div className="input-error">
                    {touched.sito_web && errors.sito_web}
                  </div>
                </Col>
              </Row>
              <Divider />
              <Row justify="center" align="middle">
                <Col span={24}>
                  <Button
                    icon={<EditOutlined />}
                    loading={loading_update}
                    disabled={!dirty || isSubmitting}
                    block={true}
                    size="large"
                    onClick={() => {
                      if (!isValid)
                        notification.error({
                          message: "Errore",
                          description: "Ricontrolla i dati inseriti",
                        });
                      handleSubmit();
                    }}
                    type="primary"
                  >
                    {loading_update ? "Attendi..." : "Aggiorna"}
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Container >
    ) : (
      <Container>
        <Loading loading={true} opaque />
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

const styles = {
  divModulo: {
    backgroundColor: Settings.colors.darkGrey,
    color: Settings.colors.white,
    borderRadius: "15px",
  },
  spanCol: {
    marginBottom: 10,
    color: Settings.colors.darkGrey,
  },
};

export default connect(mapStateToProps)(IlMioNegozio);
